import { useController, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { Box, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormTextField } from "@/components/FormTextField";
import { FormDatePicker } from "@/components/DatePicker";
import { FormNumericTextField } from "@/components/NumericTextField";
import { FormMembershipAutocomplete } from "@/components/MembershipAutocomplete";
import { FormRadioGroup } from "@/components/FormRadioGroup";
import { now } from "@/lib/dateTime";
import {
  formatDurationUnit,
  formatPrice,
  formatPurchaseType,
} from "@/formatter";
import { datetime } from "@/lib/yup";
import { PurchaseType } from "@/models";

import { calculateDiscount } from "../utils";

import type { ControllerProps } from "react-hook-form";
import type { InferType } from "yup";
import type { DurationObjectUnits } from "luxon";

export type PurchaseMembershipEditorState = InferType<typeof schema>;

export type PurchaseMembershipEditorProps = Pick<
  ControllerProps<PurchaseMembershipEditorState>,
  "control"
>;

export function PurchaseMembershipEditor({
  control,
}: PurchaseMembershipEditorProps) {
  const membership = useWatch({ name: "membership", control });
  const start = useWatch({ name: "dateRange.start", control });
  const {
    field: { onChange },
  } = useController({ name: "dateRange.end", control });

  const discountType = useWatch({ name: "discountType", control });
  const discountPercentage = useWatch({ name: "discountPercentage", control });
  const discountBaht = useWatch({ name: "discountBaht", control });
  const {
    field: { onChange: onDiscountPercentChange },
  } = useController({ name: "discountPercentage", control });
  const {
    field: { onChange: onDiscountBahtChange },
  } = useController({ name: "discountBaht", control });

  useEffect(() => {
    if (membership && start) {
      const end = start.plus({ ...membership.duration }).plus({ days: -1 });
      onChange(end);
    }
  }, [membership, start, onChange]);

  useEffect(() => {
    onDiscountPercentChange(undefined);
    onDiscountBahtChange(undefined);
  }, [discountType, onDiscountPercentChange, onDiscountBahtChange]);

  useEffect(() => {
    onDiscountBahtChange(
      calculateDiscount(membership?.price ?? 0, discountPercentage ?? 0)
    );
  }, [membership, discountPercentage, onDiscountBahtChange]);

  const membershipDuration = membership?.duration as DurationObjectUnits;

  const unit = membership
    ? (Object.keys(membership?.duration)[0] as keyof DurationObjectUnits)
    : "months";
  const duration = membershipDuration ? membershipDuration[unit] : 0;

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2.5}>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2.5}>
        <Box display="grid" gridColumn="1/span 2">
          <FormMembershipAutocomplete
            label="ชื่อแพ็กเกจสมาชิก"
            name="membership"
            control={control}
            required
          />
        </Box>
        <FormDatePicker
          label="วันเริ่มต้น"
          name="dateRange.start"
          control={control}
          disabled={!membership}
        />
        <FormDatePicker
          label="วันสิ้นสุด"
          name="dateRange.end"
          control={control}
          disabled
        />
        <Box display="grid" gridColumn="1/span 2">
          <Stack direction="row" gap={1.25} alignItems="center">
            <Typography variant="labelLarge">เลือกรูปแบบส่วนลด</Typography>
            <FormRadioGroup row name="discountType" control={control}>
              <FormControlLabel
                value="percentage"
                control={<Radio />}
                label="เปอร์เซ็นต์ %"
                disabled={!membership}
              />
              <FormControlLabel
                value="baht"
                control={<Radio />}
                label="บาท"
                disabled={!membership}
              />
            </FormRadioGroup>
          </Stack>
        </Box>
        <FormNumericTextField
          label="ส่วนลด (%)"
          name="discountPercentage"
          control={control}
          disabled={!membership || discountType === "baht"}
        />
        <FormNumericTextField
          label="ราคาส่วนลด (บาท)"
          name="discountBaht"
          control={control}
          disabled={!membership || discountType === "percentage"}
        />
        <FormTextField
          label="หมายเหตุ"
          name="note"
          control={control}
          multiline
          rows={4}
          sx={{ gridColumn: "1/-1" }}
        />
      </Box>
      <Box
        bgcolor="background.default"
        p={2}
        borderRadius={2}
        border="1px solid #9E9E9E3D"
      >
        <Box
          display="grid"
          gridTemplateColumns="150px 1fr"
          gap={3}
          alignItems="baseline"
        >
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ระยะเวลา
          </Typography>
          <Typography variant="body2">
            {duration && duration !== 0
              ? `${duration} ${formatDurationUnit(unit)}`
              : "-"}
          </Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ราคา
          </Typography>
          <Typography variant="body2">
            {membership ? formatPrice(membership.price) : "-"}
          </Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ราคาหลังหักส่วนลด
          </Typography>
          <Typography variant="body2">
            {discountBaht
              ? formatPrice(membership.price - (discountBaht ?? 0))
              : "-"}
          </Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            สถานที่เข้าใช้บริการ
          </Typography>
          <Stack>
            {membership
              ? membership?.branches.map((branch) => (
                  <li key={branch.id}>{branch.name}</li>
                ))
              : "-"}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export function usePurchaseMembershipEditorForm() {
  return useForm({ resolver, defaultValues });
}

const schema = yup.object({
  membership: yup
    .object({
      id: yup.string().required(),
      name: yup.string().required(),
      duration: yup.object().required(),
      price: yup.number().required(),
      branches: yup
        .array(
          yup.object({
            id: yup.string().required(),
            name: yup.string().required(),
          })
        )
        .required(),
    })
    .label(`ชื่อ${formatPurchaseType(PurchaseType.Membership)}`)
    .required()
    .default(null), // fix yup not validate when value is undefined
  dateRange: yup.object({
    start: datetime()
      .required()
      .min(now().minus({ years: 1 })),
    end: datetime().required(),
  }),
  note: yup.string().label("หมายเหตุ").max(500),
  discountType: yup.string(),
  discountPercentage: yup
    .number()
    .test(
      "maxDigitsAfterDecimal",
      "number field must have 10 digits after decimal or less",
      (number) => /^\d+(\.\d{0,10})?$/.test(number?.toString() ?? "0")
    )
    .label("ส่วนลด")
    .min(0)
    .max(100),
  discountBaht: yup
    .number()
    .test(
      "maxDigitsAfterDecimal",
      "number field must have 10 digits after decimal or less",
      (number) => /^\d+(\.\d{0,10})?$/.test(number?.toString() ?? "0")
    )
    .label("ส่วนลด")
    .min(0),
});

const resolver = yupResolver(schema);

const defaultValues = {
  dateRange: { start: now().startOf("day"), end: now() },
  discountType: "percentage",
} as PurchaseMembershipEditorState;
