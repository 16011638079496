import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { configs } from "@/configs";
import { updateMember } from "@/services/member";
import { formatProfile } from "@/formatter";
import { mapOptional } from "@/utils";

import { EditMemberEditorDialog } from "./EditMemberEditorDialog";
import { useMemberEditorForm } from "../../pages/MyMemberListPage/MemberProfileEditor";
import {
  BloodGroup,
  MaritalStatus,
  Member,
  PersonalTax,
  UpdateMemberInput,
} from "@/models";

type EditMemberDialogProps = {
  open: boolean;
  data?: Member;
  onClose: () => void;
  id: number;
};

export function EditMemberDialog({
  open: isOpen,
  onClose,
  id,
  data,
}: EditMemberDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useMemberEditorForm();

  const { mutate: update, isLoading } = useMutation(updateMember, {
    onSuccess: async () => {
      enqueueSnackbar("บันทึกข้อมูล สำเร็จ", { variant: "success" });
      await queryClient.refetchQueries("member");
      close();
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(configs.unknownErrorMessage, {
        variant: "error",
      });
    },
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      reset({
        memberId: data.code,
        nationalIdCard: data.profile.idCard ?? "",
        firstName: data.profile.firstName,
        lastName: data.profile.lastName,
        nickname: data.profile.nickname ?? "",
        dateOfBirth: data.profile.birthDate,
        gender: data.profile.gender,
        bloodGroup: data.profile.bloodGroup ?? BloodGroup.Unspecified,
        height: data.profile.height ?? 0,
        weight: data.profile.weight ?? 0,
        maritalStatus: data.profile.maritalStatus ?? MaritalStatus.Unspecified,
        career: data.profile.career ?? "",
        phone: data.profile.phoneNo ?? "",
        email: data.profile.email ?? "",
        line: data.profile.line ?? "",
        facebook: data.profile.facebook ?? "",
        contactOther: data.profile.contactOther ?? "",
        channel: data.profile.channel,
        channelFriend: {
          id: data.profile.memberId,
          accountId: data.profile.member?.accountId,
          code: data.profile.member?.code,
          firstName: data.profile.member?.profile.firstName,
          lastName: data.profile.member?.profile.lastName,
        },
        channelOther: data.profile.channelOther ?? "",
        emergencyContactName: data.profile.emergencyPerson ?? "",
        emergencyContactPhone: data.profile.emergencyPhoneNo ?? "",
        relationship: data.profile.emergencyRelation ?? "",
        currentAddress: {
          required: true,
          line: data.profile.address ?? "",
          district: data.profile.district ?? "",
          subdistrict: data.profile.subdistrict ?? "",
          province: data.profile.province ?? "",
          postcode: data.profile.postCode ?? "",
        },
        invoice: {
          type: data.taxType ?? PersonalTax.NormalPerson,
          address: {
            required: !!data.taxNo,
            line: data.taxAddress ?? "",
            district: data.taxDistrict ?? "",
            subdistrict: data.taxSubdistrict ?? "",
            province: data.taxProvince ?? "",
            postcode: data.taxPostCode ?? "",
          },
          no: data.taxNo ?? "",
          name: data.taxName,
          branch: data.taxBranch ?? "",
        },
        isEditing: true,
        branchId: data.branch?.id,
        sale: {
          id: data.staff?.id,
          name: mapOptional(data.staff?.profile, formatProfile) ?? undefined,
        },
      });
    }
  }, [data, isOpen, reset]);

  function close() {
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    const data: UpdateMemberInput = {
      profile: {
        idCard: form.nationalIdCard,
        firstName: form.firstName,
        lastName: form.lastName,
        nickname: form.nickname,
        birthDate: form.dateOfBirth,
        gender: form.gender,
        bloodGroup: form.bloodGroup,
        height: form.height,
        weight: form.weight,
        maritalStatus: form.maritalStatus,
        career: form.career,
        phoneNo: form.phone,
        email: form.email,
        emergencyPerson: form.emergencyContactName,
        emergencyPhoneNo: form.emergencyContactPhone,
        emergencyRelation: form.relationship,
        address: form.currentAddress.line,
        postCode: form.currentAddress.postcode,
        province: form.currentAddress.province,
        district: form.currentAddress.district,
        subdistrict: form.currentAddress.subdistrict,
        line: form.line,
        facebook: form.facebook,
        contactOther: form.contactOther,
        channel: form.channel,
        channelOther: form.channelOther,
        memberId: form.channelFriend?.id ?? undefined,
      },
      taxAddress: form.invoice.address.line,
      taxDistrict: form.invoice.address.district,
      taxSubdistrict: form.invoice.address.subdistrict,
      taxProvince: form.invoice.address.province,
      taxPostCode: form.invoice.address.postcode,
      taxName: form.invoice.name,
      taxNo: form.invoice.no,
      taxBranch: form.invoice.branch,
      taxType: form.invoice.type,
      branchId: form.branchId,
      saleId: form.sale?.id,
    };

    update({ id, data });
  });

  const title = "แก้ไข ข้อมูลส่วนตัว";

  return (
    <EditMemberEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
