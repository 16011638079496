import {
  Box,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";

import { formatPrice } from "@/formatter";

import { Product } from "@/models";

export type ProductDetailProps = {
  data: Product;
  fetchKeys?: string[];
};

export function ProductDetail({ data }: ProductDetailProps) {
  const { name, description } = data;

  const price = formatPrice(data.price);

  return (
    <Stack>
      <CardHeader
        title={name}
      />
      <CardContent>
        <Box
          display="grid"
          gridTemplateColumns="150px 1fr"
          gap={3}
          alignItems="baseline"
        >
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ราคา
          </Typography>
          <Typography variant="body2">{price}</Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            รายละเอียด
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </CardContent>
    </Stack>
  );
}
