import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { formatPrice } from "@/formatter";
import { calculateColumnWidth } from "@/utils";

import { Product } from "@/models";

import type { MouseEventHandler } from "react";

const columnsWidth = [320, 504, 120].map((item) => calculateColumnWidth(item));

export type ProductTableRowProps = {
  data: Product;
};

export function ProductTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>รายละเอียด</TableCell>
      <TableCell width={columnsWidth[2]}>ราคา</TableCell>
    </TableRow>
  );
}

export function ProductTableRow({ data }: ProductTableRowProps) {
  const navigate = useNavigate();

  const { id, name, description } = data;
  const price = formatPrice(data.price);

  const idString = id.toString();

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(idString);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2" line={2}>
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" line={2}>
          {description}
        </TextEllipsis>
      </TableCell>
      <TableCell>{price}</TableCell>
    </TableRow>
  );
}
