import { Box, BoxProps, Stack as MuiStack, Typography } from "@mui/material";
import { Fragment } from "react";

import { Section } from "@/components/Section";

type PrimitiveData = {
  label: string;
  value?: string | number | null;
  color?: string;
};

type ElementData = {
  label: string;
  value?: JSX.Element | null;
  key: string;
  color?: string;
};

type Data = PrimitiveData | ElementData;

type Stack = {
  title?: string;
  data: Data[];
};

export type Column = Stack[];

export function DataSection({
  columns,
  label,
  boxProps,
}: {
  columns: Column[];
  label?: string;
  boxProps?: BoxProps;
}) {
  return (
    <Section columns={columns.length} label={label}>
      {columns.map((column, index) => (
        <MuiStack gap={5} width="100%" key={index}>
          {column.map(({ title, data }, index) => (
            <Box
              key={title ?? `${index}-${data.length}`}
              display="grid"
              gridTemplateColumns="150px 1fr"
              gap={(theme) => theme.spacing(2, 5)}
              alignItems="baseline"
              aria-label={title}
              {...boxProps}
            >
              {title && (
                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  sx={{ gridColumn: "1/-1" }}
                >
                  {title}
                </Typography>
              )}
              {data.map((item, idx) => (
                <Fragment
                  key={`${item.label} ${
                    isPrimitiveData(item) ? item.value ?? "" : item.key
                  }-${idx}`}
                >
                  <Typography
                    variant="body2"
                    color={item.color ?? "text.secondary"}
                  >
                    {item.label}
                  </Typography>
                  {isPrimitiveData(item) ? (
                    <Typography
                      variant="body2"
                      aria-label={item.label}
                      sx={{ whiteSpace: "pre" }}
                    >
                      {item.value || "-"}
                    </Typography>
                  ) : (
                    item.value
                  )}
                </Fragment>
              ))}
            </Box>
          ))}
        </MuiStack>
      ))}
    </Section>
  );
}

function isPrimitiveData(data: Data): data is PrimitiveData {
  return !("key" in (data as ElementData));
}
