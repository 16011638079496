import { api } from "@/client/api";
import { configs } from "@/configs";
import { Customer, Opportunity } from "@/models";
import { parseDateTimeFromPrisma } from "@/utils";

type GetTasks = {
  data: Customer[];
  total: number;
};

type GetOpportunityTasks = {
  data: Opportunity[];
  total: number;
};

export async function getTasks(query = "") {
  const { data } = await api.get<GetTasks>(
    `${configs.apiUrl}/sale/task?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetTasks;
}

export async function getOpportunityTasks(query = "") {
  const { data } = await api.get<GetOpportunityTasks>(
    `${configs.apiUrl}/sale/task/opportunities?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetOpportunityTasks;
}
