import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { OpportunityStatus } from "@/models";

import { OpportunityStatusItem } from "./OpportunityStatusItem";
import { ChangeToCloseLoseDialog } from "./ChangeToCloseLoseDialog";
import { ChangeToCloseWonDialog } from "./ChangeToCloseWonDialog";

import type { FormControlProps, SelectChangeEvent } from "@mui/material";
import type { Opportunity } from "@/models";

type OpportunityStatusSelectProps = {
  data: Opportunity;
  FormControlProps?: FormControlProps;
  queryKey: string;
};

export function OpportunityStatusSelect({
  data,
  FormControlProps,
  queryKey,
}: OpportunityStatusSelectProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dialog = searchParams.get("dialog");

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  const changeToCloseLoseDialog = {
    opportunityId: data.id.toString(),
    open: dialog === "change-to-close-lose",
    onClose: onCloseDialog,
    fetchKeys: [queryKey],
  };

  const changeToCloseWonDialog = {
    data,
    open: dialog === "change-to-close-won",
    onClose: onCloseDialog,
    fetchKeys: [queryKey],
  };

  function openChangeToCloseWonDialog() {
    searchParams.set("dialog", "change-to-close-won");
    setSearchParams(searchParams);
  }

  function openChangeToCloseLoseDialog() {
    searchParams.set("dialog", "change-to-close-lose");
    setSearchParams(searchParams);
  }

  const openDialogMapper = {
    [OpportunityStatus.CloseWon]: openChangeToCloseWonDialog,
    [OpportunityStatus.CloseLose]: openChangeToCloseLoseDialog,
  };

  const onChangeStatus = (event: SelectChangeEvent) =>
    openDialogMapper[
      event.target.value as
        | OpportunityStatus.CloseWon
        | OpportunityStatus.CloseLose
    ]();

  const items = [
    OpportunityStatus.Open,
    OpportunityStatus.CloseWon,
    OpportunityStatus.CloseLose,
  ];

  return (
    <FormControl fullWidth {...FormControlProps}>
      <InputLabel>สถานะ</InputLabel>
      <Select
        label="สถานะ"
        value={OpportunityStatus.Open}
        onChange={onChangeStatus}
      >
        {items.map((item) => (
          <MenuItem
            key={item}
            value={item}
            disabled={item === OpportunityStatus.Open}
            sx={{ py: 1.5, px: 2 }}
          >
            <OpportunityStatusItem status={item} />
          </MenuItem>
        ))}
      </Select>
      <ChangeToCloseLoseDialog {...changeToCloseLoseDialog} />
      <ChangeToCloseWonDialog {...changeToCloseWonDialog} />
    </FormControl>
  );
}
