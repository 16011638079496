import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { Gender } from "@/models";
import { changeToLead } from "@/services/customer";
import { getApiErrorMessage, refetchQueries } from "@/utils";

import {
  FollowingLeadEditorDialog,
  useFollowingLeadEditorForm,
} from "./FollowingLeadEditorDialog";

import type { Customer } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

type ChangeToLeadDialogProps = {
  data: Customer;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function ChangeToLeadDialog({
  data,
  open,
  onClose,
  fetchKeys = [],
}: ChangeToLeadDialogProps) {
  const { control, handleSubmit, reset } = useFollowingLeadEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: updateToLead, isLoading } = useMutation(changeToLead, {
    onSuccess: async () => {
      enqueueSnackbar("เปลี่ยนสถานะเป็นสนใจ และเพิ่มการติดตาม สำเร็จ", {
        variant: "success",
      });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  function close() {
    reset();
    onClose();
  }

  useEffect(() => {
    if (data) {
      reset({
        gender: data.gender || Gender.Unspecified,
        firstName: data.firstName,
        lastName: data.lastName || undefined,
        nickname: data.nickname || undefined,
        contact: {
          phone: data.phoneNo,
          email: data.email || undefined,
          line: data.line || undefined,
          facebook: data.facebook || undefined,
          other: data.contactOther || undefined,
        },
        channel: data.channel,
        channelOther: data.channelOther || undefined,
        channelFriend: {
          id: data.friend?.id,
          accountId: data.friend?.accountId,
          code: data.friend?.code,
          firstName: data.friend?.profile.firstName,
          lastName: data.friend?.profile.lastName,
        },
      });
    }
  }, [data, reset]);

  const onSubmit = handleSubmit((form) => {
    updateToLead({
      id: data.id,
      email: form.contact.email || null,
      firstName: form.firstName,
      lastName: form.lastName || null,
      nickname: form.nickname || null,
      gender: form.gender,
      phoneNo: form.contact.phone || "",
      line: form.contact.line ?? null,
      facebook: form.contact.facebook || null,
      contactOther: form.contact.other || null,
      channel: form.channel,
      channelOther: form.channelOther || null,
      channelFriend: form.channelFriend?.id || null,
      following: {
        followAt: form.following.date,
        remark: form.following.remark || null,
      },
    });
  });

  const title = "เปลี่ยนสถานะเป็นสนใจ";

  return (
    <FollowingLeadEditorDialog
      control={control}
      title={title}
      isLoading={isLoading}
      open={open}
      close={close}
      onSubmit={onSubmit}
    />
  );
}
