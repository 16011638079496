import {
  Box,
  Card,
  CardContent,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { getProductPTs } from "@/services/product";
import { ItemsTotal } from "@/components/ItemsTotal";

import { ProductPTTableHead, ProductPTTableRow } from "./ProductPTTableRow";

const QUERY_KEY = "product-pts";

export function ProductPTList() {
  const [searchParams] = useSearchParams();
  const query = searchParams.toString();

  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getProductPTs(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Box mb={2}>
            <QueryTextField sx={{ width: 560 }} placeholder="ค้นหาชื่อ" />
          </Box>
          <ItemsTotal count={total} />
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <ProductPTTableHead />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <ProductPTTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
    </>
  );
}
