import { Box, Tab, Tabs } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { getDocumentTitle } from "@/utils";

import type { TabsProps } from "@mui/material";

export function CustomerPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname ?? "";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("Customer")}</title>
      </Helmet>
      <Tabs value={path} onChange={onChangeTab} sx={{ mb: { xs: 2, md: 3 } }}>
        <Tab label="รายการลูกค้าใหม่" value="/customers" sx={{ width: 180 }} />
        <Tab
          label="รายการเพิ่มยอดขาย"
          value="/customers/opportunities"
          sx={{ width: 180 }}
        />
      </Tabs>
      <Outlet />
    </Box>
  );
}
