import { Box, Tabs, Tab } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { getDocumentTitle } from "@/utils";

import type { TabsProps } from "@mui/material";

export function TaskListPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname ?? "";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("Daily Task")}</title>
      </Helmet>
      <Tabs value={path} onChange={onChangeTab} sx={{ mb: { xs: 2, md: 3 } }}>
        <Tab
          label="รายการลูกค้าใหม่"
          value="/daily-tasks"
          sx={{ width: 180 }}
        />
        <Tab
          label="รายการเพิ่มยอดขาย"
          value="/daily-tasks/opportunities"
          sx={{ width: 180 }}
        />
      </Tabs>
      <Outlet />
    </Box>
  );
}
