import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useMediaQuery } from "@mui/material";

import { FormTextField } from "@/components/FormTextField";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { getApiErrorMessage, refetchQueries } from "@/utils";
import { closeLoseOpportunity } from "@/services/opportunity";
import { theme } from "@/theme";

import type { InferType } from "yup";
import type { AxiosErrorWithData } from "@/client/api";

type ChangeToCloseLoseEditorState = InferType<typeof schema>;

type ChangeToCloseLoseDialogProps = {
  opportunityId: string;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function ChangeToCloseLoseDialog({
  opportunityId,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: ChangeToCloseLoseDialogProps) {
  const { control, handleSubmit, reset } = useChangeToCloseLoseEditorForm();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: closeCurrentCustomer, isLoading } = useMutation(
    closeLoseOpportunity,
    {
      onSuccess: async () => {
        enqueueSnackbar("เปลี่ยนสถานะเป็นขายไม่สำเร็จ", {
          variant: "success",
        });
        await refetchQueries({ queryClient, fetchKeys });
        close();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    }
  );

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit(({ closeLoseRemark }) => {
    closeCurrentCustomer({ closeLoseRemark, opportunityId });
  });

  const title = "เหตุผลที่ขายไม่สำเร็จ";

  const isTablet = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <FormConfirmDialog
      maxWidth={isTablet ? "md" : "lg"}
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <FormTextField
        label="เหตุผล"
        name="closeLoseRemark"
        control={control}
        multiline
        rows={4}
        fullWidth
        required
      />
    </FormConfirmDialog>
  );
}

function useChangeToCloseLoseEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  closeLoseRemark: yup.string().label("เหตุผล").required(),
});

const resolver = yupResolver(schema);

const defaultValues = { closeLoseRemark: "" } as ChangeToCloseLoseEditorState;
