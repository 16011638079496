import { Box, Link as MuiLink, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import { formatDurationISO, formatName, formatQuotaUnit } from "@/formatter";

import { Membership, PersonalTrainingQuota, PurchaseType } from "@/models";
import { mapOptional } from "@/utils";
import { transformISOToDate } from "@/lib/dateTime";

export type PurchaseProductDetailProps =
  | {
      memberId: number;
      type: PurchaseType.Membership;
      data: Membership | null;
    }
  | {
      memberId: number;
      type: PurchaseType.ProductPT;
      data: PersonalTrainingQuota | null;
    };

const configMap: Record<
  PurchaseProductDetailProps["type"],
  {
    title: string;
    defaultValue: {
      label: string;
      value: string;
    }[];
  }
> = {
  [PurchaseType.Membership]: {
    title: "แพ็กเกจสมาชิก",
    defaultValue: [
      {
        label: "ชื่อสมาชิก",
        value: "-",
      },
      {
        label: "ระยะเวลา",
        value: "-",
      },
      {
        label: "วันเริ่มต้น - สิ้นสุด",
        value: "-",
      },
      {
        label: "หมายเหตุ",
        value: "-",
      },
    ],
  },
  [PurchaseType.ProductPT]: {
    title: "แพ็กเกจเทรนเนอร์",
    defaultValue: [
      {
        label: "ชื่อแพ็กเกจ",
        value: "-",
      },
      {
        label: "ระยะเวลา",
        value: "-",
      },
      {
        label: "จำนวน (คงเหลือ)",
        value: "-",
      },
      {
        label: "วันเริ่มต้น - สิ้นสุด",
        value: "-",
      },
      {
        label: "หมายเหตุ",
        value: "-",
      },
    ],
  },
};

export function PurchaseProductDetail({
  memberId,
  data,
  type,
}: PurchaseProductDetailProps) {
  const { title, defaultValue } = configMap[type];

  const columns = !data
    ? defaultValue
    : type === PurchaseType.Membership
    ? [
        {
          label: "ชื่อแพ็กเกจ",
          value: (
            <Stack direction="row" justifyContent="space-between">
              <MuiLink
                component={Link}
                color="inherit"
                underline="always"
                to={`/members/${memberId}/membership/${data.product.id}`}
              >
                {data.product.name || "-"}
              </MuiLink>
            </Stack>
          ),
        },
        {
          label: "ระยะเวลา",
          value:
            formatDurationISO(data.product.productMembership.durationIso) ||
            "-",
        },
        {
          label: "วันเริ่มต้น - สิ้นสุด",
          value:
            data.startedAt && data.endedAt
              ? `${transformISOToDate(
                  data.startedAt as unknown as string // TODO: refactor
                )} - ${transformISOToDate(data.endedAt as unknown as string)}`
              : "-",
        },
        {
          label: "ชื่อพนักงานขาย",
          value: mapOptional(data.receipt?.staffs.profile, formatName) ?? "-",
        },
        {
          label: "หมายเหตุ",
          value: data.remark || "-",
        },
      ]
    : type === PurchaseType.ProductPT
    ? [
        {
          label: "ชื่อแพ็กเกจ",
          value: (
            <Stack direction="row" justifyContent="space-between">
              <MuiLink
                component={Link}
                color="inherit"
                underline="always"
                to={`/members/${memberId}/pt/${data.product.id}`}
              >
                {data.product.name || "-"}
              </MuiLink>
            </Stack>
          ),
        },
        {
          label: "ระยะเวลา",
          value:
            formatDurationISO(
              data.product.productPersonalTraining.durationIso
            ) || "-",
        },
        {
          label: "จำนวน (คงเหลือ)",
          value: `${
            data.product.productPersonalTraining.quota - data.useCount // TODO: refactor
          } / ${data.product.productPersonalTraining.quota} ${formatQuotaUnit(
            data.product.productPersonalTraining.quotaUnit
          )}`,
        },
        {
          label: "วันเริ่มต้น - สิ้นสุด",
          value:
            data.startedAt && data.endedAt
              ? `${transformISOToDate(
                  data.startedAt as unknown as string // TODO: refactor
                )} - ${transformISOToDate(data.endedAt as unknown as string)}`
              : "-",
        },
        {
          label: "ชื่อพนักงานขาย",
          value: mapOptional(data.receipt?.staffs.profile, formatName) ?? "-",
        },
        {
          label: "หมายเหตุ",
          value: data.remark || "-",
        },
      ]
    : [];

  return (
    <Box
      display="grid"
      gridTemplateColumns="150px 1fr"
      gap={(theme) => `${theme.spacing(2, 5)}`}
      alignItems="baseline"
      width="100%"
    >
      <Typography
        variant="subtitle1"
        color="text.secondary"
        sx={{ gridColumn: "1/-1" }}
      >
        {title}
      </Typography>
      {columns.map(({ label, value }) => (
        <Fragment key={`${label}`}>
          <Typography variant="subtitle2" color="text.disabled">
            {label}
          </Typography>
          {typeof value === "string" ? (
            <Typography variant="body2">{value}</Typography>
          ) : (
            value
          )}
        </Fragment>
      ))}
    </Box>
  );
}
