import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth, getTrainingLateColor } from "@/utils";
import {
  formatDate,
  formatTrainingGoal,
  formatTrainingStatus,
  formatTrainingStatusColor,
} from "@/formatter";
import { SortField } from "@/components/SortField";

import type { TrainingItem } from "@/models";

const columnsWidth = [244, 244, 160, 160, 120].map((item) =>
  calculateColumnWidth(item)
);

export type MemberTrainingTableRowProps = {
  data: TrainingItem;
};

export function MemberTrainingTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อเทรนเนอร์</TableCell>
      <TableCell width={columnsWidth[1]}>เป้าหมายการเทรน</TableCell>
      <TableCell width={columnsWidth[2]}>
        <SortField fieldName="latestFollowedAt" />
        วันที่ติดตามผล
      </TableCell>
      <TableCell width={columnsWidth[3]}>
        <SortField fieldName="endedAt" />
        วันสิ้นสุดการเทรน
      </TableCell>
      <TableCell width={columnsWidth[4]} align="center">
        สถานะ
      </TableCell>
    </TableRow>
  );
}

export function MemberTrainingTableRow({ data }: MemberTrainingTableRowProps) {
  const navigate = useNavigate();

  const { id, trainerName, latestFollowedAt, endedAt, status, goals } = data;

  const onClickTableRow = () => navigate(id.toString());
  const goalDetail = goals.map((goal) => formatTrainingGoal(goal)).join(",");

  const followUpColor = getTrainingLateColor(latestFollowedAt, status);
  const endedAtColor = getTrainingLateColor(endedAt, status);

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis line={2}>{trainerName}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis line={2}>{goalDetail}</TextEllipsis>
      </TableCell>
      <TableCell sx={{ color: followUpColor }}>
        {formatDate(latestFollowedAt)}
      </TableCell>
      <TableCell sx={{ color: endedAtColor }}>{formatDate(endedAt)}</TableCell>
      <TableCell
        align="center"
        sx={{ color: formatTrainingStatusColor(status) }}
      >
        {formatTrainingStatus(status)}
      </TableCell>
    </TableRow>
  );
}
