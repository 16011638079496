import { Box, Tab, Tabs, TabsProps } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export function MemberListPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname ?? "/members";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  return (
    <Box>
      <Tabs value={path} onChange={onChangeTab} sx={{ mb: 3 }}>
        <Tab label="สมาชิกที่ดูแล" value="/members" />
        <Tab label="สมาชิกอื่นๆ" value="/members/another" />
      </Tabs>
      <Outlet />
    </Box>
  );
}
