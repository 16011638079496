import { Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { getDocumentTitle, useRequireParams } from "@/utils";
import { Breadcrumbs } from "@/components/Breadcrumbs";

import { FollowUpDetail } from "./FollowUpDetail";

export function MemberFollowUpDetailPage() {
  const { memberId, trainingId, followUpId } = useRequireParams([
    "memberId",
    "trainingId",
    "followUpId",
  ]);
  const location = useLocation();
  const path = location?.pathname ?? "";
  const isAnother = path.includes("another");
  const memberPath = isAnother ? "/members/another" : "/members";

  const title = "รายละเอียดการติดตามผลการเทรน";
  const breadcrumbs = {
    items: [
      { label: "สมาชิก", to: "/members" },
      { label: isAnother ? "สมาชิกอื่นๆ" : "สมาชิกที่ดูแล", to: memberPath },
      { label: "ช้อมูลสมาชิก", to: `${memberPath}/${memberId}` },
      {
        label: "รายละเอียดเป้าหมายการเทรน",
        to: `${memberPath}/${memberId}/trainings/${trainingId}`,
      },
      {
        label: title,
        to: `${memberPath}/${memberId}/trainings/${trainingId}/follow-ups/${followUpId}`,
      },
    ],
  };

  return (
    <Stack gap={4}>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs {...breadcrumbs} />
      <FollowUpDetail id={followUpId} />
    </Stack>
  );
}
