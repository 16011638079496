import { Remark } from "@/models";
import { api } from "@/client/api";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma, parseISOToPrisma } from "@/utils";

type createRemarkInput = Pick<Remark, "remark" | "staffId" | "customerId">;

export async function createRemark({
  customerId,
  ...data
}: createRemarkInput): Promise<Remark> {
  const response = await api.post(
    `${configs.apiUrl}/sale/customer/${customerId}/remark`,
    parseISOToPrisma(data)
  );
  return parseDateTimeFromPrisma(response.data) as Remark;
}
