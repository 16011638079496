import { api } from "@/client/api";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma, parseISOToPrisma } from "@/utils";
import { Opportunity } from "@/models";
import { DateTime } from "luxon";

type GetOpportunities = {
  data: Opportunity[];
  total: number;
};

type CloseLoseOpportunityInput = {
  closeLoseRemark: string;
  opportunityId: string;
};

type CloseWonOpportunityInput = {
  purchaseItems: {
    productId: number;
    discountPercentage: number;
    discount: number;
    start: DateTime;
    remark?: string;
  }[];
  memberId: number;
  opportunityId: number;
};

type UpdateOpportunityInterestInput = Pick<
  Opportunity,
  "percentageChance" | "totalChance" | "expectedClosingAt"
> & {
  productIds: number[];
  opportunityId: string;
};

type CreateOpportunityInput = Pick<
  Opportunity,
  "percentageChance" | "totalChance" | "expectedClosingAt"
> & {
  memberId: number;
  following: {
    remark: string | null;
    followAt: DateTime;
  };
  productIds: number[];
};

export async function getOpportunities(query = "") {
  const { data } = await api.get<GetOpportunities>(
    `${configs.apiUrl}/sale/opportunities?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetOpportunities;
}

export async function getOpportunity(id: string): Promise<Opportunity> {
  const response = await api.get(`${configs.apiUrl}/sale/opportunities/${id}`);
  return parseDateTimeFromPrisma(response.data) as Opportunity;
}

export async function closeLoseOpportunity({
  closeLoseRemark,
  opportunityId,
}: CloseLoseOpportunityInput): Promise<string> {
  const { data } = await api.patch<string>(
    `${configs.apiUrl}/sale/opportunities/${opportunityId}/close-lose`,
    { closeLoseRemark }
  );

  return data;
}

export async function closeWonOpportunity({
  purchaseItems,
  memberId,
  opportunityId,
}: CloseWonOpportunityInput): Promise<string> {
  const { data } = await api.patch<string>(
    `${configs.apiUrl}/sale/opportunities/${opportunityId}/close-won`,
    parseISOToPrisma({ purchaseItems, memberId })
  );

  return data;
}

export async function updateOpportunityInterest({
  productIds,
  opportunityId,
  ...input
}: UpdateOpportunityInterestInput): Promise<string> {
  const { data } = await api.patch<string>(
    `${configs.apiUrl}/sale/opportunities/${opportunityId}/interests`,
    parseISOToPrisma({ productIds, ...input })
  );

  return data;
}

export async function createOpportunity(
  data: CreateOpportunityInput
): Promise<Opportunity> {
  const response = await api.post(
    `${configs.apiUrl}/sale/opportunities`,
    parseISOToPrisma(data)
  );
  return parseDateTimeFromPrisma(response.data) as Opportunity;
}
