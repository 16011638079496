import {
  Box,
  Button,
  Card,
  CircularProgress,
  CardContent,
  CardHeader,
  Divider,
  Stack,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { EmptyResultIcon } from "@/components/EmptyResultIcon";
import { PurchaseProductEditorDialog } from "@/features/product/components/PurchaseProductEditorDialog";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import { MemberProductList } from "./MemberProductList";
import { PurchaseSingleProductDialog } from "./PurchaseSingleProductDialog";

import { Member, PersonalTrainingQuota, PurchaseType } from "@/models";

export type MemberProductProps = {
  data?: Member;
  isLoading: boolean;
};

export function MemberProduct({ data, isLoading }: MemberProductProps) {
  const { staffId, name } = useCurrentUser();

  const isEmpty =
    data &&
    data.memberships.length === 0 &&
    data.personalTrainingQuota.length === 0;

  const personalTraining: PersonalTrainingQuota[] = [];

  if (data) {
    for (const pt of data.personalTrainingQuota) {
      if (
        personalTraining.find(
          (p) =>
            p.endedAt.toString() === pt.endedAt.toString() &&
            p.product.id === pt.product.id
        )
      )
        continue;
      pt.useCount = data.personalTrainingQuota.reduce(
        (previousValue, currentValue) => {
          const currentPT =
            currentValue.endedAt === pt.endedAt &&
            currentValue.product.id === pt.product.id;
          const count = currentValue.usedAt && currentPT ? 1 : 0;
          return previousValue + count;
        },
        0
      );
      personalTraining.push(pt);
    }
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = searchParams.get("dialog");

  function openPurchasePackageDialog() {
    searchParams.set("dialog", "purchase-package");
    setSearchParams(searchParams);
  }

  function openPurchaseProductsDialog() {
    searchParams.set("dialog", "purchase-products");
    setSearchParams(searchParams);
  }

  function onClose() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  const purchaseDialog = {
    open: dialog === "purchase-package",
    onClose,
    id: data?.id.toString() ?? "",
  };

  const purchaseProductsDialog = {
    open: dialog === "purchase-products",
    onClose,
    member: data
      ? {
          id: data.id,
          accountId: data.accountId ?? "",
          code: data.code ?? "",
          firstName: data.profile.firstName,
          lastName: data.profile.lastName,
          phoneNo: data.profile.phoneNo,
        }
      : undefined,
    sale: { id: staffId, name },
  };

  return (
    <Card>
      {isLoading ? (
        <Box display="grid" sx={{ placeItems: "center" }} height={440}>
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <Stack>
          <CardHeader
            title="สินค้า"
            action={
              <Stack direction="row" gap={2}>
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={openPurchaseProductsDialog}
                >
                  ซื้อสินค้า
                </Button>
                <Button variant="contained" onClick={openPurchasePackageDialog}>
                  ซื้อแพ็กเกจ
                </Button>
              </Stack>
            }
          />
          <CardContent>
            {isEmpty ? (
              <Box display="grid" sx={{ placeItems: "center" }} height={440}>
                <EmptyResultIcon />
              </Box>
            ) : (
              <Box display="grid" gridTemplateColumns="1fr 2px 1fr" gap={3}>
                {data && data.memberships && (
                  <MemberProductList
                    memberId={data.id}
                    type={PurchaseType.Membership}
                    data={data.memberships}
                  />
                )}
                <Divider orientation="vertical" flexItem />
                {data && data.personalTrainingQuota && (
                  <MemberProductList
                    memberId={data.id}
                    type={PurchaseType.ProductPT}
                    data={personalTraining}
                  />
                )}
              </Box>
            )}
          </CardContent>
        </Stack>
      )}
      <PurchaseSingleProductDialog {...purchaseDialog} />
      <PurchaseProductEditorDialog {...purchaseProductsDialog} />
    </Card>
  );
}
