import { api } from "@/client/api";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma } from "@/utils";

import type { Training } from "@/models";

export async function getTraining(id: string) {
  const { data } = await api.get<Training>(`${configs.apiUrl}/trainings/${id}`);
  return parseDateTimeFromPrisma(data) as Training;
}
