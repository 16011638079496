import * as Sentry from "@sentry/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import ErrorOutline from "@mui/icons-material/ErrorOutlined";
import InfoOutline from "@mui/icons-material/InfoOutlined";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClient, QueryClientProvider } from "react-query";

import { AppBar } from "@/components/AppBar";

import { AuthenticationProvider } from "./features/authentication/contexts/AuthenticationContext";
import { AuthenticatedGuard } from "./features/authentication/guards/AuthenticatedGuard";
import { NonAuthenticatedGuard } from "./features/authentication/guards/NonAuthenticatedGuard";
import { SignInPage } from "./features/authentication/pages/SignInPage";
import { theme } from "./theme";
import { ProductAndPackageListPage } from "./features/product/pages/ProductAndPackageListPage";
import { ProductMembershipList } from "./features/product/components/MembershipList";
import { ProductPTList } from "./features/product/components/ProductPTList";
import { ProductListPage } from "./features/product/pages/ProductListPage/ProductListPage";
import { ProductDetailPage } from "./features/product/pages/ProductDetailPage";
import { CustomerListPage } from "./features/customer/pages/CustomerListPage/CustomerListPage";
import { CustomerDetailPage } from "./features/customer/pages/CustomerDetailPage/CustomerDetailPage";
import { MemberListPage } from "./features/member/pages/MemberListPage/MemberListPage";
import { MyMemberListPage } from "./features/member/pages/MyMemberListPage";
import { MemberProfilePage } from "./features/member/pages/MemberProfilePage";
import { AnotherMemberListPage } from "./features/member/pages/AnotherMemberListPage/AnotherMemberListPage";
import { ClassReservationListPage } from "./features/member/pages/ClassReservationListPage";
import { PTReservationListPage } from "./features/member/pages/PTReservationListPage";
import { TransactionListPage } from "./features/member/pages/TransactionListPage";
import { PTDetailPage } from "./features/member/pages/MemberProfilePage/PTDetailPage";
import { MembershipDetailPage as MembershipDetailInMember } from "./features/member/pages/MemberProfilePage/MembershipDetailPage";

import { ProductPTDetailPage } from "./features/product/pages/ProductPTDetailPage/ProductPTDetailPage";
import { TaskListPage } from "@/features/task/pages/TaskListPage";
import { ProductMembershipDetailPage } from "./features/product/pages/MembershipDetailPage/MembershipDetailPage";
import { MySalesSummaryPage } from "./features/dashboard/pages/MySalesSummaryPage";
import { CheckInListPage } from "./features/check-in/pages/CheckInListPage";
import { CustomerPage } from "./features/customer/pages/CustomerPage";
import { OpportunityListPage } from "./features/customer/pages/OpportunityListPage/OpportunityListPage";
import { OpportunityDetailPage } from "@/features/customer/pages/OpportunityDetailPage/OpportunityDetailPage";
import { CustomerTaskListPage } from "@/features/task/pages/CustomerTaskListPage/CustomerTaskListPage";
import { OpportunityTaskListPage } from "@/features/task/pages/OpportunityTaskListPage/OpportunityTaskListPage";
import { ReceiptListPage } from "@/features/receipt/pages/ReceiptListPage";
import { MemberTrainingList } from "./features/member/pages/MemberTrainingList";
import { MemberTrainingDetailPage } from "./features/member/pages/MemberTrainingDetailPage";
import { MemberFollowUpDetailPage } from "./features/member/pages/MemberFollowUpDetailPage";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function App() {
  return (
    <Sentry.ErrorBoundary>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              preventDuplicate
              iconVariant={{
                success: <CheckCircleOutline />,
                error: <ErrorOutline />,
                warning: <ReportProblemOutlined />,
                info: <InfoOutline />,
              }}
            >
              <QueryClientProvider client={queryClient}>
                <AuthenticationProvider>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <SentryRoutes>
                      <Route
                        path="/auth/sign-in"
                        element={
                          <NonAuthenticatedGuard>
                            <SignInPage />
                          </NonAuthenticatedGuard>
                        }
                      />
                      <Route
                        path=""
                        element={
                          <AuthenticatedGuard>
                            <AppBar />
                          </AuthenticatedGuard>
                        }
                      >
                        <Route
                          path="/"
                          element={<Navigate to="/check-in" replace />}
                        />
                        <Route
                          path="*"
                          element={<Navigate to="/check-in" replace />}
                        />
                        <Route path="check-in" element={<CheckInListPage />} />
                        <Route path="daily-tasks">
                          <Route path="" element={<TaskListPage />}>
                            <Route index element={<CustomerTaskListPage />} />
                            <Route
                              path="opportunities"
                              element={<OpportunityTaskListPage />}
                            />
                          </Route>
                          <Route path=":id" element={<CustomerDetailPage />} />
                          <Route
                            path="opportunities/:id"
                            element={<OpportunityDetailPage />}
                          />
                          <Route
                            path="opportunities/:opportunityId/members"
                            element={<MemberProfilePage />}
                          >
                            <Route path=":memberId">
                              <Route
                                index
                                element={<ClassReservationListPage />}
                              />
                              <Route
                                path="pt"
                                element={<PTReservationListPage />}
                              />
                              <Route
                                path="transactions"
                                element={<TransactionListPage />}
                              />
                            </Route>
                          </Route>
                        </Route>
                        <Route path="customers">
                          <Route path="" element={<CustomerPage />}>
                            <Route index element={<CustomerListPage />} />
                            <Route
                              path="opportunities"
                              element={<OpportunityListPage />}
                            />
                          </Route>
                          <Route path=":id" element={<CustomerDetailPage />} />
                          <Route
                            path="opportunities/:id"
                            element={<OpportunityDetailPage />}
                          />
                          <Route
                            path="opportunities/:opportunityId/members"
                            element={<MemberProfilePage />}
                          >
                            <Route path=":memberId">
                              <Route
                                index
                                element={<ClassReservationListPage />}
                              />
                              <Route
                                path="pt"
                                element={<PTReservationListPage />}
                              />
                              <Route
                                path="transactions"
                                element={<TransactionListPage />}
                              />
                            </Route>
                          </Route>
                        </Route>
                        <Route path="members" element={<MemberListPage />}>
                          <Route index element={<MyMemberListPage />} />
                          <Route
                            path="another"
                            element={<AnotherMemberListPage />}
                          />
                        </Route>
                        <Route path="members">
                          <Route
                            path=":memberId"
                            element={<MemberProfilePage />}
                          >
                            <Route
                              index
                              element={<ClassReservationListPage />}
                            />
                            <Route
                              path="pt"
                              element={<PTReservationListPage />}
                            />
                            <Route
                              path="transactions"
                              element={<TransactionListPage />}
                            />
                            <Route
                              path="trainings"
                              element={<MemberTrainingList />}
                            />
                          </Route>
                          <Route
                            path=":memberId/trainings/:trainingId"
                            element={<MemberTrainingDetailPage />}
                          />
                          <Route
                            path=":memberId/trainings/:trainingId/follow-ups/:followUpId"
                            element={<MemberFollowUpDetailPage />}
                          />
                        </Route>
                        <Route
                          path="members/:memberId/membership/:id"
                          element={<MembershipDetailInMember />}
                        />
                        <Route
                          path="members/:memberId/pt/:id"
                          element={<PTDetailPage />}
                        />
                        <Route
                          path="members/another"
                          element={<MemberProfilePage />}
                        >
                          <Route path=":memberId">
                            <Route
                              index
                              element={<ClassReservationListPage />}
                            />
                            <Route
                              path="pt"
                              element={<PTReservationListPage />}
                            />
                            <Route
                              path="transactions"
                              element={<TransactionListPage />}
                            />
                            <Route
                              path="trainings"
                              element={<MemberTrainingList />}
                            />
                          </Route>
                        </Route>
                        <Route
                          path="members/another/:memberId/membership/:id"
                          element={<MembershipDetailInMember />}
                        />
                        <Route
                          path="members/another/:memberId/pt/:id"
                          element={<PTDetailPage />}
                        />
                        <Route
                          path="members/another/:memberId/trainings/:trainingId"
                          element={<MemberTrainingDetailPage />}
                        />
                        <Route
                          path="members/another/:memberId/trainings/:trainingId/follow-ups/:followUpId"
                          element={<MemberFollowUpDetailPage />}
                        />
                        <Route path="products-and-packages">
                          <Route
                            path=""
                            element={<ProductAndPackageListPage />}
                          >
                            <Route index element={<ProductMembershipList />} />
                            <Route path="pt" element={<ProductPTList />} />
                            <Route
                              path="products"
                              element={<ProductListPage />}
                            />
                          </Route>
                          <Route
                            path=":id"
                            element={<ProductMembershipDetailPage />}
                          />
                          <Route
                            path="pt/:id"
                            element={<ProductPTDetailPage />}
                          />
                          <Route
                            path="products/:id"
                            element={<ProductDetailPage />}
                          />
                        </Route>
                        <Route path="receipts" element={<ReceiptListPage />} />
                        <Route
                          path="dashboard"
                          element={<MySalesSummaryPage />}
                        />
                      </Route>
                    </SentryRoutes>
                  </LocalizationProvider>
                </AuthenticationProvider>
              </QueryClientProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  );
}
