import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { CustomerStatus } from "@/models";

import { CloseCustomerDialog } from "./CloseCustomerDialog";
import { UnqualifiedCustomerDialog } from "./UnqualifiedCustomerDialog";
import { ChangeToProspectDialog } from "./ChangeToProspectDialog";
import { CustomerStatusItem } from "./CustomerStatusItem";
import { ChangeToLeadDialog } from "./ChangeToLeadDialog";
import { ChangeToMemberDialog } from "./ChangeToMemberDialog";

import type { SelectChangeEvent, FormControlProps } from "@mui/material";
import type { Customer } from "@/models";

type StatusSelectProps = {
  data: Customer;
  status: CustomerStatus;
  FormControlProps?: FormControlProps;
  queryKey: string;
};

export function StatusSelect({
  data,
  status,
  FormControlProps,
  queryKey,
}: StatusSelectProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dialog = searchParams.get("dialog");

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  const changeToLeadDialog = {
    data,
    open: dialog === "change-to-lead",
    onClose: onCloseDialog,
    fetchKeys: [queryKey],
  };

  const changeToProspectDialog = {
    data,
    open: dialog === "change-to-prospect",
    onClose: onCloseDialog,
    fetchKeys: [queryKey],
  };

  const changeToMemberDialog = {
    data,
    open: dialog === "change-to-member",
    onClose: onCloseDialog,
    fetchKeys: [queryKey],
  };

  const closeCustomerDialog = {
    customerId: data.id.toString(),
    open: dialog === "customer-close",
    onClose: onCloseDialog,
    fetchKeys: [queryKey],
  };

  const unqualifiedCustomerDialog = {
    customerId: data.id.toString(),
    open: dialog === "customer-unqualified",
    onClose: onCloseDialog,
    fetchKeys: [queryKey],
  };

  function openChangeToLeadDialog() {
    searchParams.set("dialog", "change-to-lead");
    setSearchParams(searchParams);
  }

  function openChangeToProspectDialog() {
    searchParams.set("dialog", "change-to-prospect");
    setSearchParams(searchParams);
  }

  function openChangeToMemberDialog() {
    searchParams.set("dialog", "change-to-member");
    setSearchParams(searchParams);
  }

  function openCloseCustomerDialog() {
    searchParams.set("dialog", "customer-close");
    setSearchParams(searchParams);
  }

  function openUnqualifiedCustomerDialog() {
    searchParams.set("dialog", "customer-unqualified");
    setSearchParams(searchParams);
  }

  const items = itemMapper[status];
  const filteredItems = data.productMembership
    ? items.filter((item) => item !== CustomerStatus.Lead)
    : items;

  const openDialogMapper = {
    [CustomerStatus.Lead]: openChangeToLeadDialog,
    [CustomerStatus.Prospect]: openChangeToProspectDialog,
    [CustomerStatus.Member]: openChangeToMemberDialog,
    [CustomerStatus.Close]: openCloseCustomerDialog,
    [CustomerStatus.Unqualified]: openUnqualifiedCustomerDialog,
  };

  const onChangeStatus = (event: SelectChangeEvent) =>
    openDialogMapper[event.target.value as CustomerStatus]();

  return (
    <FormControl fullWidth {...FormControlProps}>
      <InputLabel>สถานะ</InputLabel>
      <Select label="สถานะ" value={status} onChange={onChangeStatus}>
        {filteredItems.map((item) => (
          <MenuItem
            key={item}
            value={item}
            disabled={status === item}
            sx={{ py: 1.5, px: 2 }}
          >
            <CustomerStatusItem status={item} />
          </MenuItem>
        ))}
      </Select>
      <ChangeToLeadDialog {...changeToLeadDialog} />
      <ChangeToProspectDialog {...changeToProspectDialog} />
      <ChangeToMemberDialog {...changeToMemberDialog} />
      <CloseCustomerDialog {...closeCustomerDialog} />
      <UnqualifiedCustomerDialog {...unqualifiedCustomerDialog} />
    </FormControl>
  );
}

const itemMapper = {
  [CustomerStatus.Lead]: [
    CustomerStatus.Lead,
    CustomerStatus.Prospect,
    CustomerStatus.Member,
    CustomerStatus.Close,
    CustomerStatus.Unqualified,
  ],
  [CustomerStatus.Prospect]: [
    CustomerStatus.Prospect,
    CustomerStatus.Member,
    CustomerStatus.Close,
  ],
  [CustomerStatus.Member]: [CustomerStatus.Member],
  [CustomerStatus.Close]: [
    CustomerStatus.Close,
    CustomerStatus.Lead,
    CustomerStatus.Prospect,
    CustomerStatus.Member,
  ],
  [CustomerStatus.Unqualified]: [
    CustomerStatus.Unqualified,
    CustomerStatus.Lead,
    CustomerStatus.Prospect,
    CustomerStatus.Member,
  ],
};
