import { Box } from "@mui/material";

type AddImageThumbnailProps = {
  src: string | null;
  onClick: () => void;
};

export function AddImageThumbnail({ src, onClick }: AddImageThumbnailProps) {
  // TODO: need to refactor set src instead svg
  return src ? (
    <Box
      component="img"
      height="300px"
      width="300px"
      display="grid"
      borderRadius={1}
      sx={{
        cursor: "pointer",
      }}
      src={src}
      onClick={onClick}
    />
  ) : (
    <Box
      component="button"
      height="300px"
      width="300px"
      display="grid"
      borderRadius={1}
      sx={{
        placeItems: "center",
        border: 0,
        backgroundColor: "#E9EEF1",
        p: 6,
        cursor: "pointer",
        "&:hover": {
          opacity: 0.8,
        },
      }}
      onClick={onClick}
    >
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="104"
        height="95"
      >
        <path
          fill="#000"
          fillOpacity="0.28"
          d="M13.5 14V.5h9V14H36v9H22.5v13.5h-9V23H0v-9h13.5zM27 41V27.5h13.5V14H72l8.235 9H94.5c4.95 0 9 4.05 9 9v54c0 4.95-4.05 9-9 9h-72c-4.95 0-9-4.05-9-9V41H27zm31.5 40.5C70.92 81.5 81 71.42 81 59S70.92 36.5 58.5 36.5 36 46.58 36 59s10.08 22.5 22.5 22.5zM44.1 59c0 7.965 6.435 14.4 14.4 14.4 7.965 0 14.4-6.435 14.4-14.4 0-7.965-6.435-14.4-14.4-14.4-7.965 0-14.4 6.435-14.4 14.4z"
        ></path>
      </svg>
    </Box>
  );
}
