import { TableRow, TableCell } from "@mui/material";

import { calculateColumnWidth } from "@/utils";
import { ProductMembership } from "@/models";
import { useNavigate } from "react-router-dom";
import { TextEllipsis } from "@/components/TextEllipsis";
import { formatPrice, formatDurationISO, formatProductType } from "@/formatter";
import { MouseEventHandler } from "react";

const columnsWidth = [300, 624, 120, 150, 150].map((item) =>
  calculateColumnWidth(item)
);

export function MembershipTableHead() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>รายละเอียด</TableCell>
      <TableCell width={columnsWidth[2]}>ระยะเวลา</TableCell>
      <TableCell width={columnsWidth[3]}>ราคา</TableCell>
      <TableCell width={columnsWidth[4]}>สาขาที่เข้าใช้บริการ</TableCell>
    </TableRow>
  );
}

export type ProductMembershipTableRowProps = {
  data: ProductMembership;
};

export function ProductMembershipTableRow({
  data,
}: ProductMembershipTableRowProps) {
  const navigate = useNavigate();

  const { id, name, description, productMembership, type } = data;
  const idString = id.toString();
  const price = formatPrice(data.price);
  const duration = formatDurationISO(productMembership.durationIso);

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(idString);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{description}</TextEllipsis>
      </TableCell>
      <TableCell>{duration}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>{formatProductType(type)}</TableCell>
    </TableRow>
  );
}
