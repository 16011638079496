import {
  Box,
  Breadcrumbs,
  Stack,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { CustomerRemark } from "@/components/CustomerRemark";
import { CircularLoading } from "@/components/CircularLoading";
import { useRequireParams } from "@/utils";
import { getCustomer } from "@/services/customer";

import { CustomerDetail } from "./CustomerDetail";
import { CustomerFollowing } from "./CustomerFollowing";

const QUERY_KEY = "getCustomer";

export function CustomerDetailPage() {
  const { id } = useRequireParams(["id"]);
  const location = useLocation();
  const path = location?.pathname;
  const isTask = path.includes("daily-tasks");
  const title = isTask ? "สิ่งที่ต้องทำ" : "การจัดการลูกค้า";

  const { data, isFetching } = useQuery(QUERY_KEY, () => getCustomer(id));

  return isFetching || !data ? (
    <CircularLoading height="calc(100vh - 182px)" />
  ) : (
    <Stack gap={4}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <MuiLink component={Link} color="text.secondary" to="..">
          {title}
        </MuiLink>
        <Typography color="text.primary">รายละเอียดลูกค้า</Typography>
      </Breadcrumbs>
      <Box display="grid" gridTemplateColumns="2fr 1fr" gap={2}>
        <Stack gap={2}>
          <CustomerDetail data={data} queryKey={QUERY_KEY} />
          <CustomerRemark
            data={data.latestRemark}
            customerId={data.id}
            queryKey={QUERY_KEY}
          />
        </Stack>
        <CustomerFollowing data={data} queryKey={QUERY_KEY} />
      </Box>
    </Stack>
  );
}
