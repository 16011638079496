import {
  Card,
  CardContent,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";

import { FollowUpTableRow, FollowUpTableRowHeader } from "./FollowUpTableRow";

import type { FollowUpItem } from "@/models";

type FollowUpListProps = {
  followUps: FollowUpItem[];
};

export function FollowUpList({ followUps }: FollowUpListProps) {
  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant="h6">การติดตามผลการเทรน</Typography>
          <TableContainer sx={{ height: 794, overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <FollowUpTableRowHeader />
              </TableHead>
              <TableBody>
                {followUps.map((followUp, index) => (
                  <FollowUpTableRow
                    key={followUp.id}
                    followUpNo={followUps.length - index}
                    data={followUp}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </CardContent>
    </Card>
  );
}
