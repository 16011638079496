import { yupResolver } from "@hookform/resolvers/yup";
import { ControllerProps, useForm } from "react-hook-form";
import { InferType } from "yup";

import {
  FormSaleAutocomplete,
  SaleAutocomplete,
} from "../pages/MyMemberListPage/SaleAutocomplete";

export type SaleEditorState = InferType<typeof schema>;

export type SaleEditorProps = Pick<ControllerProps<SaleEditorState>, "control">;

export function SaleEditor({ control }: SaleEditorProps) {
  return (
    <FormSaleAutocomplete
      label="ชื่อพนักงานขาย"
      name="sale"
      control={control}
      required
    />
  );
}

export function useSaleForm() {
  return useForm({ resolver, defaultValues: {} as SaleEditorState });
}

const schema = SaleAutocomplete.schema;

const resolver = yupResolver(schema);
