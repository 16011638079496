import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import {
  formatDate,
  formatOpportunityStatus,
  formatOpportunityStatusColor,
} from "@/formatter";
import { fromISO, now } from "@/lib/dateTime";
import { OpportunitySort, SortType } from "@/models";

import type { MouseEventHandler } from "react";
import type { Opportunity } from "@/models";

const columnsWidth = [
  { xs: 130, tablet: 140 },
  { xs: 130, tablet: 180 },
  { xs: 256, tablet: 331 },
  { xs: 120, tablet: 160 },
  { xs: 120, tablet: 160 },
  { xs: 156, tablet: 373 },
];

export function OpportunityTableRowHeader({
  dailyTask,
}: {
  dailyTask?: boolean;
}) {
  const [field, label] = dailyTask
    ? [OpportunitySort.LastFollowingAt, "วันที่ติดตาม"]
    : [OpportunitySort.CreatedAt, "วันที่สร้าง"];

  return (
    <TableRow>
      <TableCell sx={{ width: columnsWidth[0] }}>
        <SortField field={field} />
        {label}
      </TableCell>
      <TableCell sx={{ width: columnsWidth[1] }}>หมายเลขสมาชิก</TableCell>
      <TableCell sx={{ width: columnsWidth[2] }}>ชื่อ</TableCell>
      <TableCell sx={{ width: columnsWidth[3] }}>เบอร์โทรศัพท์</TableCell>
      <TableCell sx={{ width: columnsWidth[4] }}>สถานะ</TableCell>
      <TableCell sx={{ width: columnsWidth[5] }}>หมายเหตุ</TableCell>
    </TableRow>
  );
}

export type OpportunityTableRowProps = {
  data: Opportunity;
  dailyTask?: boolean;
};

export function OpportunityTableRow({
  data,
  dailyTask,
}: OpportunityTableRowProps) {
  const navigate = useNavigate();

  const {
    id,
    member: {
      code,
      profile: { firstName, lastName, phoneNo },
    },
    createdAt,
    status,
    followings,
    lastFollowingAt,
  } = data;

  const { remark } = followings[0];

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(id.toString());
  };

  const dailyTaskDateColor =
    now() >= lastFollowingAt ? "primary.main" : "text.primary";

  const [dateColor, date] = dailyTask
    ? [dailyTaskDateColor, formatDate(fromISO(lastFollowingAt.toString()))]
    : ["text.primary", formatDate(fromISO(createdAt.toString()))];

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2" color={dateColor}>
          {date}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{code}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">
          {firstName} {lastName}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{phoneNo}</TextEllipsis>
      </TableCell>
      <TableCell>
        <Stack direction="row" gap={1} alignItems="center">
          <CircleIcon
            sx={{
              width: 12,
              height: 12,
              color: formatOpportunityStatusColor(status),
            }}
          />
          <Typography variant="caption">
            {formatOpportunityStatus(status)}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Typography noWrap width={columnsWidth[5]}>
          {remark || "-"}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

function SortField({ field }: { field: OpportunitySort }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort") as OpportunitySort | undefined;
  const sortType = searchParams.get("sortType") as SortType | undefined;
  const setSort = (s: OpportunitySort) => () => {
    if (s) {
      searchParams.set("sort", s);
      searchParams.set(
        "sortType",
        sortType === SortType.Asc && sort === field
          ? SortType.Desc
          : SortType.Asc
      );
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };
  return (
    <IconButton
      sx={{ padding: 0, width: "auto", height: "auto" }}
      onClick={setSort(field)}
    >
      {sort === field && sortType === SortType.Asc ? (
        <ArrowDownwardIcon />
      ) : (
        <ArrowUpwardIcon />
      )}
    </IconButton>
  );
}
