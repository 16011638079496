import {
  Box,
  Card,
  CardContent,
  Stack,
  ButtonGroup,
  Button,
  TableContainer,
  Table,
  TableHead,
} from "@mui/material";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import { QueryTextField } from "@/components/QueryTextField";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { DataTableBody } from "@/components/DataTableBody";
import { ItemsTotal } from "@/components/ItemsTotal";
import { mapOptional, pickListTableParams } from "@/utils";
import { formatCustomerStatus } from "@/formatter";
import { CustomerStatus } from "@/models";
import { getCustomers } from "@/services/customer";

import { AddLeadDialog } from "./AddLeadDialog";
import {
  CustomerTableRowHeader,
  CustomerTableRow,
} from "../../components/CustomerTableRow";

const QUERY_KEY = "customer";

export function CustomerListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = pickListTableParams(searchParams);
  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getCustomers(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  const dialog = searchParams.get("dialog");

  const status = searchParams.get("status") as CustomerStatus | undefined;
  const setStatus = (s: CustomerStatus | null) => () => {
    if (s) {
      searchParams.set("status", s);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  function add() {
    searchParams.set("dialog", "lead-add");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  const addLeadDialog = {
    open: dialog === "lead-add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Box
            display="grid"
            gridTemplateColumns="1fr 682px"
            alignItems="center"
            gap={3}
            mb={2}
          >
            <QueryTextField placeholder="ค้นหาชื่อ หรือเบอร์โทรศัพท์" />
            <Stack direction="row" gap={3}>
              <ButtonGroup variant="contained" size="medium" color="inherit">
                {[
                  null,
                  CustomerStatus.Lead,
                  CustomerStatus.Prospect,
                  CustomerStatus.Member,
                  CustomerStatus.Close,
                  CustomerStatus.Unqualified,
                ].map((s) => (
                  <Button
                    key={s}
                    onClick={setStatus(s)}
                    sx={{ bgcolor: s === status ? "grey.100" : "grey.300" }}
                  >
                    {mapOptional(s, formatCustomerStatus) ?? "ทั้งหมด"}
                  </Button>
                ))}
              </ButtonGroup>
              <Button variant="contained" size="medium" onClick={add}>
                เพิ่มผู้ที่สนใจ
              </Button>
            </Stack>
          </Box>
          <ItemsTotal count={total} />
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <CustomerTableRowHeader />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <CustomerTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddLeadDialog {...addLeadDialog} />
    </Box>
  );
}
