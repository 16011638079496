import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import { getDocumentTitle } from "@/utils";
import { DashboardCanvas } from "../components/DashboardCanvas";

export function MySalesSummaryPage() {
  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("สรุปข้อมูลการขายของฉัน")}</title>
      </Helmet>
      <DashboardCanvas title="สรุปข้อมูลการขายของฉัน" type="my-sales" />
    </Box>
  );
}
