import { TableCell, TableRow, Typography } from "@mui/material";

import { TextEllipsis } from "@/components/TextEllipsis";
import { DateRangeDisplay } from "@/components/DateRangeDisplay";
import { calculateColumnWidth, mapOptional } from "@/utils";
import {
  formatBookingStatus,
  formatBookingStatusColor,
  formatProfiles,
} from "@/formatter";

import type { MemberBooking, Staff } from "@/models";

const columnsWidth = [200, 240, 240, 120, 132, 96, 56].map((width) =>
  calculateColumnWidth(width)
);

export function ClassReservationTableHeaderRow() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>วันที่และเวลา</TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อคลาส</TableCell>
      <TableCell width={columnsWidth[2]}>ผู้สอน</TableCell>
      <TableCell width={columnsWidth[3]}>สถานที่</TableCell>
      <TableCell width={columnsWidth[4]}>สาขา</TableCell>
      <TableCell width={columnsWidth[5]} align="center">
        สถานะ
      </TableCell>
      <TableCell width={columnsWidth[6]} />
    </TableRow>
  );
}

export type ClassReservationTableRowProps = {
  data: MemberBooking;
};
export function ClassReservationTableRow({
  data,
}: ClassReservationTableRowProps) {
  const {
    schedule: {
      trainers,
      startedAt,
      endedAt,
      class: { name: className },
      location,
      branch: { name: branchName },
    },
    status,
  } = data;

  const trainersName =
    mapOptional(
      trainers as unknown as Pick<Staff, "profile">[],
      formatProfiles
    ) ?? "-";

  return (
    <TableRow>
      <TableCell>
        <DateRangeDisplay variant="body2" start={startedAt} end={endedAt} />
      </TableCell>
      <TableCell>{className ?? "-"}</TableCell>
      <TableCell>
        <TextEllipsis variant="body2" line={3}>
          {trainersName}
        </TextEllipsis>
      </TableCell>
      <TableCell aria-label="Location">{location}</TableCell>
      <TableCell aria-label="Branch">{branchName}</TableCell>
      <TableCell aria-label="Status">
        <Typography
          variant="body2"
          align="center"
          fontWeight={500}
          sx={{ color: formatBookingStatusColor(status) }}
        >
          {formatBookingStatus(status)}
        </Typography>
      </TableCell>
      <TableCell />
    </TableRow>
  );
}
