import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useMediaQuery } from "@mui/material";

import { LeadEditor, useLeadEditorForm } from "../../components/LeadEditor";
import { getApiErrorMessage, refetchQueries } from "@/utils";
import { updateLeadData } from "@/services/customer";
import { Gender } from "@/models";
import { theme } from "@/theme";

import type { Customer } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type EditLeadDialogProps = {
  data: Customer;
  open: boolean;
  onClose: () => void;
  fetchKeys: string[];
};

export function EditLeadDialog({
  data,
  open: isOpen,
  onClose,
  fetchKeys,
}: EditLeadDialogProps) {
  const { control, handleSubmit, reset } = useLeadEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: update, isLoading } = useMutation(updateLeadData, {
    onSuccess: async () => {
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  useEffect(() => {
    if (data) {
      reset({
        gender: data.gender || Gender.Unspecified,
        firstName: data.firstName,
        lastName: data.lastName || undefined,
        nickname: data.nickname || undefined,
        contact: {
          phone: data.phoneNo,
          email: data.email || undefined,
          line: data.line || undefined,
          facebook: data.facebook || undefined,
          other: data.contactOther || undefined,
        },
        channel: data.channel,
        channelOther: data.channelOther || undefined,
        channelFriend: {
          id: data.friend?.id,
          accountId: data.friend?.accountId,
          code: data.friend?.code,
          firstName: data.friend?.profile.firstName,
          lastName: data.friend?.profile.lastName,
        },
      });
    }
  }, [data, reset]);

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit((form) => {
    update({
      id: data.id,
      email: form.contact.email || null,
      firstName: form.firstName,
      lastName: form.lastName || null,
      nickname: form.nickname || null,
      gender: form.gender,
      phoneNo: form.contact.phone || "",
      line: form.contact.line ?? null,
      facebook: form.contact.facebook || null,
      contactOther: form.contact.other || null,
      channel: form.channel,
      channelOther: form.channelOther || null,
      channelFriend: form.channelFriend?.id || null,
    });
  });

  const title = "แก้ไขผู้ที่สนใจ";

  const isTablet = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <FormConfirmDialog
      maxWidth={isTablet ? "md" : "lg"}
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <LeadEditor control={control} />
    </FormConfirmDialog>
  );
}
