import { configs } from "@/configs";
import { api } from "@/client/api";
import { parseDateTimeFromPrisma } from "@/utils";

import type { FollowUp } from "@/models";

export async function getFollowUp(id: string): Promise<FollowUp> {
  const response = await api.get<FollowUp>(
    `${configs.apiUrl}/follow-ups/${id}`
  );
  return parseDateTimeFromPrisma(response.data) as FollowUp;
}
