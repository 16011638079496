import { Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { formatTrainingStatus, formatTrainingStatusColor } from "@/formatter";

import type { TrainingStatus } from "@/models";

type TrainingStatusItemProps = { status: TrainingStatus };

export function TrainingStatusItem({ status }: TrainingStatusItemProps) {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <CircleIcon
        sx={{
          width: 12,
          height: 12,
          color: formatTrainingStatusColor(status),
        }}
      />
      <Typography>{formatTrainingStatus(status)}</Typography>
    </Stack>
  );
}
