import { useSearchParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { formatDateTime, formatProfile } from "@/formatter";
import { mapOptional } from "@/utils";

import { EditCustomerRemarkDialog } from "./EditCustomerRemarkDialog";

import type { Remark } from "@/models";

type CustomerRemarkProps = {
  customerId: number;
  data?: Remark;
  queryKey: string;
  isEditable?: boolean;
};

export function CustomerRemark({
  customerId,
  data,
  queryKey,
  isEditable = true,
}: CustomerRemarkProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dialog = searchParams.get("dialog");

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  function openEditDialog() {
    searchParams.set("dialog", "remark-edit");
    setSearchParams(searchParams);
  }

  const editCustomerRemarkDialog = {
    open: dialog === "remark-edit",
    customerId,
    data,
    onClose: onCloseDialog,
    fetchKeys: [queryKey],
  };

  return (
    <Card>
      <CardHeader
        title="บันทึกเพิ่มเติม"
        action={
          isEditable ? (
            <Tooltip title="แก้ไข">
              <IconButton size="small" onClick={openEditDialog}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )
        }
        sx={{ ".MuiCardHeader-action": { alignSelf: "center" } }}
      />
      <CardContent>
        {data ? (
          <Stack gap={0.5}>
            <Typography
              variant="caption"
              color="text.secondary"
            >{`${formatDateTime(data.createdAt, "-")} โดย ${
              mapOptional(data.staff?.profile, formatProfile) || "-"
            }`}</Typography>
            <Stack>
              {data.remark.split("\n").map((text) => (
                <Typography key={text} variant="body2">
                  {text}
                </Typography>
              ))}
            </Stack>
          </Stack>
        ) : (
          <Typography variant="body2">-</Typography>
        )}
      </CardContent>
      <EditCustomerRemarkDialog {...editCustomerRemarkDialog} />
    </Card>
  );
}
