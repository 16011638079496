import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InferType } from "yup";
import { Divider, Stack, useMediaQuery } from "@mui/material";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { theme } from "@/theme";

import { LeadEditor } from "../../components/LeadEditor";
import { FollowingEditor } from "../../components/FollowingEditor";

import type { Control } from "react-hook-form";
import type { LeadEditorState } from "../../components/LeadEditor";
import type { FollowingEditorState } from "../../components/FollowingEditor";

type FollowingLeadEditorState = InferType<typeof schema>;

type FollowingLeadEditorDialogProps = {
  control: Control<FollowingLeadEditorState>;
  title: string;
  isLoading: boolean;
  open: boolean;
  close: () => void;
  onSubmit: () => void;
};

export function FollowingLeadEditorDialog({
  control,
  title,
  isLoading,
  open: isOpen,
  close,
  onSubmit,
}: FollowingLeadEditorDialogProps) {
  const isTablet = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <FormConfirmDialog
      maxWidth={isTablet ? "md" : "lg"}
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <Stack gap={2.5}>
        <LeadEditor control={control as unknown as Control<LeadEditorState>} />
        <Divider />
        <FollowingEditor
          control={control as unknown as Control<FollowingEditorState>}
        />
      </Stack>
    </FormConfirmDialog>
  );
}

export function useFollowingLeadEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = LeadEditor.schema.concat(FollowingEditor.schema);

const resolver = yupResolver(schema);

const defaultValues = {
  ...LeadEditor.defaultValues,
  ...FollowingEditor.defaultValues,
} as FollowingLeadEditorState;
