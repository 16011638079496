import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import {
  formatCustomerStatus,
  formatCustomerStatusColor,
  formatDate,
} from "@/formatter";
import { fromISO, now } from "@/lib/dateTime";
import { CustomerSort, SortType } from "@/models";

import type { MouseEventHandler } from "react";
import type { Customer } from "@/models";

const columnsWidth = [
  { xs: 160 },
  { xs: 256, lg: 336 },
  { xs: 168, lg: 224 },
  { xs: 136, lg: 216 },
  { xs: 216, lg: 400 },
];

export type CustomerTableRowProps = {
  data: Customer;
  dailyTask?: boolean;
};

export function CustomerTableRowHeader({ dailyTask }: { dailyTask?: boolean }) {
  const [field, label] = dailyTask
    ? [CustomerSort.LastFollowingAt, "วันที่ติดตาม"]
    : [CustomerSort.CreatedAt, "วันที่สร้าง"];

  return (
    <TableRow>
      <TableCell sx={{ width: columnsWidth[0] }}>
        <SortField field={field} />
        {label}
      </TableCell>
      <TableCell sx={{ width: columnsWidth[1] }}>ชื่อ</TableCell>
      <TableCell sx={{ width: columnsWidth[2] }} align="center">
        เบอร์โทรศัพท์
      </TableCell>
      <TableCell sx={{ width: columnsWidth[3] }}>สถานะ</TableCell>
      <TableCell sx={{ width: columnsWidth[4] }}>หมายเหตุ</TableCell>
    </TableRow>
  );
}

export function CustomerTableRow({ data, dailyTask }: CustomerTableRowProps) {
  const navigate = useNavigate();

  const {
    id: customerId,
    firstName,
    lastName,
    phoneNo,
    createdAt,
    status,
    followings,
  } = data;

  const id = customerId.toString();
  const { followAt, remark } = followings[followings.length - 1];

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(id);
  };

  const dailyTaskDateColor =
    now() >= followAt ? "primary.main" : "text.primary";

  const [dateColor, date] = dailyTask
    ? [dailyTaskDateColor, formatDate(fromISO(followAt.toString()))]
    : ["text.primary", formatDate(fromISO(createdAt.toString()))];

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2" color={dateColor}>
          {date}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">
          {firstName} {lastName}
        </TextEllipsis>
      </TableCell>
      <TableCell align="center">
        <TextEllipsis variant="body2">{phoneNo ? phoneNo : "-"}</TextEllipsis>
      </TableCell>
      <TableCell align="center">
        <Stack direction="row" gap={1} alignItems="center">
          <CircleIcon
            sx={{
              width: 12,
              height: 12,
              color: formatCustomerStatusColor(status),
            }}
          />
          <Typography variant="body2">
            {formatCustomerStatus(status)}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Typography noWrap width={columnsWidth[4]}>
          {remark || "-"}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

function SortField({ field }: { field: CustomerSort }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort") as CustomerSort | undefined;
  const sortType = searchParams.get("sortType") as SortType | undefined;
  const setSort = (s: CustomerSort) => () => {
    if (s) {
      searchParams.set("sort", s);
      searchParams.set(
        "sortType",
        sortType === SortType.Asc && sort === field
          ? SortType.Desc
          : SortType.Asc
      );
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };
  return (
    <IconButton
      sx={{ padding: 0, width: "auto", height: "auto" }}
      onClick={setSort(field)}
    >
      {sort === field && sortType === SortType.Asc ? (
        <ArrowDownwardIcon />
      ) : (
        <ArrowUpwardIcon />
      )}
    </IconButton>
  );
}
