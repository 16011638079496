import { Box, Stack } from "@mui/material";

import { MemberStatusLabel } from "@/features/member/components/MemberStatusLabel";
import { getMemberStatus, mapOptional } from "@/utils";
import {
  formatGender,
  formatProfile,
  formatProfiles,
  formatTimeRange,
} from "@/formatter";
import { transformISOToDate } from "@/lib/dateTime";

import { DataSection } from "./DataSection";
import { MediaViewer } from "./MediaViewer";

import type { Booking, Member } from "@/models";

type BookingMemberProfileProps = { member: Member; bookings?: Booking[] };

export function BookingMemberProfile({
  member,
  bookings = [],
}: BookingMemberProfileProps) {
  const avatarUrl = member.profile.avatarUrl;
  const photoUrls = avatarUrl ? [{ url: avatarUrl }] : [];
  const memberStatus = getMemberStatus(member);

  const classBookings = bookings.filter((booking) => !!booking.schedule.class);
  const trainerBookings = bookings.filter((booking) => !booking.schedule.class);

  const columns = [
    [
      {
        title: "ข้อมูลสมาชิก",
        data: [
          { label: "หมายเลขสมาชิก", value: member.code },
          {
            label: "สถานะ",
            value: <MemberStatusLabel variant="body2" status={memberStatus} />,
            key: memberStatus,
          },
          { label: "สาขาที่สมัคร", value: member.branch?.name },
          {
            label: "พนักงานขายที่ดูแล",
            value: mapOptional(member.staff?.profile, formatProfile),
          },
        ],
      },
    ],
  ];

  const columns2 = [
    [
      {
        title: "ข้อมูลส่วนบุคคล",
        data: [
          {
            label: "ชื่อ",
            value: member.profile.firstName,
          },
          {
            label: "นามสกุล",
            value: member.profile.lastName,
          },
          {
            label: "ชื่อเล่น",
            value: member.profile.nickname,
          },
          {
            label: "วัน/เดือน/ปีเกิด",
            value: transformISOToDate(member.profile.birthDate.toString()),
          },
          {
            label: "เบอร์โทรศัพท์",
            value: member.profile.phoneNo,
          },
        ],
      },
      {
        title: "แพ็กเกจสมาชิก",
        data: [
          {
            label: "ประเภท",
            value: member.membership?.product.name,
          },
          {
            label: "วันสิ้นสุดสมาชิก",
            value: mapOptional(
              member.membership?.endedAt.toString(),
              transformISOToDate
            ),
          },
        ],
      },
      {
        title: "แพ็กเกจเทรนเนอร์",
        data: [
          {
            label: "ประเภท",
            value: member.personalTraining?.product.name,
          },
          {
            label: "วันสิ้นสุดสมาชิก",
            value: mapOptional(
              member.personalTraining?.endedAt.toString(),
              transformISOToDate
            ),
          },
        ],
      },
    ],
  ];

  const columns3 = [
    [
      {
        title: "ตารางคลาสวันนี้",
        data:
          classBookings.length > 0
            ? classBookings.map(({ schedule }) => ({
                label: formatTimeRange(schedule.startedAt, schedule.endedAt),
                value: schedule.class?.name ?? "",
              }))
            : [
                {
                  label: "-",
                  value: " ",
                },
              ],
      },
      {
        title: "ตารางการเทรนวันนี้",
        data:
          trainerBookings.length > 0
            ? trainerBookings.map(({ schedule }) => ({
                label: formatTimeRange(schedule.startedAt, schedule.endedAt),
                value: mapOptional(schedule.staffs, formatProfiles) ?? "",
              }))
            : [
                {
                  label: "-",
                  value: " ",
                },
              ],
      },
    ],
  ];

  return (
    <Box display="grid" gridTemplateColumns="300px 1fr 1fr" gap={5}>
      <Stack gap={2}>
        <MediaViewer mediaList={photoUrls} imageWidth={300} imageHeight={300} />
        <DataSection columns={columns} />
      </Stack>
      <DataSection columns={columns2} />
      <DataSection columns={columns3} />
    </Box>
  );
}
