import { useController, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Stack } from "@mui/material";
import * as yup from "yup";
import { useEffect } from "react";

import { FormMembershipAutocomplete } from "@/components/MembershipAutocomplete";
import { FormProductPTAutocomplete } from "@/components/ProductPTAutocomplete";
import { FormDatePicker } from "@/components/DatePicker";
import { FormTextField } from "@/components/FormTextField";
import { datetime } from "@/lib/yup";
import { Section } from "@/components/Section";

import type { InferType } from "yup";
import type { ControllerProps } from "react-hook-form";

export type ProspectEditorState = InferType<typeof schema>;

type ProspectEditorProps = Pick<
  ControllerProps<ProspectEditorState>,
  "control"
>;

export function ProspectEditor({ control }: ProspectEditorProps) {
  const membership = useWatch({ name: "membership", control });
  const packagePT = useWatch({ name: "packagePT", control });
  const chance = useWatch({ name: "chance", control });

  const {
    field: { onChange },
  } = useController({ name: "total", control });

  const estimatePrice =
    ((membership?.price ?? 0) + (packagePT?.price ?? 0)) *
    ((chance ?? 0) / 100);

  useEffect(() => {
    if (membership || packagePT || chance) onChange(estimatePrice);
  }, [chance, estimatePrice, membership, onChange, packagePT]);

  return (
    <Stack gap={4}>
      <Section columns={1} label="ความสนใจ">
        <FormMembershipAutocomplete
          label="ชื่อแพ็กเกจสมาชิก"
          name="membership"
          control={control}
          required
        />
        <FormProductPTAutocomplete
          label={`ชื่อแพ็กเกจเทรนเนอร์`}
          name="packagePT"
          control={control}
        />
      </Section>
      <Divider />
      <Section columns={2} label="โอกาสที่คาดว่าจะปิดได้">
        <FormTextField
          label="จำนวน (%)"
          name="chance"
          control={control}
          required
        />
        <FormTextField
          label="ยอด (บาท)"
          name="total"
          control={control}
          value={estimatePrice.toFixed(2)}
          disabled
        />
        <FormDatePicker
          disablePast
          label="วันที่คาดว่าจะปิด"
          name="dueDate"
          control={control}
          TextFieldProps={{ required: true }}
        />
      </Section>
    </Stack>
  );
}

export function useProspectEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  membership: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
      duration: yup.object().required(),
      price: yup.number().required(),
    })
    .label("ชื่อแพ็กเกจสมาชิก")
    .required()
    .default(null), // fix yup not validate when value is undefined
  packagePT: yup
    .object({
      id: yup.number(),
      name: yup.string(),
      quota: yup.number(),
      duration: yup.object(),
      quotaUnit: yup.string().required(),
      price: yup.number().required(),
    })
    .default(null)
    .nullable(),
  total: yup.number(),
  chance: yup.number().integer().min(0).max(100).required().label("จำนวน (%)"),
  dueDate: datetime().required().label("วันที่คาดว่าจะปิด"),
});

const resolver = yupResolver(schema);

const defaultValues = {} as ProspectEditorState;

ProspectEditor.schema = schema;
ProspectEditor.defaultValues = defaultValues;
