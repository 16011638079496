import { Box, Tab, Tabs, TabsProps } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export function ProductAndPackageListPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname ?? "/products";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  return (
    <Box>
      <Tabs value={path} onChange={onChangeTab} sx={{ mb: 3 }}>
        <Tab label="แพ็กเกจสมาชิก" value="/products-and-packages" />
        <Tab label="แพ็กเกจเทรนเนอร์" value="/products-and-packages/pt" />
        <Tab label="สินค้า" value="/products-and-packages/products" />
      </Tabs>
      <Outlet />
    </Box>
  );
}
