import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { formatDate } from "@/formatter";
import { now } from "@/lib/dateTime";
import { CustomerStatus } from "@/models";

import { AddFollowingDialog } from "./AddFollowingDialog";

import type { Customer } from "@/models";

type CustomerFollowingProps = {
  data: Customer;
  queryKey: string;
};

export function CustomerFollowing({ data, queryKey }: CustomerFollowingProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dialog = searchParams.get("dialog");

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  function openAddDialog() {
    searchParams.set("dialog", "following-add");
    setSearchParams(searchParams);
  }

  const addFollowingDialog = {
    open: dialog === "following-add",
    onClose: onCloseDialog,
    customerId: data.id.toString(),
    fetchKeys: [queryKey],
  };

  const { followings, status } = data;
  const isFollowingCustomer = [
    CustomerStatus.Lead,
    CustomerStatus.Prospect,
  ].includes(status);

  return (
    <Card sx={{ height: "max-content" }}>
      <CardHeader
        title="การติดตามผล"
        action={
          isFollowingCustomer ? (
            <Button variant="contained" size="medium" onClick={openAddDialog}>
              เพิ่มการติดตาม
            </Button>
          ) : (
            <></>
          )
        }
        sx={{ ".MuiCardHeader-action": { m: 0 } }}
      />
      <CardContent>
        <Stepper orientation="vertical">
          {followings.map(({ followAt, remark, createdAt }, index) => (
            <Step
              key={createdAt.toISO()}
              active
              completed={index !== 0 || !isFollowingCustomer}
            >
              <StepLabel
                StepIconProps={{ icon: followings.length }}
                sx={{ gap: 2 }}
              >
                ติดตามครั้งที่ {followings.length - index}
              </StepLabel>
              <StepContent sx={{ pl: 4.5 }}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    วันที่บันทึก : {formatDate(createdAt)}
                  </Typography>
                  <Typography
                    variant="caption"
                    color={
                      isFollowingCustomer && index === 0 && followAt <= now()
                        ? "primary.main"
                        : "text.secondary"
                    }
                    mb={1}
                  >
                    วันที่ติดตาม : {formatDate(followAt)}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    หมายเหตุ : {remark || "-"}
                  </Typography>
                </Stack>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </CardContent>
      <AddFollowingDialog {...addFollowingDialog} />
    </Card>
  );
}
