import {
  Box,
  Card,
  CardContent,
  Stack,
  ButtonGroup,
  Button,
  TableContainer,
  Table,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect } from "react";

import { mapOptional, pickListTableParams } from "@/utils";
import { formatCustomerStatus } from "@/formatter";
import { DataTableBody } from "@/components/DataTableBody";
import { QueryTextField } from "@/components/QueryTextField";
import { ItemsTotal } from "@/components/ItemsTotal";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import {
  CustomerTableRow,
  CustomerTableRowHeader,
} from "@/features/customer/components/CustomerTableRow";
import { getTasks } from "@/services/task";
import { CustomerStatus } from "@/models";

const QUERY_KEY = "customer-tasks";

export function CustomerTaskListPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = pickListTableParams(searchParams);
  const { data: raw, isFetching } = useQuery(
    [QUERY_KEY, query],
    () => getTasks(query),
    { enabled: !!query }
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  const status = searchParams.get("status") as CustomerStatus | undefined;
  const setStatus = (s: CustomerStatus | null) => () => {
    if (s) {
      searchParams.set("status", s);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!query) {
      searchParams.set("sort", "lastFollowingAt");
      searchParams.set("sortType", "asc");
      setSearchParams(searchParams, { replace: true });
    }
  }, [query, searchParams, setSearchParams]);

  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            mb={5}
          >
            <QueryTextField
              sx={{ width: 340 }}
              placeholder="ค้นหาชื่อ หรือเบอร์โทรศัพท์"
            />
            <ButtonGroup variant="contained" size="small" color="inherit">
              {[null, CustomerStatus.Lead, CustomerStatus.Prospect].map((s) => (
                <Button
                  key={s}
                  onClick={setStatus(s)}
                  sx={{
                    bgcolor: s === status ? "grey.100" : "grey.300",
                    px: "22px",
                    py: 1,
                  }}
                >
                  {mapOptional(s, formatCustomerStatus) ?? "ทั้งหมด"}
                </Button>
              ))}
            </ButtonGroup>
          </Stack>
          <ItemsTotal count={total} />
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <CustomerTableRowHeader dailyTask />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <CustomerTableRow key={item.id} data={item} dailyTask />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
    </Box>
  );
}
