import { useRequireParams } from "@/utils";
import {
  Breadcrumbs,
  Box,
  Card,
  CircularProgress,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

import { getProductMembership } from "@/services/product";
import { MembershipDetail } from "./MembershipDetail";

export function ProductMembershipDetailPage() {
  const { id } = useRequireParams(["id"]);

  const { data, isFetching } = useQuery("product-membership", () =>
    getProductMembership(id)
  );

  const isFetched = !isFetching && typeof data !== "undefined";

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 4.5 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/products-and-packages">
          แพ็กเกจและสินค้า
        </MuiLink>
        <MuiLink component={Link} color="text.secondary" to="/products-and-packages">
          แพ็กเกจสมาชิก
        </MuiLink>
        <Typography color="text.primary">รายละเอียดแพ็กเกจสมาชิก</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {isFetched ? (
          <MembershipDetail data={data} />
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={228}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
    </>
  );
}
