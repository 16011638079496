import type { DateTime } from "luxon";

export type Role = {
  id: number;
  name: "Admin" | "Trainer" | "Manager" | "Sale" | "Staff";
};

export type UploadModule =
  | "classes"
  | "videos"
  | "trainers"
  | "categories"
  | "fitness";
// | "member_cards";

export enum Gender {
  Male = "male",
  Female = "female",
  Unspecified = "unspecified",
}

export enum BloodGroup {
  A = "A",
  AB = "AB",
  B = "B",
  O = "O",
  Unspecified = "unspecified",
}

export enum MaritalStatus {
  Single = "single",
  Marry = "marry",
  Widow = "widow",
  Divorce = "divorce",
  Unspecified = "unspecified",
}

export enum Channel {
  Facebook = "Facebook",
  Line = "Line",
  Google = "Google",
  Instagram = "Instagram",
  Friend = "Friend",
  Event = "Event",
  Brochure = "Brochure",
  Other = "Other",
}

export enum BookingStatus {
  Booked = "BOOKED",
  Waiting = "WAITING",
  Cancelled = "CANCELLED",
  Pending = "PENDING",
  Absent = "ABSENT",
  Attended = "ATTENDED",
}

export enum BookingType {
  Class = "CLASS",
  PersonalTraining = "PERSONAL_TRAINING",
}

export enum MemberStatus {
  Active = "Active",
  Expired = "Expired",
  Ban = "Ban",
}

export enum PurchaseType {
  Membership = "MEMBERSHIP",
  ProductPT = "PACKAGE_PT",
}

export enum DurationUnit {
  years = "years",
  months = "months",
  days = "days",
}

export enum TrainerContract {
  Freelance = "freelance",
  Employee = "employee",
}

export enum Day {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export enum DayShort {
  Friday = "fri",
  Monday = "mon",
  Saturday = "sat",
  Sunday = "sun",
  Thursday = "thu",
  Tuesday = "tue",
  Wednesday = "wed",
}

export enum FitnessType {
  Company = "company",
  LimitedPartnership = "limitedPartnership",
  NaturalPerson = "naturalPerson",
}

export enum RoleType {
  Admin = "เจ้าของกิจการ",
  Trainer = "ผู้ฝึกสอน",
  Manager = "ผู้จัดการสาขา",
  Sale = "พนักงานขาย",
  Staff = "พนักงาน",
}

export type UpdateAvatarProfileInput = {
  id: number;
  data: {
    avatar: string;
  };
};

export type Profile = {
  id: number;
  email: string;
  avatarUrl: string | null;
  idCard: string;
  firstName: string;
  lastName: string;
  nickname: string;
  birthDate: Date;
  gender: Gender;
  height: number | null | undefined;
  weight: number | null | undefined;
  maritalStatus: MaritalStatus | null | undefined;
  bloodGroup: BloodGroup | null;
  career: string | null | undefined;
  phoneNo: string;
  emergencyPerson: string | null | undefined;
  emergencyPhoneNo: string | null | undefined;
  emergencyRelation: string | null | undefined;
  address: string | undefined;
  postCode: string | undefined;
  province: string | undefined;
  district: string | undefined;
  subdistrict: string | undefined;
  line: string | undefined;
  facebook: string | undefined;
  contactOther: string | undefined;
  channel: Channel;
  channelOther: string | undefined;
  memberId: number | undefined;
  member: {
    id: number;
    code: string;
    accountId: string;
    profile: {
      firstName: string;
      lastName: string;
    };
  } | null;
};

export type StaffInput = {
  profile: {
    idCard: string;
    firstName: string;
    lastName: string;
    nickname: string;
    birthDate: Date;
    email: string;
    gender: string;
    phoneNo: string;
    emergencyPerson: string | undefined;
    emergencyPhoneNo: string | undefined;
    emergencyRelation: string | undefined;
    address: string | undefined;
    district: string | undefined;
    subdistrict: string | undefined;
    province: string | undefined;
    postCode: string | undefined;
  };
  roles: number[];
  branches: number[];
};

export type UpdateStaffInput = {
  id: string;
  data: StaffInput;
};

export type Staff = {
  id: number;
  accountId: string;
  code: string;
  profile: Profile;
  trainerProfileData: TrainerProfile;
  roles: Role[];
  branches: Pick<Branch, "id" | "name">[];
};

export type Following = {
  remark: string | null;
  followAt: DateTime;
  createdAt: DateTime;
};

export type Customer = {
  id: number;
  email: string | null;
  firstName: string;
  lastName: string | null;
  nickname: string | null;
  gender: Gender | null;
  phoneNo: string;
  line: string | null;
  facebook: string | null;
  contactOther: string | null;
  channel: Channel;
  channelOther: string | null;
  channelFriend: number | null;
  createdAt: DateTime;
  status: CustomerStatus;
  followings: Following[];
  productMembershipId: number | null;
  productMembership: {
    id: number;
    products: {
      name: string;
      price: number;
      productBranches: { branch: { id: number; name: string } }[];
    }[];
    durationIso: string;
  } | null;
  productPersonalTrainingId: number | null;
  productPersonalTraining: {
    id: number;
    products: {
      name: string;
      price: number;
      productBranches: { branch: { id: number; name: string } }[];
    }[];
    durationIso: string;
    quota: number;
    quotaUnit: QuotaUnit;
  } | null;
  chance: number | null;
  productTotal: number | null;
  productCloseAt: DateTime | null;
  purchaseProductMembership: { products: { name: string }[] };
  purchaseProductPersonalTraining: { products: { name: string }[] };
  purchaseTotal: number | null;
  purchaseAt: DateTime | null;
  customerStatusStamps: CustomerStatusStamp[];
  customerCloseStamps: CustomerCloseStamp[];
  customerUnqualifiedStamps: CustomerUnqualifiedStamp[];
  member: Pick<Member, "id" | "code"> | null;
  friend: Pick<Member, "id" | "accountId" | "code" | "profile"> | null;
  latestRemark: Remark;
};

type DateRange = {
  startTime: DateTime;
  endTime: DateTime;
};

export type WorkingDays = Record<DayString, DateRange>;

export type TrainerProfile = {
  id: number;
  avatarUrl?: string;
  youtubeIntroUrl?: string;
  personalTraining: boolean;
  contract: TrainerContract;
  skills: string[];
  certificates: string[];
  experiences: string[];
  workingDays: WorkingDays;
  deletedAt: Date | null;
};

export type OfficeDayInput = {
  monStartTime: Date | null;
  monEndTime: Date | null;
  tueStartTime: Date | null;
  tueEndTime: Date | null;
  wedStartTime: Date | null;
  wedEndTime: Date | null;
  thuStartTime: Date | null;
  thuEndTime: Date | null;
  friStartTime: Date | null;
  friEndTime: Date | null;
  satStartTime: Date | null;
  satEndTime: Date | null;
  sunStartTime: Date | null;
  sunEndTime: Date | null;
};

export type TrainerInput = {
  avatarUrl: string | null;
  youtubeIntroUrl: string | undefined;
  personalTraining: boolean;
  contract: TrainerContract;
  skills: string[];
  certificates: string[];
  experiences: string[];
  monStartTime: Date | null;
  monEndTime: Date | null;
  tueStartTime: Date | null;
  tueEndTime: Date | null;
  wedStartTime: Date | null;
  wedEndTime: Date | null;
  thuStartTime: Date | null;
  thuEndTime: Date | null;
  friStartTime: Date | null;
  friEndTime: Date | null;
  satStartTime: Date | null;
  satEndTime: Date | null;
  sunStartTime: Date | null;
  sunEndTime: Date | null;
};

export type UpdateTrainerInput = {
  id: string;
  data: TrainerInput;
};

export type Trainer = {
  id: number;
  code: string;
  profile: Profile;
  trainerProfileData: TrainerProfile;
  branches: Pick<Branch, "id" | "name">[];
};

export type Sale = {
  id: number;
  code: string;
  profile: Profile;
  branches: Pick<Branch, "id" | "name">[];
};

export type TimeRange = {
  start: DateTime;
  end: DateTime;
};

export type OfficeDay = {
  enable: boolean;
  day: Day;
  timeRange: TimeRange | null;
};

export type DayString = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";

export type ClassBranch = {
  id: number;
  publishedAt?: DateTime;
};

export type Class = {
  id: number;
  name: string;
  youtubeUrl?: string;
  description?: string;
  thumbnailUrls: string[];
  branches: ClassBranch[];
  numberOfSchedules: number;
  categories: Category[];
};

export type Schedule = {
  id: number;
  location: string;
  description?: string;
  startedAt: DateTime;
  endedAt: DateTime;
  cancelledAt?: DateTime;
  capacity: number;
  joinedAt: DateTime | null;
  cancelBefore: DateTime;
  staffs?: Staff[];
  class: Class | null;
  numberOfBookings: number;
  bookings: Booking[];
  scheduleTrainers: [{ staff: Staff }];
};

export enum ScheduleStatus {
  Active = "ACTIVE",
  Upcoming = "UPCOMING",
  Completed = "COMPLETE",
  Cancelled = "CANCELLED",
}

export type Booking = {
  id: number;
  cancelledAt: Date | null;
  attendedAt: Date | null;
  member: Member;
  isWaiting: boolean;
  schedule: Schedule;
  createdAt: Date;
  isPending: boolean;
};

export type Category = {
  id: number;
  name: string;
  description: string;
  thumbnailUrl: string | null;
};

export interface CatergoryData extends Category {
  videoCount?: number;
}

export type CreateCategoryInput = Omit<Category, "id">;

export type UpdateCategoryInput = {
  id: string;
  data: Omit<Category, "id">;
};

export type Video = {
  id: number;
  name: string;
  description: string | null;
  thumbnailUrl: string | null;
  youtubeUrl: string;
};

export type CreateVideoInput = Omit<Video, "id">;
export type UpdateVideoInput = {
  id: string;
  data: Omit<Video, "id">;
};

export type Product = {
  id: number;
  name: string;
  price: number;
  description: string;
  publishedAt?: DateTime;
  productMembershipsId?: number;
  productPersonalTrainingsId?: number;
};

export enum ProductType {
  Only = "only",
  Some = "some",
  Every = "every",
}

export type ProductMembership = {
  id: number;
  name: string;
  type: ProductType;
  price: number;
  description: string;
  productMembership: {
    id: number;
    durationIso: string;
  };
  productBranches: { branch: Pick<Branch, "id" | "name"> }[];
  branches: Pick<Branch, "id" | "name">[];
};

export type ProductMembershipInput = {
  name: string;
  price: number;
  description: string;
  durationIso: string;
  type: "some" | "only" | "every";
  branches: number[];
};

export enum QuotaUnit {
  hour = "hour",
  times = "times",
}

export type ProductPT = {
  id: number;
  name: string;
  type: ProductType;
  price: number;
  description: string;
  productPersonalTraining: {
    id: number;
    durationIso: string;
    quota: number;
    quotaUnit: QuotaUnit;
  };
  branches: Pick<Branch, "id" | "name">[];
};

export type ProductPTInput = {
  name: string;
  price: number;
  description: string;
  durationIso: string;
  quota: number;
  quotaUnit: QuotaUnit;
  type: "some" | "only" | "every";
  branches: number[];
};

export enum PersonalTax {
  JuristicPerson = "juristicPerson",
  NormalPerson = "normalPerson",
}

export type MemberInput = {
  profile: Omit<Profile, "id" | "avatarUrl" | "member">;
  taxAddress: string | undefined;
  taxPostCode: string | undefined;
  taxProvince: string | undefined;
  taxDistrict: string | undefined;
  taxSubdistrict: string | undefined;
  taxBranch: string | undefined;
  taxName: string | undefined;
  taxNo: string | undefined;
  taxType: PersonalTax | null;
  staffsId: number;
  productMembershipId: number;
  membershipName: string;
  membershipStartedAt: Date;
  membershipEndedAt: Date;
  membershipRemark: string | null;
  membershipPrice: number | undefined;
  membershipDiscountPercentage: number | undefined;
  membershipDiscountBaht: number | undefined;
  productPTId: number | null;
  ptName: string | undefined;
  ptStartedAt: Date | null;
  ptEndedAt: Date | null;
  ptRemark: string | null;
  ptPrice: number | undefined;
  ptDiscountPercentage: number | undefined;
  ptDiscountBaht: number | undefined;
  customerId?: number;
};

export type UpdateMemberInput = {
  profile: Omit<Profile, "id" | "avatarUrl" | "member">;
  taxAddress: string | undefined;
  taxPostCode: string | undefined;
  taxProvince: string | undefined;
  taxDistrict: string | undefined;
  taxSubdistrict: string | undefined;
  taxBranch: string | undefined;
  taxName: string | undefined;
  taxNo: string | undefined;
  taxType: PersonalTax | null;
  branchId: number | undefined;
  saleId: number | undefined;
};

export type BanMemberInput = {
  id: string;
  data: {
    isBan: boolean;
  };
};

export type VerifyMemberInput = {
  id: number;
  data: {
    code: string;
  };
};

type DoorPermission = {
  id: number;
  name: string;
  branches: {
    id: number;
    name: string;
    doors: { id: number; name: string }[];
  }[];
};

export type Member = {
  id: number;
  code: string | undefined;
  accountId: string | undefined;
  taxAddress: string | undefined;
  taxPostCode: string | undefined;
  taxProvince: string | undefined;
  taxDistrict: string | undefined;
  taxSubdistrict: string | undefined;
  taxBranch: string | undefined;
  taxName: string | undefined;
  taxNo: string | undefined;
  taxType: PersonalTax | null;
  banUntil: Date | null;
  profile: Profile;
  branch: Pick<Branch, "id" | "name"> | undefined;
  staff: Staff | undefined;
  memberships: Membership[];
  membership: Membership | null;
  personalTrainingQuota: PersonalTrainingQuota[];
  personalTraining: PersonalTrainingQuota | null;
  customerId: number | undefined;
  latestRemark: Remark | undefined;
  doorPermission?: DoorPermission;
  fitnessCenter?: {
    hasPermissionGroupControl: boolean;
  };
};

export enum MemberSort {
  Code = "code",
  Memberships = "memberships",
  PersonalTraining = "personalTraining",
}

export enum MemberSortType {
  Asc = "asc",
  Desc = "desc",
}

export enum CustomerSort {
  CreatedAt = "createdAt",
  LastFollowingAt = "lastFollowingAt",
}

export enum SortType {
  Asc = "asc",
  Desc = "desc",
}

export type Membership = {
  id: number;
  startedAt: Date;
  endedAt: Date;
  remark: string | null;
  product: ProductMembership;
  receipt: Receipt;
};

export type EditMembershipInput = {
  startedAt: Date;
  endedAt: Date;
  remark: string | null | undefined;
};

export type PurchaseMembershipInput = {
  staffsId: number;
  productMembershipId: number;
  name: string;
  startedAt: Date;
  endedAt: Date;
  remark: string | null;
  discountPercentage: number;
  discountBaht: number;
  price: number;
};

export type PurchasePTInput = {
  staffsId: number;
  productPTId: number;
  name: string;
  startedAt: Date;
  endedAt: Date;
  remark: string | null;
  discountPercentage: number;
  discountBaht: number;
  price: number;
};

export type PersonalTrainingQuota = {
  id: number;
  startedAt: Date;
  endedAt: Date;
  usedAt: Date | null;
  remark: string | null;
  product: ProductPT;
  useCount: number;
  receipt: Receipt;
};

export interface FitnessCenter {
  id: number;
  name: string;
  logoUrl?: string;
  type: FitnessType;
  isVatRegistration: boolean;
  companyName: string;
  phoneNo?: string | null;
  mobilePhoneNo?: string | null;
  faxNo?: string | null;
  taxNo: string;
  address: string;
  postCode: string;
  province: string;
  district: string;
  subdistrict: string;
}

export interface Branch {
  id: number;
  name: string;
  code: string | null;
  phoneNo: string;
  email: string | null;
  address: string;
  postCode: string;
  province: string;
  district: string;
  subdistrict: string;
  googleMapLink: string | null;
}

export interface StaffBranchRole {
  staffsId: number;
  branchsId: number;
  rolesId: number;
  role: Role;
}

export interface AuthResponsePayload {
  id: number;
  code: string;
  fitnessCentersId: number;
  profilesId: number;
  trainerProfilesId?: number | null;
  fitnessCenter: FitnessCenter;
  roles: Role[];
  profile: Profile;
  branches: Pick<Branch, "id" | "name">[];
}

export type GetMemberBookingInput = {
  memberId: number;
  type: BookingType;
  query?: string;
};

export type GetMemberClassBookingInput = {
  memberId: number;
  query?: string;
};

export type CheckIn = {
  id: number;
  member: Member;
  createdAt: DateTime;
};

export type ReceiptItem = {
  id: number;
  title: string;
  discount: number;
  discountPercent: number;
  amount: number;
  product: Product;
};

export enum ReceiptStatus {
  Approve = "approve",
  Cancelled = "cancelled",
}

export type Receipt = {
  id: number;
  status: ReceiptStatus;
  invoiceNo: string;
  subtotal: number;
  discountTotal: number;
  total: number;
  receiptItems: ReceiptItem[];
  staffs: Staff;
  createdAt: DateTime;
  member: Member;
};

export enum CustomerStatus {
  Lead = "lead",
  Prospect = "prospect",
  Member = "member",
  Close = "close",
  Unqualified = "unqualified",
}

export type CustomerStatusStamp = {
  status: CustomerStatus;
  createdAt: DateTime;
};

export type Remark = {
  customerId: number;
  staffId: number;
  staff?: { profile: Profile };
  remark: string;
  createdAt: DateTime;
};

export type CustomerCloseStamp = {
  remark: string;
  createdAt: DateTime;
};

export type CustomerUnqualifiedStamp = {
  remark: string;
  createdAt: DateTime;
};

export type MemberBooking = {
  id: number;
  status: BookingStatus;
  schedule: Pick<Schedule, "id" | "location" | "startedAt" | "endedAt"> & {
    trainers: Array<
      Pick<Staff, "id"> & {
        profile: Pick<Profile, "firstName" | "lastName" | "nickname">;
      }
    >;
    branch: Pick<Branch, "name">;
    class: Pick<Class, "id" | "name">;
  };

  createdAt: DateTime;
};

export enum OpportunityStatus {
  Open = "open",
  CloseWon = "closeWon",
  CloseLose = "closeLose",
}

export type Opportunity = {
  id: number;
  member: Pick<Member, "id" | "code" | "customerId" | "latestRemark"> & {
    profile: Pick<
      Profile,
      "firstName" | "lastName" | "nickname" | "gender" | "phoneNo" | "email"
    >;
  };
  receipt?: {
    total: number;
    createdAt: DateTime;
    receiptItems: {
      product: {
        name: string;
        productMembershipsId?: number;
        productPersonalTrainingsId?: number;
      };
    }[];
  };
  percentageChance: number;
  totalChance: number;
  expectedClosingAt: DateTime;
  status: OpportunityStatus;
  createdAt: DateTime;
  closeLoseAt?: DateTime;
  closeLoseRemark?: string;
  followings: Following[];
  opportunityInterests: OpportunityInterest[];
  latestRemark: Remark;
  lastFollowingAt: DateTime;
};

export type OpportunityInterest = {
  product: Pick<Product, "id" | "name" | "price"> & {
    productMembership?: { id: number; durationIso: string };
    productPersonalTraining?: {
      id: number;
      durationIso: string;
      quota: number;
      quotaUnit: string;
    };
    branches: Pick<Branch, "id" | "name">[];
  };
};
export enum OpportunitySort {
  CreatedAt = "createdAt",
  LastFollowingAt = "lastFollowingAt",
}

export enum DiscountType {
  Bath = "baht",
  Percentage = "percentage",
}

export enum TrainingGoal {
  BodyComposition = "bodyComposition",
  GeneralHealth = "generalHealth",
  SkillRelated = "skillRelated",
  SportPerformance = "sportPerformance",
  Other = "other",
}

export enum TrainingStatus {
  InProgress = "inProgress",
  Success = "success",
  Failed = "failed",
}

export type TrainingItem = {
  id: number;
  memberName: string;
  trainerName: string;
  memberPhone: string;
  goals: TrainingGoal[];
  latestFollowedAt: DateTime;
  status: TrainingStatus;
  endedAt: DateTime;
};

export type FollowUpItem = {
  id: string;
  followedAt: DateTime;
  remark: string | null;
  summary: string | null;
};

type TrainingTrainer = {
  profile: {
    firstName: string;
    lastName: string;
    nickname: string;
  };
};

type TrainingChangeLog = {
  createdAt: DateTime;
  updatedBy: string;
  goals: TrainingGoal[];
  startedAt: DateTime;
  endedAt: DateTime;
  detail: string | null;
  reason: string | null;
  trainer: TrainingTrainer;
};

export type Training = {
  id: number;
  trainer: TrainingTrainer;
  member: {
    id: number;
    code: string;
    profile: {
      firstName: string;
      lastName: string;
      nickname: string;
    };
  };
  startedAt: DateTime;
  endedAt: DateTime;
  goals: TrainingGoal[];
  detail: string | null;
  status: TrainingStatus;
  summary: string | null;
  reason: string | null;
  finishedAt: DateTime | null;
  finishedBy: string | null;
  updatedAt: DateTime;
  updatedBy: string | null;
  trainingFollowUps: FollowUpItem[];
  memberTrainingChangeLogs: TrainingChangeLog[];
};

export type FollowUpChangeLog = {
  createdAt: DateTime;
  followedAt: DateTime;
  updatedBy?: string | null;
  remark?: string | null;
  reason?: string | null;
};

export type FollowUp = {
  id: number;
  no: number;
  followedAt: DateTime;
  remark?: string | null;
  summary?: string | null;
  reason?: string | null;
  updatedAt: DateTime;
  updatedBy?: string | null;
  trainingFollowUpChangeLogs: FollowUpChangeLog[];
  memberTraining: {
    id: number;
    status: TrainingStatus;
    startedAt: DateTime;
    endedAt: DateTime;
  };
  isLastFollowUp: boolean;
};

export enum PermissionGroupType {
  member = "member",
  staff = "staff",
}

export interface PermissionDoor {
  id: number;
  name: string;
  type: PermissionGroupType;
  doors: {
    id: number;
    name: string;
  }[];
}
