import { Link, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Box,
  Breadcrumbs,
  Link as MuiLink,
  Stack,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { CircularLoading } from "@/components/CircularLoading";
import { CustomerRemark } from "@/components/CustomerRemark";
import { useRequireParams } from "@/utils";
import { getOpportunity } from "@/services/opportunity";

import { OpportunityFollowing } from "./OpportunityFollowing";
import { OpportunityDetail } from "./OpportunityDetail";

const QUERY_KEY = "getOpportunity";

export function OpportunityDetailPage() {
  const { id } = useRequireParams(["id"]);
  const location = useLocation();
  const path = location?.pathname;
  const isTask = path.includes("daily-tasks");
  const title = isTask ? "สิ่งที่ต้องทำ" : "การจัดการลูกค้า";

  const { data, isFetching } = useQuery(QUERY_KEY, () => getOpportunity(id));

  return isFetching || !data ? (
    <CircularLoading height="calc(100vh - 182px)" />
  ) : (
    <Stack gap={4}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <MuiLink component={Link} color="text.secondary" to="..">
          {title}
        </MuiLink>
        <MuiLink component={Link} color="text.secondary" to="../opportunities">
          รายการเพิ่มยอดขาย
        </MuiLink>
        <Typography color="text.primary">รายละเอียดลูกค้า</Typography>
      </Breadcrumbs>
      <Box
        display="grid"
        gridTemplateColumns="2fr 1fr"
        gap={{ xs: 2, tablet: 3 }}
      >
        <Stack gap={{ xs: 2, tablet: 3 }}>
          <OpportunityDetail data={data} queryKey={QUERY_KEY} />
          {data.member.customerId && (
            <CustomerRemark
              data={data.member.latestRemark}
              customerId={data.member.customerId}
              queryKey={QUERY_KEY}
            />
          )}
        </Stack>
        <OpportunityFollowing data={data} queryKey={QUERY_KEY} />
      </Box>
    </Stack>
  );
}
