import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { getApiErrorMessage, refetchQueries } from "@/utils";
import { transformDurationISOtoObject } from "@/lib/duration";
import { updateOpportunityInterest } from "@/services/opportunity";
import { theme } from "@/theme";
import { PurchaseType } from "@/models";

import {
  InterestEditor,
  useInterestEditorForm,
} from "../../components/InterestEditor";

import type { Opportunity } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type EditInterestDialogProps = {
  data: Opportunity;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function EditInterestDialog({
  data,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: EditInterestDialogProps) {
  const { control, handleSubmit, reset } = useInterestEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: update, isLoading } = useMutation(updateOpportunityInterest, {
    onSuccess: async () => {
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", {
        variant: "success",
      });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  function close() {
    onClose();
    reset();
  }

  useEffect(() => {
    if (data) {
      reset({
        products: data.opportunityInterests.map(
          ({
            product: {
              id,
              name,
              price,
              productMembership,
              productPersonalTraining,
            },
          }) => {
            const type = productMembership
              ? PurchaseType.Membership
              : PurchaseType.ProductPT;

            return {
              type,
              membership: productMembership
                ? {
                    productId: id,
                    name,
                    price,
                    ...productMembership,
                    duration: transformDurationISOtoObject(
                      productMembership.durationIso
                    ),
                  }
                : null,
              packagePT: productPersonalTraining
                ? {
                    productId: id,
                    name,
                    price,
                    ...productPersonalTraining,
                    duration: transformDurationISOtoObject(
                      productPersonalTraining.durationIso
                    ),
                  }
                : null,
            };
          }
        ),
        totalChance: data.totalChance,
        percentageChance: data.percentageChance,
        expectedClosingAt: data.expectedClosingAt,
      });
    }
  }, [data, reset]);

  const onSubmit = handleSubmit((form) => {
    const productIds = form.products
      .map(({ type, membership, packagePT }) =>
        type === PurchaseType.Membership
          ? membership?.productId
          : packagePT?.productId
      )
      .filter(Boolean) as number[];

    update({
      opportunityId: data.id.toString(),
      productIds,
      totalChance: form.totalChance,
      percentageChance: form.percentageChance,
      expectedClosingAt: form.expectedClosingAt,
    });
  });

  const title = "แก้ไขเสนอการขาย";

  const isTablet = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <FormConfirmDialog
      maxWidth={isTablet ? "md" : "lg"}
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <InterestEditor control={control} />
    </FormConfirmDialog>
  );
}
