import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useMediaQuery } from "@mui/material";

import { FormTextField } from "@/components/FormTextField";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { unqualifiedCustomer } from "@/services/customer";
import { getApiErrorMessage, refetchQueries } from "@/utils";
import { theme } from "@/theme";

import type { InferType } from "yup";
import type { AxiosErrorWithData } from "@/client/api";

type UnqualifiedCustomerEditorState = InferType<typeof schema>;

type UnqualifiedCustomerDialogProps = {
  customerId: string;
  open: boolean;
  onClose: () => void;
  fetchKeys: string[];
};

export function UnqualifiedCustomerDialog({
  customerId,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: UnqualifiedCustomerDialogProps) {
  const { control, handleSubmit, reset } = useUnqualifiedCustomerEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: unqualified, isLoading } = useMutation(unqualifiedCustomer, {
    onSuccess: async () => {
      enqueueSnackbar("เปลี่ยนสถานะเป็นไม่ตรงเป้าหมายสำเร็จ", {
        variant: "success",
      });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit((form) => {
    unqualified({
      customerId,
      remark: form.remark,
    });
  });

  const title = "เหตุผลที่ไม่ตรงเป้าหมาย";

  const isTablet = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <FormConfirmDialog
      maxWidth={isTablet ? "md" : "lg"}
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <FormTextField
        label="เหตุผล"
        name="remark"
        control={control}
        multiline
        rows={4}
        fullWidth
        required
      />
    </FormConfirmDialog>
  );
}

function useUnqualifiedCustomerEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  remark: yup.string().label("เหตุผล").required(),
});

const resolver = yupResolver(schema);

const defaultValues = { remark: "" } as UnqualifiedCustomerEditorState;
