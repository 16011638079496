import {
  Backdrop,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { CircularLoading } from "@/components/CircularLoading";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import {
  getApiErrorMessage,
  pickListTableParams,
  useRequireParams,
} from "@/utils";
import { getReceiptPDF, getReceiptsByMember } from "@/services/receipts";
import { ItemsTotal } from "@/components/ItemsTotal";

import {
  TransactionTableRow,
  TransactionTableHeaderRow,
} from "./TransactionTableRow";
import { CancelReceiptDialog } from "./CancelReceiptDialog";

import type { AxiosErrorWithData } from "@/client/api";

const QUERY_KEY = "receipts";

export function useReceipts(id: string, query: string) {
  return useQuery([QUERY_KEY, query], () => getReceiptsByMember(id, query));
}

export function TransactionListPage() {
  const { memberId } = useRequireParams(["memberId"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: generatePDF, isLoading: isPDFLoading } = useMutation(
    getReceiptPDF,
    {
      onSuccess: (url) => {
        window.open(url, "_blank");
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    }
  );

  const query = pickListTableParams(searchParams);

  const { data: raw, isLoading } = useReceipts(memberId, query);

  const data = raw?.data ?? [];
  const total = raw?.total;

  const dialog = searchParams.get("dialog");
  const cancelReceiptId = searchParams.get("cancelReceiptId") ?? "";
  const showMsg = !!searchParams.get("showMsg");

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("cancelReceiptId");
    searchParams.delete("showMsg");
    setSearchParams(searchParams, { replace: true });
  }

  const cancelReceiptDialog = {
    open: dialog === "receipt-cancel",
    id: cancelReceiptId,
    onClose: onCloseDialog,
    showMsg,
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Stack gap={3}>
      <Card>
        <CardContent>
          <Stack gap={3}>
            <ItemsTotal count={total} />
            <TableContainer sx={{ height: "684px", overflowX: "hidden" }}>
              <Table stickyHeader>
                <TableHead>
                  <TransactionTableHeaderRow />
                </TableHead>
                <DataTableBody loading={isLoading} data={data}>
                  {data.map((item) => (
                    <TransactionTableRow
                      key={item.id}
                      data={item}
                      generatePDF={generatePDF}
                    />
                  ))}
                </DataTableBody>
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <Backdrop open={isPDFLoading} sx={{ zIndex: 10000 }}>
        <CircularLoading height="100vh" />
      </Backdrop>
      <CancelReceiptDialog {...cancelReceiptDialog} />
    </Stack>
  );
}
