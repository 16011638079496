import { api } from "@/client/api";
import { configs } from "@/configs";
import { CheckIn } from "@/models";

type GetCheckIns = {
  data: CheckIn[];
  total: number;
};

export async function getCheckIns(query = "") {
  const { data } = await api.get<GetCheckIns>(
    `${configs.apiUrl}/check-in?${query}`
  );
  return data;
}

export async function checkIn(id: number) {
  const { data } = await api.post<CheckIn>(`${configs.apiUrl}/check-in/${id}`);
  return data;
}
