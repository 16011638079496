import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "react-query";
import {
  useController,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import {
  FormMemberAutocomplete,
  MemberAutocomplete,
} from "@/components/MemberAutocomplete";
import {
  FormProductAutocomplete,
  ProductAutocomplete,
} from "@/components/ProductAutocomplete";
import { Section } from "@/components/Section";
import { FormSaleAutocomplete } from "@/features/member/pages/MyMemberListPage/SaleAutocomplete";
import { SummaryPriceDetail } from "@/features/member/components/SummaryPriceDetail";
import { FormNumericTextField } from "@/components/NumericTextField";
import { FormSelect } from "@/components/Select";
import { formatDiscountType } from "@/formatter";
import { purchaseProducts } from "@/services/product";
import { DiscountType } from "@/models";
import { decimal } from "@/lib/yup/validation";
import { configs } from "@/configs";

import type { Control } from "react-hook-form";
import type { AxiosErrorWithData } from "@/client/api";

type PurchaseProductEditorState = yup.InferType<typeof schema>;

export function PurchaseProductEditorDialog({
  open: isOpen,
  onClose,
  member,
  sale,
}: {
  open: boolean;
  onClose: () => void;
  member?: {
    id: number;
    accountId: string;
    code: string;
    firstName: string;
    lastName: string;
    phoneNo: string;
  };
  sale?: {
    id: number;
    name: string;
  };
}) {
  const { control, handleSubmit, reset } = usePurchaseProductEditorForm();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (member || sale) {
      reset({
        member: member ?? null,
        products: defaultValues.products,
        sale: sale ?? undefined,
      });
    }
  }, [member, reset, sale]);

  function close() {
    onClose();
    reset();
  }

  const { mutate: purchase, isLoading } = useMutation(purchaseProducts, {
    onSuccess: () => {
      enqueueSnackbar("ซื้อสินค้าสำเร็จ", { variant: "success" });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    const input = {
      memberId: form.member?.id,
      staffId: form.sale.id,
      purchaseProducts: form.products.map(
        ({ product, quantity, discountBaht, discountPercentage }) => ({
          productId: product.id,
          name: product.name,
          price: product.price,
          quantity,
          discountBaht: discountBaht ?? 0,
          discountPercentage: discountPercentage ?? 0,
        })
      ),
    };

    purchase(input);
  });

  const title = "ซื้อสินค้า";

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <Stack gap={2.5}>
        {!member && (
          <>
            <MemberSection control={control} />
            <Divider />
          </>
        )}
        <SaleSection control={control} />
        <Divider />
        <ProductSection control={control} />
        <Divider />
        <SummarySection control={control} />
      </Stack>
    </FormConfirmDialog>
  );
}

function MemberSection({
  control,
}: {
  control: Control<PurchaseProductEditorState>;
}) {
  return (
    <Stack gap={2.5}>
      <Box
        display="flex"
        gridTemplateColumns="50px 1fr"
        gap={3}
        alignItems="center"
      >
        <QrCodeScannerIcon fontSize="large" />
        <Typography variant="subtitle1">
          สแกนคิวอาร์โค้ด เพื่อตรวจสอบข้อมูลของสมาชิก
        </Typography>
      </Box>
      <FormMemberAutocomplete
        label="หมายเลขสมาชิก ชื่อ หรือเบอร์โทรศัพท์"
        name="member"
        control={control}
      />
    </Stack>
  );
}

function SaleSection({
  control,
}: {
  control: Control<PurchaseProductEditorState>;
}) {
  return (
    <Section columns={1} label="พนักงานขาย">
      <FormSaleAutocomplete
        label="ชื่อพนักงานขาย"
        name="sale"
        control={control}
        required
      />
    </Section>
  );
}

function ProductSection({
  control,
}: {
  control: Control<PurchaseProductEditorState>;
}) {
  const { fields, append, remove } = useFieldArray({
    name: "products",
    control,
  });

  const onAdd = () => append(productDefaultValues);
  const onClickDelete = (index: number) => remove(index);

  return (
    <Stack gap={2.5}>
      <Stack direction="row" gap={2.5} alignItems="center">
        <Typography variant="labelLarge">รายการสินค้า</Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{ width: 100 }}
          onClick={onAdd}
          disabled={fields.length >= 10}
        >
          เพิ่ม
        </Button>
      </Stack>
      {fields.map((product, index) => (
        <PurchaseProductItem
          key={product.id}
          index={index}
          control={control}
          onClickDelete={() => onClickDelete(index)}
        />
      ))}
    </Stack>
  );
}

type PurchaseProductItemProps = {
  index: number;
  control: Control<PurchaseProductEditorState>;
  onClickDelete: () => void;
};

function PurchaseProductItem({
  index,
  control,
  onClickDelete,
}: PurchaseProductItemProps) {
  const product = useWatch({ name: `products.${index}.product`, control });
  const quantity = useWatch({ name: `products.${index}.quantity`, control });
  const type = useWatch({ name: `products.${index}.type`, control });
  const discountPercentage = useWatch({
    name: `products.${index}.discountPercentage`,
    control,
  });
  const discountBaht = useWatch({
    name: `products.${index}.discountBaht`,
    control,
  });

  const {
    field: { onChange: onChangeDiscountPercentage },
  } = useController({ name: `products.${index}.discountPercentage`, control });
  const {
    field: { onChange: onChangeDiscountBaht },
  } = useController({ name: `products.${index}.discountBaht`, control });
  const {
    field: { onChange: onChangeQuantity },
  } = useController({ name: `products.${index}.quantity`, control });
  const {
    field: { onChange: onChangeDiscountType },
  } = useController({ name: `products.${index}.type`, control });

  const price = (product?.price ?? 0) * (quantity ?? 0);

  useEffect(() => {
    if (!product) {
      onChangeDiscountPercentage(0);
      onChangeDiscountBaht(0);
      onChangeQuantity(1);
      onChangeDiscountType(DiscountType.Percentage);
    }
  }, [
    onChangeDiscountBaht,
    onChangeDiscountPercentage,
    onChangeQuantity,
    onChangeDiscountType,
    product,
  ]);

  useEffect(() => {
    if (type === DiscountType.Percentage) {
      onChangeDiscountBaht(price * ((discountPercentage ?? 0) / 100));
    }
  }, [discountPercentage, onChangeDiscountBaht, price, type]);

  function onChangeType() {
    onChangeDiscountPercentage(0);
    onChangeDiscountBaht(0);
  }

  const amount = price - (discountBaht ?? 0);

  return (
    <Stack direction="row" gap={2}>
      <FormProductAutocomplete
        name={`products.${index}.product`}
        control={control}
        label="ชื่อสินค้า"
        required
        TextFieldProps={{ sx: { width: 416 } }}
      />
      <FormNumericTextField
        allowZero
        label="จำนวนสินค้า"
        name={`products.${index}.quantity`}
        control={control}
        sx={{ width: 88 }}
        disabled={!product}
        onBlur={(event) => {
          const quantity = +event.target.value;
          if (quantity <= 0) {
            onChangeQuantity(1);
          }
        }}
      />
      <TextField
        label="ราคาต่อหน่วย (บาท)"
        value={price}
        disabled
        sx={{ width: 128 }}
      />
      <FormSelect
        name={`products.${index}.type`}
        control={control}
        label="รูปแบบส่วนลด"
        sx={{ width: 152 }}
        disabled={!product}
        onChange={onChangeType}
      >
        {[DiscountType.Percentage, DiscountType.Bath].map((type) => (
          <MenuItem key={type} value={type}>
            {formatDiscountType(type)}
          </MenuItem>
        ))}
      </FormSelect>
      {type === DiscountType.Percentage ? (
        <FormNumericTextField
          allowZero
          label="ส่วนลด (%)"
          name={`products.${index}.discountPercentage`}
          control={control}
          sx={{ width: 96 }}
          disabled={!product}
        />
      ) : (
        <FormNumericTextField
          allowZero
          label="ส่วนลด (บาท)"
          name={`products.${index}.discountBaht`}
          control={control}
          sx={{ width: 96 }}
          disabled={!product}
        />
      )}
      <TextField
        label="ราคารวม (บาท)"
        value={amount}
        disabled
        sx={{ width: 128 }}
      />
      {index !== 0 && (
        <IconButton onClick={onClickDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  );
}

function SummarySection({
  control,
}: {
  control: Control<PurchaseProductEditorState>;
}) {
  const products = useWatch({ name: "products", control });

  const { subtotal, totalDiscount } = products.reduce(
    (memo, { product, quantity, discountBaht }) => {
      const price = (product?.price ?? 0) * quantity;

      return {
        subtotal: memo.subtotal + price,
        totalDiscount: memo.totalDiscount + (discountBaht ?? 0),
      };
    },
    {
      subtotal: 0,
      totalDiscount: 0,
    }
  );

  const grandTotal = subtotal - totalDiscount;

  return (
    <SummaryPriceDetail
      subTotal={subtotal}
      discount={totalDiscount}
      grandTotal={grandTotal}
    />
  );
}

function usePurchaseProductEditorForm() {
  return useForm({ resolver, defaultValues });
}

const productSchema = yup.object({
  product: ProductAutocomplete.schema.label("ชื่อสินค้า").required(),
  quantity: yup.number().required(),
  type: yup.string().oneOf(Object.values(DiscountType)).required(),
  discountPercentage: yup
    .number()
    .concat(decimal)
    .min(0)
    .max(100)
    .label("ส่วนลด"),
  discountBaht: yup.number().concat(decimal).min(0).label("ส่วนลด"),
});

const schema = yup.object({
  member: MemberAutocomplete.schema.label("หมายเลขสมาชิกหรือชื่อ"),
  sale: yup
    .object({
      id: yup.number().integer().required(),
      name: yup.string().required(),
    })
    .label("ชื่อพนักงานขาย")
    .default(null)
    .nullable()
    .required(),
  products: yup.array(productSchema).required().min(1),
});

const resolver = yupResolver(schema);

const productDefaultValues = {
  product: undefined,
  quantity: 1,
  type: DiscountType.Percentage,
  discountPercentage: 0,
  discountBaht: 0,
};

const defaultValues = {
  products: [productDefaultValues],
} as unknown as PurchaseProductEditorState;
