import { api } from "@/client/api";
import { configs } from "@/configs";
import { Receipt } from "@/models";
import { DateTime } from "luxon";
import { parseDateTimeFromPrisma, parseURLSearchParams } from "@/utils";

type GetReceipts = {
  total: number;
  data: Receipt[];
};

export async function getReceiptsByMember(id: string, query = "") {
  const { data } = await api.get<GetReceipts>(
    `${configs.apiUrl}/receipts/${id}?${query}`
  );
  return data;
}

export async function getReceipts(date: DateTime, query = "") {
  const param = parseURLSearchParams({ date: date.toISO() });
  const { data } = await api.get<GetReceipts>(
    `${configs.apiUrl}/receipts?${param}&${query}`
  );
  return parseDateTimeFromPrisma(data) as GetReceipts;
}

export async function getReceiptPDF(id: string | number): Promise<string> {
  const response = await api.get<string>(
    `${configs.apiUrl}/receipts/${id}/pdf`
  );
  return response.data;
}

export async function cancelReceipt(id: number): Promise<Receipt> {
  const response = await api.post<Receipt>(
    `${configs.apiUrl}/receipts/${id}/cancel`
  );
  return response.data;
}
