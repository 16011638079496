import {
  Outlet,
  useLocation,
  useNavigate,
  Link,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import NavigateNext from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Link as MuiLink,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";

import { CustomerRemark } from "@/components/CustomerRemark";
import { getDocumentTitle, useRequireParams } from "@/utils";
import { getMember } from "@/services/member";

import { MemberProduct } from "./MemberProduct";
import { MemberProfile } from "./MemberProfile";
import { MemberPermissionGroup } from "./MemberPermissionGroup";

import type { TabsProps } from "@mui/material";

// TODO: refactor
export const MEMBER_DETAIL_QUERY_KEY = "member";

export function useMember(id: string) {
  return useQuery(MEMBER_DETAIL_QUERY_KEY, () => getMember(+id));
}

export function MemberProfilePage() {
  const { memberId } = useRequireParams(["memberId"]);
  const { opportunityId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname ?? "";
  const isAnother = path.includes("another");
  const isOpportunity = path.includes("opportunities");
  const isTask = path.includes("daily-tasks");

  const mainOpportunityPath = isTask ? "daily-tasks" : "customers";

  const memberListPath = isOpportunity
    ? `/${mainOpportunityPath}/opportunities/${opportunityId ?? ""}/members`
    : isAnother
    ? "/members/another"
    : "/members";

  const breadcrumbs = isOpportunity
    ? [
        {
          label: isTask ? "สิ่งที่ต้องทำ" : "การจัดการลูกค้า",
          to: `/${mainOpportunityPath}`,
        },
        {
          label: "รายการเพิ่มยอดขาย",
          to: `/${mainOpportunityPath}/opportunities`,
        },
        {
          label: "รายละเอียดลูกค้า",
          to: `/${mainOpportunityPath}/opportunities/${opportunityId ?? ""}`,
        },
      ]
    : [
        { label: "สมาชิก", to: "/members" },
        {
          label: isAnother ? "สมาชิกอื่นๆ" : "สมาชิกที่ดูแล",
          to: isAnother ? "/members/another" : "/members",
        },
      ];

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  const { data: profileData, isFetching } = useMember(memberId);

  const isLoaded = !isFetching && typeof profileData !== "undefined";
  const title = profileData?.code ?? "";
  const isShowPermission =
    !!profileData?.fitnessCenter?.hasPermissionGroupControl;

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 4.5 }}
      >
        {breadcrumbs.map(({ label, to }) => (
          <MuiLink key={label} component={Link} color="text.secondary" to={to}>
            {label}
          </MuiLink>
        ))}
        <Typography color="text.primary">ข้อมูลสมาชิก</Typography>
      </Breadcrumbs>
      <Stack spacing={3}>
        {isLoaded ? (
          <>
            <MemberProfile data={profileData} />
            {isShowPermission && <MemberPermissionGroup data={profileData} />}
            {profileData.customerId && (
              <CustomerRemark
                customerId={profileData.customerId}
                data={profileData.latestRemark}
                queryKey={MEMBER_DETAIL_QUERY_KEY}
                isEditable={!isAnother}
              />
            )}
          </>
        ) : (
          <Card>
            <Box display="grid" sx={{ placeItems: "center" }} height={1192}>
              <CircularProgress disableShrink />
            </Box>
          </Card>
        )}
        <MemberProduct data={profileData} isLoading={isFetching} />
        <Box>
          <Tabs value={path} sx={{ mb: 2 }} onChange={onChangeTab}>
            <Tab
              label="ประวัติการจองคลาส"
              value={`${memberListPath}/${memberId}`}
            />
            <Tab
              label="ประวัติการจองเทรนเนอร์"
              value={`${memberListPath}/${memberId}/pt`}
            />
            <Tab
              label="ประวัติการชำระเงิน"
              value={`${memberListPath}/${memberId}/transactions`}
            />
            <Tab
              label="ประวัติเป้าหมายการเทรน"
              value={`${memberListPath}/${memberId}/trainings`}
            />
          </Tabs>
          <Outlet />
        </Box>
      </Stack>
    </Box>
  );
}
