import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { updateProspectData } from "@/services/customer";
import { getApiErrorMessage, mapOptional, refetchQueries } from "@/utils";
import { transformDurationISOtoObject } from "@/lib/duration";

import { ProspectEditor, useProspectEditorForm } from "./ProspectEditor";

import type { Customer } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type EditProspectDialogProps = {
  data: Customer;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function EditProspectDialog({
  data,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: EditProspectDialogProps) {
  const { control, handleSubmit, reset } = useProspectEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: updateProspect, isLoading } = useMutation(
    updateProspectData,
    {
      onSuccess: async () => {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", {
          variant: "success",
        });
        await refetchQueries({ queryClient, fetchKeys });
        close();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    }
  );

  function close() {
    onClose();
    reset();
  }

  useEffect(() => {
    if (data) {
      reset({
        membership: data.productMembership
          ? {
              id: data.productMembership.id,
              name: data.productMembership.products[0].name,
              duration: transformDurationISOtoObject(
                data.productMembership.durationIso
              ),
              price: data.productMembership.products[0].price,
            }
          : undefined,
        packagePT: data.productPersonalTraining
          ? {
              id: data.productPersonalTraining.id,
              name: data.productPersonalTraining.products[0].name,
              quota: data.productPersonalTraining.quota,
              duration: transformDurationISOtoObject(
                data.productPersonalTraining.durationIso
              ),
              quotaUnit: data.productPersonalTraining.quotaUnit,
              price: data.productPersonalTraining.products[0].price,
            }
          : undefined,
        total: data.productTotal || undefined,
        chance: data.chance || undefined,
        dueDate: data.productCloseAt || undefined,
      });
    }
  }, [data, reset]);

  const onSubmit = handleSubmit((form) => {
    updateProspect({
      id: data.id,
      productMembershipId: +form.membership.id,
      productPersonalTrainingId:
        mapOptional(form.packagePT?.id, Number) || null,
      chance: form.chance,
      productTotal: form.total ?? 0,
      productCloseAt: form.dueDate,
    });
  });

  const title = "แก้ไขผู้ที่มีโอกาสซื้อ";

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <ProspectEditor control={control} />
    </FormConfirmDialog>
  );
}
