import { getProductPT } from "@/services/product";
import { useRequireParams } from "@/utils";
import {
  Breadcrumbs,
  Box,
  Link as MuiLink,
  Typography,
  Card,
  CircularProgress,
} from "@mui/material";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { ProductPTDetail } from "./ProductPTDetail";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export function ProductPTDetailPage() {
  const { id } = useRequireParams(["id"]);

  const { data, isFetching } = useQuery("product-membership", () =>
    getProductPT(id)
  );

  const isFetched = !isFetching && typeof data !== "undefined";

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 4.5 }}
      >
        <MuiLink
          component={Link}
          color="text.secondary"
          to="/products-and-packages"
        >
          แพ็กเกจและสินค้า
        </MuiLink>
        <MuiLink
          component={Link}
          color="text.secondary"
          to="/products-and-packages/pt"
        >
          แพ็กเกจเทรนเนอร์
        </MuiLink>
        <Typography color="text.primary">รายละเอียดแพ็กเกจเทรนเนอร์</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {isFetched ? (
          <ProductPTDetail data={data} />
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={228}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
    </Box>
  );
}
