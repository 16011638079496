import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  List,
  IconButton,
  Menu,
  Divider,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAtom } from "jotai";

import {
  signOut,
  useAuthentication,
  useCurrentUser,
} from "@/features/authentication/contexts/AuthenticationContext";
import { Select } from "@/components/Select";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { UserDetailDialog } from "@/components/UserDetailDialog";
import { selectedBranchAtom } from "@/atom/global";
import LogoHorizontal from "@/assets/logo-sale-horizontal.svg";

import type { ListItemButtonProps, SelectChangeEvent } from "@mui/material";

export function AppBar() {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedBranch, setSelectedBranch] = useAtom(selectedBranchAtom);

  const currentBranch = selectedBranch ?? user.branches[0].id;

  const menu = [
    { label: "เช็คอิน", path: "check-in" },
    { label: "สิ่งที่ต้องทำ", path: "daily-tasks" },
    { label: "การจัดการลูกค้า", path: "customers" },
    { label: "สมาชิก", path: "members" },
    { label: "แพ็กเกจและสินค้า", path: "products-and-packages" },
    { label: "ใบเสร็จ", path: "receipts" },
    { label: "แดชบอร์ด", path: "dashboard" },
  ];

  const onChangeBranch = (event: SelectChangeEvent) => {
    const branchId = +event.target.value;
    setSelectedBranch(branchId);
    navigate("/check-in");
  };

  const onClickLogo = () => navigate("/check-in");

  useEffect(() => {
    setSelectedBranch(currentBranch);
  }, [currentBranch, setSelectedBranch]);

  return (
    <Stack minWidth={1024}>
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          p: 3,
          borderRadius: 0,
          gap: 2,
          position: "fixed",
          top: 0,
          zIndex: 100,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            component="img"
            src={LogoHorizontal}
            alt="logo-horizontal"
            width={120}
            height={64}
            sx={{ cursor: "pointer" }}
            onClick={onClickLogo}
          />
          <Stack direction="row" gap={3}>
            <Select
              label="สาขา"
              value={currentBranch.toString()}
              onChange={onChangeBranch}
              sx={{ width: 280 }}
            >
              {user.branches.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <UserAvatarButton />
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" gap={3}>
          {menu.map(({ label, path }) => (
            <Button
              key={label}
              variant="text"
              size="medium"
              onClick={() => navigate(`/${path}`)}
              sx={{
                color: pathname.split("/")[1].includes(path)
                  ? "primary.main"
                  : "text.primary",
              }}
            >
              {label}
            </Button>
          ))}
        </Stack>
      </Paper>
      <Box mt={24} p={3} mx="auto" width="100%" maxWidth={1440}>
        <Outlet />
      </Box>
    </Stack>
  );
}

function UserAvatarButton() {
  const user = useCurrentUser();
  const [, setSelectedBranch] = useAtom(selectedBranchAtom);
  const [, dispatch] = useAuthentication();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openUserDetailDialog, setOpenUserDetailDialog] = useState(false);

  const confirmDialog = {
    maxWidth: "xs" as const,
    open: openConfirmDialog,
    onClose: () => setOpenConfirmDialog(false),
    onConfirm: async () => {
      await signOut(dispatch, queryClient);
      // Clear selected branches id after signout
      setSelectedBranch(null);
      localStorage.removeItem("selectedBranch");
    },
    title: "คุณต้องการออกจากระบบหรือไม่",
    confirmMessage: "ออกจากระบบ",
  };

  const userDetailDialog = {
    open: openUserDetailDialog,
    onClose: () => setOpenUserDetailDialog(false),
  };

  const open = Boolean(anchorEl);

  const onClickAvatar: ListItemButtonProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickSignOut = () => {
    setAnchorEl(null);
    setOpenConfirmDialog(true);
  };

  const onClose = () => setAnchorEl(null);

  const onClickUserDetail = () => {
    setAnchorEl(null);
    setOpenUserDetailDialog(true);
  };

  return (
    <List sx={{ p: 0 }}>
      <ListItemButton sx={{ p: 0 }} onClick={onClickAvatar}>
        <ListItemAvatar>
          <Avatar alt={user.name} src={user.avatarURL} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            noWrap: true,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          primary={user.name}
          secondary={user.position}
          sx={{ width: 152 }}
        />
        <IconButton>
          <ExpandMoreIcon />
        </IconButton>
      </ListItemButton>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        <MenuItem sx={{ width: 256 }} onClick={onClickUserDetail}>
          ข้อมูลส่วนตัว
        </MenuItem>
        <MenuItem sx={{ width: 256 }} onClick={onClickSignOut}>
          ออกจากระบบ
        </MenuItem>
      </Menu>
      <ConfirmDialog {...confirmDialog} />
      <UserDetailDialog {...userDetailDialog} />
    </List>
  );
}
