import { TableCell, TableRow, Typography } from "@mui/material";

import { TextEllipsis } from "@/components/TextEllipsis";
import { DateRangeDisplay } from "@/components/DateRangeDisplay";
import { calculateColumnWidth, getBookingStatus, mapOptional } from "@/utils";
import {
  formatBookingStatus,
  formatBookingStatusColor,
  formatProfile,
} from "@/formatter";

import type { Booking } from "@/models";

const columnsWidth = [328, 328, 330, 56].map((width) =>
  calculateColumnWidth(width)
);

export function PTReservationTableHeaderRow() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>วันที่และเวลา</TableCell>
      <TableCell width={columnsWidth[1]}>ผู้สอน</TableCell>
      <TableCell width={columnsWidth[2]} align="center">
        สถานะ
      </TableCell>
      <TableCell width={columnsWidth[3]} />
    </TableRow>
  );
}

export type PTReservationTableRowProps = {
  data: Booking;
};

export function PTReservationTableRow({ data }: PTReservationTableRowProps) {
  const {
    schedule: { startedAt, endedAt, scheduleTrainers },
  } = data;
  const status = getBookingStatus(data);

  const staff = scheduleTrainers?.[0].staff;

  const trainerName = mapOptional(staff.profile, formatProfile) ?? "-";

  return (
    <TableRow>
      <TableCell>
        <DateRangeDisplay variant="body2" start={startedAt} end={endedAt} />
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" line={3}>
          {trainerName}
        </TextEllipsis>
      </TableCell>
      <TableCell aria-label="Status">
        <Typography
          variant="body2"
          align="center"
          fontWeight={500}
          sx={{ color: formatBookingStatusColor(status) }}
        >
          {formatBookingStatus(status)}
        </Typography>
      </TableCell>
      <TableCell />
    </TableRow>
  );
}
