import { useState } from "react";
import * as yup from "yup";
import { Autocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";

import { useDebounce } from "@/utils";
import { getMembers } from "@/services/member";

import type { TextFieldProps } from "@mui/material";
import type { AutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

export type MemberAutocompleteValues = {
  id: number;
  accountId?: string;
  code: string;
  firstName: string;
  lastName: string;
};

export type MemberAutocompleteProps = {
  label?: string;
  required?: boolean;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  AutocompleteProps<MemberAutocompleteValues, false, false, false>,
  "renderInput" | "options"
>;

const QUERY_KEY = "membersAutocomplete";

export function MemberAutocomplete({
  label,
  required,
  TextFieldProps,
  ...props
}: MemberAutocompleteProps) {
  const [query, setQuery] = useState("");
  const debouceQuery = useDebounce(query);

  const { data: raw, isFetching } = useQuery([QUERY_KEY, debouceQuery], () =>
    getMembers(`query=${debouceQuery}`)
  );

  const options =
    raw?.data.map(
      ({ id, accountId, code, profile: { firstName, lastName } }) => ({
        id,
        accountId,
        code: code ?? "",
        firstName,
        lastName,
      })
    ) ?? [];

  return (
    <Autocomplete
      options={options}
      loading={isFetching}
      getOptionLabel={({ code, firstName, lastName }) =>
        `${code} ${firstName} ${lastName}`
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={(_, newQuery, reason) => {
        if (reason === "clear") {
          setQuery("");
        } else if (reason === "input") {
          setQuery(newQuery);
        }
      }}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      {...props}
    />
  );
}

export function FormMemberAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required,
  ...props
}: Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> &
  Omit<
    MemberAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <MemberAutocomplete
      value={field.value ?? null}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}

const schema = yup
  .object({
    id: yup.number().required(),
    accountId: yup.string().nullable(),
    code: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
  })
  .default(null) // fix yup not validate when value is undefined
  .nullable();

MemberAutocomplete.schema = schema;
