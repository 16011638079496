import { api } from "@/client/api";
import { configs } from "@/configs";
import {
  Customer,
  CustomerCloseStamp,
  CustomerUnqualifiedStamp,
  Following,
} from "@/models";
import { parseDateTimeFromPrisma, parseISOToPrisma } from "@/utils";

type GetCustomers = {
  data: Customer[];
  total: number;
};

type CreateCustomerInput = Omit<
  Customer,
  | "id"
  | "createdAt"
  | "status"
  | "followings"
  | "customerStatusStamps"
  | "customerCloseStamps"
  | "customerUnqualifiedStamps"
  | "friend"
  | "latestRemark"
  | "productMembershipId"
  | "productMembership"
  | "productPersonalTrainingId"
  | "productPersonalTraining"
  | "chance"
  | "productTotal"
  | "productCloseAt"
  | "purchaseProductMembership"
  | "purchaseProductPersonalTraining"
  | "purchaseTotal"
  | "purchaseAt"
  | "member"
> & {
  following: Omit<Following, "createdAt">;
};

type UpdateLeadDataInput = Omit<CreateCustomerInput, "following"> & {
  id: number;
};

type ChangeToLeadInput = CreateCustomerInput & {
  id: number;
};

type UpdateProspectDataInput = Omit<ChangeToProspectInput, "following"> & {
  id: number;
};

type ChangeToProspectInput = Pick<
  Customer,
  | "id"
  | "productMembershipId"
  | "productPersonalTrainingId"
  | "chance"
  | "productTotal"
  | "productCloseAt"
> & {
  following: Omit<Following, "createdAt">;
};

type CloseCustomerInput = Pick<CustomerCloseStamp, "remark"> & {
  customerId: string;
};

type UnqualifiedCustomerInput = Pick<CustomerUnqualifiedStamp, "remark"> & {
  customerId: string;
};

export async function getCustomers(query = "") {
  const { data } = await api.get<GetCustomers>(
    `${configs.apiUrl}/sale/customer?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetCustomers;
}

export async function getCustomer(id: string): Promise<Customer> {
  const response = await api.get(`${configs.apiUrl}/sale/customer/${id}`);
  return parseDateTimeFromPrisma(response.data) as Customer;
}

export async function createCustomer(
  data: CreateCustomerInput
): Promise<Customer> {
  const response = await api.post(
    `${configs.apiUrl}/sale/customer`,
    parseISOToPrisma(data)
  );
  return parseDateTimeFromPrisma(response.data) as Customer;
}

export async function updateLeadData({
  id,
  ...input
}: UpdateLeadDataInput): Promise<Customer> {
  const { data } = await api.patch<Customer>(
    `${configs.apiUrl}/sale/customer/${id}/lead`,
    { ...input }
  );
  return data;
}

export async function changeToLead({
  id,
  ...input
}: ChangeToLeadInput): Promise<Customer> {
  const { data } = await api.patch<Customer>(
    `${configs.apiUrl}/sale/customer/${id}/change-to-lead`,
    { ...input }
  );
  return data;
}

export async function updateProspectData({
  id,
  ...input
}: UpdateProspectDataInput): Promise<Customer> {
  const { data } = await api.patch<Customer>(
    `${configs.apiUrl}/sale/customer/${id}/prospect`,
    parseISOToPrisma({ ...input })
  );
  return data;
}

export async function changeToProspect({
  id,
  ...input
}: ChangeToProspectInput): Promise<Customer> {
  const { data } = await api.patch<Customer>(
    `${configs.apiUrl}/sale/customer/${id}/change-to-prospect`,
    parseISOToPrisma({ ...input })
  );
  return data;
}

export async function closeCustomer({
  customerId,
  ...input
}: CloseCustomerInput): Promise<string> {
  const { data } = await api.patch<string>(
    `${configs.apiUrl}/sale/customer/${customerId}/close`,
    { ...input }
  );
  return data;
}

export async function unqualifiedCustomer({
  customerId,
  ...input
}: UnqualifiedCustomerInput): Promise<string> {
  const { data } = await api.patch<string>(
    `${configs.apiUrl}/sale/customer/${customerId}/unqualified`,
    { ...input }
  );
  return data;
}
