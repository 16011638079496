import { Box, CardContent, CardHeader, Stack, Typography } from "@mui/material";

import { formatDurationISO, formatPrice, formatProductType } from "@/formatter";

import type { ProductMembership } from "@/models";

export type MembershipDetailProps = {
  data: ProductMembership;
};

export function MembershipDetail({ data }: MembershipDetailProps) {
  const { name, description, productMembership, type, branches } = data;

  const price = formatPrice(data.price);
  const duration = formatDurationISO(productMembership.durationIso);

  return (
    <Stack>
      <CardHeader title={name} />
      <CardContent>
        <Box
          display="grid"
          gridTemplateColumns="150px 1fr"
          gap={3}
          alignItems="baseline"
        >
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ระยะเวลา
          </Typography>
          <Typography variant="body2">{duration}</Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ราคา
          </Typography>
          <Typography variant="body2">{price}</Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            สถานที่เข้าใช้บริการ
          </Typography>
          <Stack>
            <Typography variant="body2">{formatProductType(type)}</Typography>
            {branches.map((branch) => (
              <li key={branch.id}>{branch.name}</li>
            ))}
          </Stack>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            รายละเอียด
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </CardContent>
    </Stack>
  );
}
