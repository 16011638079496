import { MutableRefObject, Ref, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

import { configs } from "@/configs";

import { formatDateTime, formatDayString } from "./formatter";
import { fromISO, fromJSDate, now } from "./lib/dateTime";
import {
  Booking,
  BookingStatus,
  DayString,
  Member,
  MemberStatus,
  OfficeDay,
  Role,
  RoleType,
  Schedule,
  ScheduleStatus,
  TrainingStatus,
  WorkingDays,
} from "./models";

import type { QueryClient } from "react-query";
import type { AxiosErrorWithData } from "@/client/api";

export function getDocumentTitle(text?: string) {
  const title = ["FitUP"];

  title.unshift(text ?? "");

  return title.filter(Boolean).join(" - ");
}

export function mapOptional<T, U>(
  value: T | null,
  transform: (value: NonNullable<T>) => U
): U | null;

export function mapOptional<T, U>(
  value: T | undefined,
  transform: (value: NonNullable<T>) => U
): U | undefined;

export function mapOptional<T, U>(
  value: T | null | undefined,
  transform: (value: NonNullable<T>) => U,
  defaultValue?: U
): U | null | undefined {
  if (typeof value === "undefined" || value === null) {
    return defaultValue;
  }

  return transform(value as NonNullable<T>);
}

export function useDebounce<T>(value: T, delay = 200) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

export function delay(seconds: number, error?: boolean) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (error) {
        reject(new Error());
        return;
      }

      resolve(null);
    }, seconds * 1000);
  });
}

export const generateId = () => Math.random().toString(32);

export function useMounted() {
  const ref = useRef(true);

  useEffect(() => {
    ref.current = true;

    return () => void (ref.current = false);
  }, []);

  return ref;
}

export function useObjectURL(file: File | null | undefined) {
  const [url, setURL] = useState<null | string>(null);

  useEffect(() => {
    if (!file) {
      return;
    }

    const objectURL = URL.createObjectURL(file);
    setURL(objectURL);
    return () => {
      URL.revokeObjectURL(objectURL);
      setURL(null);
    };
  }, [file]);

  return url;
}

export function getStorageFileName(URL: string) {
  const decodedURL = decodeURIComponent(URL);
  const splitURL = decodedURL.split("/");
  const name = splitURL[splitURL.length - 1];

  return name.substring(0, name.indexOf("?"));
}

export function useRequireParams<Key extends string | number | symbol = string>(
  keys: Key[]
): Record<Key, string> {
  const params = useParams();

  if (!keys.every((key) => key in params)) {
    throw new Error("Input key not in params");
  }

  return params as unknown as Record<Key, string>;
}

export function calculateColumnWidth(
  figmaColumn: number,
  figmaTable = 1084,
  tableWidth = 1200
) {
  return (figmaColumn / figmaTable) * tableWidth;
}

export function setTime(date: DateTime, time: string) {
  const [hour, minute] = time.split(":");
  return date.set({ hour: +hour, minute: +minute }).startOf("minute");
}

export function mergeRefs<T>(...refs: Array<Ref<T> | undefined>) {
  return (node: T) => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === "object") {
        (ref as MutableRefObject<T>).current = node;
        return;
      }
      ref(node);
    });
  };
}

export function isTimeAfter(time: string, target: string) {
  return (
    parseInt(time.replace(":", ""), 10) > parseInt(target.replace(":", ""), 10)
  );
}

export function getMemberStatus(
  member: Pick<Member, "banUntil" | "membership">
) {
  const currentTime = now();
  const isBanned =
    mapOptional(
      member.banUntil,
      (date) => currentTime <= fromISO(date.toString())
    ) ?? false;
  const isPackageExpired =
    mapOptional(
      member.membership?.endedAt,
      (date) => currentTime > fromISO(date.toString())
    ) ?? true;

  return isBanned
    ? MemberStatus.Ban
    : isPackageExpired
    ? MemberStatus.Expired
    : MemberStatus.Active;
}

export function mapRoles(roles: Role[]) {
  const roleList = [];
  for (const role of roles) {
    if (role.id === 2 || role.id === 4) {
      roleList.push(role);
    }
  }
  return roleList.length
    ? roleList
        .sort((a, b) => a.id - b.id)
        .map((role) => RoleType[role.name])
        .join(", ")
    : "-";
}

export function mapPosition(roles: Role[]) {
  return roles.find((role) => role.id === 1)
    ? RoleType.Admin
    : roles.find((role) => role.id === 3)
    ? RoleType.Manager
    : RoleType.Staff;
}

export function getOfficeDay(workingDays: WorkingDays) {
  const officeDay = [];
  for (const workingDay in workingDays) {
    const day = workingDay as DayString;
    const enable = !!workingDays[day].startTime;
    const map: OfficeDay = {
      enable,
      day: formatDayString(day),
      timeRange: enable
        ? {
            start: fromISO(workingDays[day].startTime.toString()),
            end: fromISO(workingDays[day].endTime.toString()),
          }
        : null,
    };
    officeDay.push(map);
  }
  return officeDay;
}

export function getScheduleStatus(schedule: Schedule) {
  const { cancelledAt, endedAt, joinedAt } = schedule;
  return cancelledAt
    ? ScheduleStatus.Cancelled
    : endedAt < now()
    ? ScheduleStatus.Completed
    : joinedAt && joinedAt > now()
    ? ScheduleStatus.Upcoming
    : ScheduleStatus.Active;
}

// TODO: refactor
export function parseDateTimeFromPrisma(input: unknown): unknown {
  const isoDatePattern =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;
  if (
    typeof input === "string" &&
    isNaN(+input) &&
    isoDatePattern.test(input)
    //  other way when iso pattern is not work
    // fromISO(input).isValid &&
    // !isNaN(new Date(input).getTime())
  ) {
    return fromISO(input);
  }
  if (input instanceof Date) {
    return fromJSDate(input);
  }

  if (Array.isArray(input) && input !== null) {
    return input.map((item: unknown) => parseDateTimeFromPrisma(item));
  }

  if (input instanceof Object) {
    const data: Record<string, unknown> = {};
    Object.keys(input).forEach((key) => {
      const newInput = input as Record<string, unknown>;
      data[key] = parseDateTimeFromPrisma(newInput[key]);
    });
    return data;
  }

  return input;
}

// TODO: refactor
export function parseISOToPrisma(input: unknown): unknown {
  if (input instanceof Date) {
    return fromJSDate(input).toISO();
  }

  if (input instanceof DateTime) {
    return input.toISO();
  }

  if (Array.isArray(input) && input !== null) {
    return input.map((item: unknown) => parseISOToPrisma(item));
  }

  if (input instanceof Object) {
    const data: Record<string, unknown> = {};
    Object.keys(input).forEach((key) => {
      const newInput = input as Record<string, unknown>;
      data[key] = parseISOToPrisma(newInput[key]);
    });
    return data;
  }

  return input;
}

export function pickListTableParams(
  searchParams: URLSearchParams,
  keys: string[] = []
): string {
  const params: string[] = [];
  const selectedKeys = [
    "query",
    "page",
    "limit",
    "status",
    "sort",
    "sortType",
  ].concat(keys);

  searchParams.forEach((value, key) => {
    if (selectedKeys.includes(key)) {
      params.push(`${key}=${value}`);
    }
  });
  return params.join("&");
}

export function parseURLSearchParams(object: Record<string, string>): string {
  return new URLSearchParams(object).toString();
}

export function getBookingStatus({
  cancelledAt,
  isWaiting,
  isPending,
}: Booking) {
  return cancelledAt
    ? BookingStatus.Cancelled
    : isWaiting
    ? BookingStatus.Waiting
    : isPending
    ? BookingStatus.Pending
    : BookingStatus.Booked;
}

export async function refetchQueries({
  queryClient,
  fetchKeys,
}: {
  queryClient: QueryClient;
  fetchKeys: string[];
}) {
  return Promise.all(
    fetchKeys.map(async (fetchKey) => queryClient.refetchQueries(fetchKey))
  );
}

export function getApiErrorMessage(error: AxiosErrorWithData) {
  return error.response?.data.message ?? configs.unknownErrorMessage;
}

export function getTrainingLateColor(date: DateTime, status: TrainingStatus) {
  const isLate =
    now().startOf("day") > date && status === TrainingStatus.InProgress;
  return isLate ? "error.main" : "text.primary";
}

export function getUpdatedByDetail(name?: string | null, date?: DateTime) {
  const updateDate = mapOptional(date, formatDateTime) ?? "-";
  const updateBy = name ?? "-";
  return `แก้ไขล่าสุด : ${updateDate} โดย ${updateBy}`;
}

export function getFollowedAtColor({
  status,
  followedAt,
  isLastFollowUp,
}: {
  status: TrainingStatus;
  followedAt: DateTime;
  isLastFollowUp: boolean;
}) {
  const isInProgress = status === TrainingStatus.InProgress;

  const isLate = now().startOf("day") > followedAt;

  const followedAtColor =
    isInProgress && isLate && isLastFollowUp ? "error.main" : "text.primary";

  return followedAtColor;
}
