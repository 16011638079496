import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useMediaQuery } from "@mui/material";

import { FormTextField } from "@/components/FormTextField";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { closeCustomer } from "@/services/customer";
import { getApiErrorMessage, refetchQueries } from "@/utils";
import { theme } from "@/theme";

import type { InferType } from "yup";
import type { AxiosErrorWithData } from "@/client/api";

type CloseCustomerEditorState = InferType<typeof schema>;

type CloseCustomerDialogProps = {
  customerId: string;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function CloseCustomerDialog({
  customerId,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: CloseCustomerDialogProps) {
  const { control, handleSubmit, reset } = useCloseCustomerEditorForm();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: closeCurrentCustomer, isLoading } = useMutation(
    closeCustomer,
    {
      onSuccess: async () => {
        enqueueSnackbar("เปลี่ยนสถานะเป็นปฏิเสธการขายสำเร็จ", {
          variant: "success",
        });
        await refetchQueries({ queryClient, fetchKeys });
        close();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    }
  );

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit((form) => {
    closeCurrentCustomer({ remark: form.remark, customerId });
  });

  const title = "เหตุผลที่ปฏิเสธการขาย";

  const isTablet = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <FormConfirmDialog
      maxWidth={isTablet ? "md" : "lg"}
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <FormTextField
        label="เหตุผล"
        name="remark"
        control={control}
        multiline
        rows={4}
        fullWidth
        required
      />
    </FormConfirmDialog>
  );
}

function useCloseCustomerEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  remark: yup.string().label("เหตุผล").required(),
});

const resolver = yupResolver(schema);

const defaultValues = { remark: "" } as CloseCustomerEditorState;
