import { useForm, useController, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

import { FormTextField } from "@/components/FormTextField";
import { FormDatePicker } from "@/components/DatePicker";
import { FormRadioGroup } from "@/components/FormRadioGroup";
import {
  BloodGroup,
  Channel,
  Gender,
  MaritalStatus,
  PersonalTax,
} from "@/models";
import {
  formatBloodGroup,
  formatChannel,
  formatGender,
  formatMaritalStatus,
  formatPersonalTaxType,
} from "@/formatter";
import { Section } from "@/components/Section";
import { FormSelect } from "@/components/Select";
import { FormNumericTextField } from "@/components/NumericTextField";
import { AddressEditor } from "@/components/AddressEditor";
import { FormCheckbox } from "@/components/FormCheckbox";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { FormMemberAutocomplete } from "@/components/MemberAutocomplete";

import { MemberIdFormTextField } from "./MemberIdFormTextField";
import { FormSaleAutocomplete } from "./SaleAutocomplete";

import type { InferType, StringSchema } from "yup";
import type { ControllerProps } from "react-hook-form";

export type MemberProfileEditorState = InferType<typeof schema>;

export type MemberProfileEditorProps = Pick<
  ControllerProps<MemberProfileEditorState>,
  "control"
>;

export function MemberProfileEditor({ control }: MemberProfileEditorProps) {
  const channel = useWatch({ name: "channel", control });
  const isEditing = useWatch({ name: "isEditing", control });
  const {
    field: { onChange },
  } = useController({ name: "channelFriend", control });

  const handleChannelChange = () => onChange(null);
  return (
    <Stack gap={5}>
      <BranchAndSaleForm control={control} />
      <Section columns={2} label="ข้อมูลส่วนบุคคล">
        <MemberIdFormTextField control={control} />
        <FormTextField
          name="nationalIdCard"
          control={control}
          label="เลขที่บัตรประชาชน/หนังสือเดินทาง"
          required
        />
        <FormTextField
          name="firstName"
          control={control}
          label="ชื่อ"
          required
        />
        <FormTextField
          name="lastName"
          control={control}
          label="นามสกุล"
          required
        />
        <FormTextField
          name="nickname"
          control={control}
          label="ชื่อเล่น"
          required
        />
        <FormDatePicker
          label="วัน/เดือน/ปีเกิด"
          name="dateOfBirth"
          control={control}
          TextFieldProps={{ required: true, sx: { width: 220 } }}
        />
        <Box sx={{ gridColumn: "1/span 2" }}>
          <Typography variant="labelLarge">เพศ*</Typography>
          <FormRadioGroup row name="gender" control={control} sx={{ mt: 0.75 }}>
            {[Gender.Unspecified, Gender.Male, Gender.Female].map((gender) => (
              <FormControlLabel
                key={gender}
                value={gender}
                control={<Radio />}
                label={formatGender(gender)}
              />
            ))}
          </FormRadioGroup>
        </Box>
        <FormSelect
          name="bloodGroup"
          control={control}
          label="กรุ๊ปเลือด"
          required
        >
          {[
            BloodGroup.A,
            BloodGroup.B,
            BloodGroup.AB,
            BloodGroup.O,
            BloodGroup.Unspecified,
          ].map((bloodGroup) => (
            <MenuItem key={bloodGroup} value={bloodGroup}>
              {formatBloodGroup(bloodGroup)}
            </MenuItem>
          ))}
        </FormSelect>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2.5}>
          <FormNumericTextField
            name="height"
            control={control}
            label="ส่วนสูง (ซม.)"
          />
          <FormNumericTextField
            name="weight"
            control={control}
            label="น้ำหนัก (กก.)"
          />
        </Box>
        <FormSelect name="maritalStatus" control={control} label="สถานภาพ">
          {[
            MaritalStatus.Single,
            MaritalStatus.Marry,
            MaritalStatus.Widow,
            MaritalStatus.Divorce,
            MaritalStatus.Unspecified,
          ].map((maritalStatus) => (
            <MenuItem key={maritalStatus} value={maritalStatus}>
              {formatMaritalStatus(maritalStatus)}
            </MenuItem>
          ))}
        </FormSelect>
        <FormTextField name="career" control={control} label="อาชีพ" />
      </Section>
      <Divider />
      <Section columns={2} label="ช่องทางการติดต่อ">
        <FormTextField
          name="phone"
          control={control}
          label="เบอร์โทรศัพท์"
          required
        />
        <FormTextField
          name="email"
          control={control}
          label="อีเมล"
          type="email"
          disabled={isEditing}
          required
        />
        <FormTextField name="line" control={control} label="Line" />
        <FormTextField name="facebook" control={control} label="Facebook" />
        <FormTextField name="contactOther" control={control} label="อื่นๆ" />
      </Section>
      <Divider />
      <Section columns={2} label="ช่องทางที่รู้จัก">
        <FormSelect
          name="channel"
          control={control}
          label="ช่องทาง"
          onChange={handleChannelChange}
          required
        >
          {[
            Channel.Facebook,
            Channel.Line,
            Channel.Google,
            Channel.Friend,
            Channel.Event,
            Channel.Brochure,
            Channel.Other,
          ].map((channel) => (
            <MenuItem key={channel} value={channel}>
              {formatChannel(channel)}
            </MenuItem>
          ))}
        </FormSelect>
        {channel === Channel.Other && (
          <FormTextField
            name="channelOther"
            control={control}
            label="รายละเอียดเพิ่มเติม"
            required
          />
        )}
        {channel === Channel.Friend && (
          <FormMemberAutocomplete
            label="หมายเลขสมาชิกหรือชื่อ"
            name="channelFriend"
            control={control}
            required
          />
        )}
      </Section>
      <Divider />
      <Section columns={2} label="ข้อมูลติดต่อฉุกเฉิน">
        <FormTextField
          name="emergencyContactName"
          control={control}
          label="บุคคลที่สามารถติดต่อกรณีฉุกเฉิน"
        />
        <FormTextField
          name="emergencyContactPhone"
          control={control}
          label="เบอร์โทรศัพท์"
        />
        <FormTextField
          name="relationship"
          control={control}
          label="ความสัมพันธ์"
        />
      </Section>
      <Divider />
      <Section columns={1} label="ที่อยู่ปัจจุบัน">
        <AddressEditor control={control} name="currentAddress" />
      </Section>
      <Divider />
      <Stack gap={2.5}>
        <InvoiceAddressForm control={control} />
      </Stack>
    </Stack>
  );
}

function BranchAndSaleForm({
  control,
}: Pick<MemberProfileEditorProps, "control">) {
  const user = useCurrentUser();
  const branchId = useWatch({ name: "branchId", control });
  const isEditing = useWatch({ name: "isEditing", control });
  const {
    field: { onChange },
  } = useController({ name: "sale", control });

  const handleBranchChange = () => onChange(null);

  return isEditing ? (
    <>
      <Section columns={2}>
        <FormSelect
          label="สาขา"
          name="branchId"
          control={control}
          onChange={handleBranchChange}
        >
          {user.branches.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSaleAutocomplete
          label="ชื่อพนักงานขาย"
          name="sale"
          branchId={branchId}
          control={control}
        />
      </Section>
      <Divider />
    </>
  ) : null;
}

const configMap = {
  [PersonalTax.JuristicPerson]: {
    label: "ชื่อบริษัท หน่วยงาน หรือองค์กร",
    gridColumn: "1/-1",
  },
  [PersonalTax.NormalPerson]: {
    label: "ชื่อ - นามสกุล",
    gridColumn: "1/1",
  },
};

function InvoiceAddressForm({ control }: MemberProfileEditorProps) {
  const enable = useWatch({ name: "invoice.address.required", control });

  //  TODO: remove useController
  const {
    field: { onChange: onChangeNo },
  } = useController({ name: "invoice.no", control });
  const {
    field: { onChange: onChangeName },
  } = useController({ name: "invoice.name", control });
  const {
    field: { onChange: onChangeBranch },
  } = useController({ name: "invoice.branch", control });
  const {
    field: { value: type, onChange: onChangeType },
  } = useController({ name: "invoice.type", control });
  const {
    field: { onChange: onChangePostcode },
  } = useController({ name: "invoice.address.postcode", control });
  const {
    field: { onChange: onChangeLine },
  } = useController({ name: "invoice.address.line", control });

  const isJuristicPerson = type === PersonalTax.JuristicPerson;

  const { label, gridColumn } = configMap[type];

  useEffect(() => {
    if (!enable) {
      onChangeType(PersonalTax.NormalPerson);
      onChangeNo("");
      onChangeName("");
      onChangeBranch("");
      onChangePostcode("");
      onChangeLine("");
    }
  }, [
    enable,
    onChangeNo,
    onChangeName,
    onChangeBranch,
    onChangeType,
    onChangePostcode,
    onChangeLine,
  ]);

  return (
    <>
      <Stack direction="row" gap={1.25} alignItems="center">
        <Typography variant="labelLarge">ใบกำกับภาษีเต็มรูปแบบ</Typography>
        <FormControl sx={{ width: 280 }}>
          <FormControlLabel
            control={
              <FormCheckbox name="invoice.address.required" control={control} />
            }
            label="ต้องการใบกำกับภาษีเต็มรูปแบบ"
            labelPlacement="end"
          />
        </FormControl>
      </Stack>
      <FormRadioGroup row name="invoice.type" control={control}>
        {[PersonalTax.NormalPerson, PersonalTax.JuristicPerson].map((type) => (
          <FormControlLabel
            key={type}
            value={type}
            control={<Radio />}
            label={formatPersonalTaxType(type)}
            disabled={!enable}
          />
        ))}
      </FormRadioGroup>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2.5}>
        <FormTextField
          name="invoice.no"
          control={control}
          label="เลขที่ใบกำกับภาษี"
          required
          disabled={!enable}
          sx={{ gridColumn }}
        />
        <FormTextField
          name="invoice.name"
          control={control}
          label={label}
          required
          disabled={!enable}
        />
        {isJuristicPerson && (
          <FormTextField
            name="invoice.branch"
            control={control}
            label="สาขา"
            required
            disabled={!enable}
          />
        )}
        <AddressEditor
          name="invoice.address"
          control={control}
          disabled={!enable}
          sx={{ gridColumn: "1/-1" }}
        />
      </Box>
    </>
  );
}

export function useMemberEditorForm() {
  return useForm({ resolver, defaultValues });
}

const invoiceRequired = yup.string().when("address.required", {
  is: true,
  then: (schema: StringSchema) => schema.required(),
}) as StringSchema;

const saleSchema = yup.object({
  id: yup.number().integer().required(),
  name: yup.string().required(),
});

const schema = yup.object({
  memberId: yup.string(),
  nationalIdCard: yup
    .string()
    .label("เลขที่บัตรประชาชน/หนังสือเดินทาง")
    .required(),
  firstName: yup.string().label("ชื่อ").required(),
  lastName: yup.string().label("นามสกุล").required(),
  nickname: yup.string().label("ชื่อเล่น").required(),
  dateOfBirth: yup.date().label("วัน/เดือน/ปีเกิด").required(),
  gender: yup.string().oneOf(Object.values(Gender)).required(),
  bloodGroup: yup
    .string()
    .oneOf(Object.values(BloodGroup))
    .label("กรุ๊ปเลือด")
    .required(),
  height: yup.number().min(0).max(250),
  weight: yup.number().min(0).max(250),
  maritalStatus: yup.string().oneOf(Object.values(MaritalStatus)),
  career: yup.string(),
  phone: yup.string().label("เบอร์โทรศัพท์").required(),
  email: yup.string().label("อีเมล").email().required(),
  line: yup.string().label("Line"),
  facebook: yup.string().label("Facebook"),
  contactOther: yup.string().label("อื่นๆ"),
  channel: yup
    .string()
    .oneOf(Object.values(Channel))
    .label("ช่องทาง")
    .required(),
  channelOther: yup
    .string()
    .label("รายละเอียดเพิ่มเติม")
    .when("channel", {
      is: Channel.Other,
      then: (schema: yup.StringSchema) => schema.required(),
      otherwise: (schema: yup.StringSchema) => schema,
    }),
  channelFriend: yup
    .object({
      id: yup.number(),
      accountId: yup.string(),
      code: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
    })
    .nullable()
    .when("channel", {
      is: Channel.Friend,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
  emergencyContactName: yup.string(),
  emergencyContactPhone: yup.string(),
  relationship: yup.string(),
  currentAddress: AddressEditor.schema,
  invoice: yup.object({
    type: yup.string().oneOf(Object.values(PersonalTax)).required(),
    address: AddressEditor.schema,
    no: invoiceRequired.label("เลขที่ใบกำกับภาษี"),
    name: invoiceRequired.label(
      "ชื่อ - นามสกุล, ชื่อบริษัท หน่วยงาน หรือองค์กร"
    ),
    branch: invoiceRequired.label("สาขา").concat(
      yup.string().when("type", {
        is: PersonalTax.JuristicPerson,
        then: (schema: StringSchema) => schema.required(),
      }) as StringSchema
    ),
  }),
  isEditing: yup.boolean().required(),
  branchId: yup.number().when("isEditing", {
    is: true,
    then: (schema: yup.NumberSchema) => schema.required(),
    otherwise: (schema: yup.NumberSchema) => schema,
  }),
  sale: saleSchema
    .label("ชื่อ Sale")
    .default(null)
    .nullable()
    .when("isEditing", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
});

const resolver = yupResolver(schema);

const defaultValues = {
  gender: Gender.Unspecified,
  invoice: {
    type: PersonalTax.NormalPerson,
    address: {
      required: false,
    },
  },
  isEditing: false,
} as MemberProfileEditorState;

export function toInput(state: MemberProfileEditorState) {
  const isJuristicPerson = state.invoice.type === PersonalTax.JuristicPerson;

  return {
    firstNameTH: state.firstName,
    firstNameEN: state.firstName,
    lastNameTH: state.lastName,
    lastNameEN: state.lastName,
    nicknameTH: state.nickname,
    nicknameEN: state.nickname,
    gender: state.gender,
    career: state.career ?? "",
    birthDate: state.dateOfBirth,
    height: state.height,
    weight: state.weight,
    identificationNo: state.nationalIdCard,
    status: state.maritalStatus,
    bloodGroup: state.bloodGroup,
    phoneNumber: state.phone,
    email: state.email,
    emergencyContactName: state.emergencyContactName,
    relationship: state.relationship,
    emergencyContactPhone: state.emergencyContactPhone,
    address: {
      address: state.currentAddress.line,
      district: state.currentAddress.district,
      subDistrict: state.currentAddress.subdistrict,
      province: state.currentAddress.province,
      postalCode: state.currentAddress.postcode,
    },
    billingAddress: {
      address: state.invoice.address.line,
      district: state.invoice.address.district,
      subDistrict: state.invoice.address.subdistrict,
      province: state.invoice.address.province,
      postalCode: state.invoice.address.postcode,
      type: state.invoice.address.required ? state.invoice.type : undefined,
      taxIdentificationNo: state.invoice.no,
      branch: state.invoice.branch,
      fullName: !isJuristicPerson ? state.invoice.name : "",
      companyName: isJuristicPerson ? state.invoice.name : "",
    },
  };
}
