import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { formatDate, formatGender } from "@/formatter";

import type { BoxProps, StackProps } from "@mui/material";
import type { DateTime } from "luxon";

type UserDetailDialogProps = {
  open: boolean;
  onClose?: () => void;
};

export function UserDetailDialog({ onClose, ...props }: UserDetailDialogProps) {
  const { name, avatarURL, profile, code, branches } = useCurrentUser();
  const { nickname, email, idCard, birthDate, gender, phoneNo } = profile;

  const columns = [
    {
      title: "ข้อมูลส่วนตัว",
      records: [
        { label: "เลขที่บัตรประชาชน/หนังสือเดินทาง", data: idCard },
        {
          label: "วัน/เดือน/ปีเกิด",
          data: formatDate(birthDate as unknown as DateTime), // TODO refactor change date type
        },
        { label: "เพศ", data: formatGender(gender) },
        { label: "เบอร์โทรศัพท์", data: phoneNo },
      ],
    },
    {
      title: "สิทธิ์การใช้งาน",
      records: [{ label: "สาขา", data: branches.map(({ name }) => name) }],
    },
  ];

  return (
    <Dialog onClose={onClose} maxWidth="md" {...props}>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        ข้อมูลส่วนตัว
        <Tooltip title="ปิด">
          <IconButton size="medium" onClick={onClose} sx={{ ml: "auto" }}>
            <Close fontSize="large" />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ pb: 5 }}>
        <Stack direction="row" gap={8}>
          <Box
            component="img"
            src={avatarURL}
            alt="user-avatar"
            width={300}
            height={300}
            borderRadius="12px"
          />
          <Stack gap={3} width="100%">
            <Stack gap={1}>
              <Typography typography="h6" color="primary.main">
                {code}
              </Typography>
              <Typography typography="h4">{name}</Typography>
              <Typography typography="h6">{nickname}</Typography>
              <Typography typography="h6" color="text.secondary">
                {email}
              </Typography>
            </Stack>
            <Divider />
            <DataSection columns={columns} />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

type Column = {
  title: string;
  records: { label: string; data: string | string[] }[];
};

type DataSectionProps = {
  columns: Column[];
  SectionProps?: StackProps;
  DataStackContainerProps?: StackProps;
  DataStackProps?: StackProps;
} & BoxProps;

function DataSection({
  columns,
  SectionProps,
  DataStackContainerProps,
  DataStackProps,
  ...props
}: DataSectionProps) {
  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${columns.length}, 1fr)`}
      columnGap={4}
      {...props}
    >
      {columns.map(({ title, records }) => (
        <Stack key={title} gap={1} {...SectionProps}>
          <Typography variant="subtitle1">{title}</Typography>
          <Stack gap={2} {...DataStackContainerProps}>
            {records.map(({ label, data }) => (
              <Stack key={label} gap={0.5} {...DataStackProps}>
                <Typography variant="body2" color="text.disabled">
                  {label}
                </Typography>
                {data instanceof Array ? (
                  data.map((item) => (
                    <Typography
                      key={item}
                      variant="body2"
                      display="list-item"
                      ml={2}
                    >
                      {item}
                    </Typography>
                  ))
                ) : (
                  <Typography variant="body2">{data}</Typography>
                )}
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}
    </Box>
  );
}
