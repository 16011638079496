import { TableRow, TableCell } from "@mui/material";

import { calculateColumnWidth } from "@/utils";
import { ProductPT } from "@/models";
import { TextEllipsis } from "@/components/TextEllipsis";
import {
  formatDurationISO,
  formatPrice,
  formatProductType,
  formatQuotaUnit,
} from "@/formatter";
import { MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";

const columnsWidth = [300, 504, 120, 120, 150, 150].map((item) =>
  calculateColumnWidth(item)
);

export function ProductPTTableHead() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>รายละเอียด</TableCell>
      <TableCell width={columnsWidth[2]}>จำนวน</TableCell>
      <TableCell width={columnsWidth[3]}>ระยะเวลา</TableCell>
      <TableCell width={columnsWidth[4]}>ราคา</TableCell>
      <TableCell width={columnsWidth[5]}>สาขาที่เข้าใช้บริการ</TableCell>
    </TableRow>
  );
}

export type ProductPTTableRowProps = {
  data: ProductPT;
};

export function ProductPTTableRow({ data }: ProductPTTableRowProps) {
  const navigate = useNavigate();

  const { id, name, description, productPersonalTraining, type } = data;
  const { quota, quotaUnit, durationIso } = productPersonalTraining;

  const price = formatPrice(data.price);
  const duration = formatDurationISO(durationIso);

  const idString = id.toString();

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(idString);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{description}</TextEllipsis>
      </TableCell>
      <TableCell>
        {quota} {formatQuotaUnit(quotaUnit)}
      </TableCell>
      <TableCell>{duration}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>{formatProductType(type)}</TableCell>
    </TableRow>
  );
}
