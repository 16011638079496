import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import {
  PurchaseEditor,
  usePurchaseEditorForm,
} from "@/features/member/components/PurchaseEditor";
import { getApiErrorMessage, mapOptional, refetchQueries } from "@/utils";
import { closeWonOpportunity } from "@/services/opportunity";
import { transformDurationISOtoObject } from "@/lib/duration";
import { now } from "@/lib/dateTime";
import { theme } from "@/theme";
import { DiscountType, PurchaseType } from "@/models";

import type { AxiosErrorWithData } from "@/client/api";
import type { Opportunity } from "@/models";

type ChangeToCloseWonDialogProps = {
  data: Opportunity;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function ChangeToCloseWonDialog({
  data,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: ChangeToCloseWonDialogProps) {
  const { control, handleSubmit, reset } = usePurchaseEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: closeWon, isLoading } = useMutation(closeWonOpportunity, {
    onSuccess: async () => {
      enqueueSnackbar("เปลี่ยนสถานะเป็นขายสำเร็จ", {
        variant: "success",
      });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  useEffect(() => {
    const products = data.opportunityInterests.map(({ product }) => {
      const type = product.productMembership
        ? PurchaseType.Membership
        : PurchaseType.ProductPT;
      const isMembership = type === PurchaseType.Membership;

      const packageData = {
        name: product.name,
        price: product.price,
        branches: product.branches,
        productId: product.id,
      };

      return {
        type,
        membership: isMembership
          ? {
              id: product.productMembership?.id,
              duration:
                mapOptional(
                  product.productMembership?.durationIso,
                  transformDurationISOtoObject
                ) ?? null,
              ...packageData,
            }
          : null,
        packagePT: !isMembership
          ? {
              id: product.productPersonalTraining?.id,
              duration:
                mapOptional(
                  product.productPersonalTraining?.durationIso,
                  transformDurationISOtoObject
                ) ?? null,
              quota: product.productPersonalTraining?.quota,
              quotaUnit: product.productPersonalTraining?.quotaUnit,
              ...packageData,
            }
          : null,
        discountType: DiscountType.Percentage,
        dateRange: { start: null, end: null },
        note: "",
        discountPercentage: 0,
        discountBaht: 0,
      };
    });

    reset({ products });
  }, [reset, data]);

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit((form) => {
    closeWon({
      memberId: data.member.id,
      opportunityId: data.id,
      purchaseItems: form.products.map(
        ({
          type,
          membership,
          packagePT,
          discountPercentage,
          discountBaht,
          dateRange,
          note,
        }) => {
          const packageData =
            type === PurchaseType.Membership ? membership : packagePT;

          return {
            productId: packageData?.productId ?? -1,
            discountPercentage: discountPercentage ?? 0,
            discount: discountBaht ?? 0,
            start: dateRange.start ?? now(),
            remark: note,
          };
        }
      ),
    });
  });

  const title = "เปลี่ยนสถานะเป็นขายสำเร็จ";

  const isTablet = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <FormConfirmDialog
      maxWidth={isTablet ? "md" : "lg"}
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <PurchaseEditor control={control} />
    </FormConfirmDialog>
  );
}
