import { Autocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from "yup";

import { getSales } from "@/services/sale";
import { formatProfile } from "@/formatter";

import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import type { TextFieldProps } from "@mui/material";
import type { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";
import { parseURLSearchParams } from "@/utils";

export type SaleAutocompleteValue = {
  id: number;
  name: string;
};

export type SaleAutocompleteProps = {
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
  branchId?: number;
} & Omit<
  MuiAutocompleteProps<SaleAutocompleteValue, false, false, false>,
  "renderInput" | "options"
>;

function useSales(branchId: number | undefined) {
  const query = parseURLSearchParams({
    limit: "1000",
    ...(branchId ? { branchId: branchId.toString() } : {}),
  });

  return useQuery(["sales", branchId], () => getSales(query));
}

export function SaleAutocomplete({
  label,
  TextFieldProps,
  branchId,
  ...props
}: SaleAutocompleteProps) {
  const { data: raw, isFetching } = useSales(branchId);

  const sales = raw?.data ?? [];

  const options = sales.map(({ id, profile }) => ({
    id: id,
    name: formatProfile(profile),
  }));

  return (
    <Autocomplete
      options={options}
      loading={isFetching}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      {...props}
    />
  );
}

export function FormSaleAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required = false,
  ...props
}: { required?: boolean } & Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control"
> &
  Omit<
    SaleAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <SaleAutocomplete
      value={field.value ?? null}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}

const schema = yup.object({
  sale: yup
    .object({
      id: yup.number().integer().required(),
      name: yup.string().required(),
    })
    .label("ชื่อพนักงานขาย")
    .default(null)
    .nullable()
    .required(),
});

SaleAutocomplete.schema = schema;
