import {
  Box,
  Card,
  CardContent,
  Stack,
  TableContainer,
  Table,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect } from "react";

import { pickListTableParams } from "@/utils";
import { DataTableBody } from "@/components/DataTableBody";
import { QueryTextField } from "@/components/QueryTextField";
import { ItemsTotal } from "@/components/ItemsTotal";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import {
  OpportunityTableRow,
  OpportunityTableRowHeader,
} from "@/features/customer/components/OpportunityTableRow";
import { getOpportunityTasks } from "@/services/task";

const QUERY_KEY = "opportunity-tasks";

export function OpportunityTaskListPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = pickListTableParams(searchParams);
  const { data: raw, isFetching } = useQuery(
    [QUERY_KEY, query],
    () => getOpportunityTasks(query),
    { enabled: !!query }
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  useEffect(() => {
    if (!query) {
      searchParams.set("sort", "lastFollowingAt");
      searchParams.set("sortType", "asc");
      setSearchParams(searchParams, { replace: true });
    }
  }, [query, searchParams, setSearchParams]);

  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            mb={5}
          >
            <QueryTextField
              sx={{ width: 560 }}
              placeholder="ค้นหาชื่อ หรือเบอร์โทรศัพท์"
            />
          </Stack>
          <ItemsTotal count={total} />
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <OpportunityTableRowHeader dailyTask />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <OpportunityTableRow key={item.id} data={item} dailyTask />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
    </Box>
  );
}
