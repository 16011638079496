import * as yup from "yup";
import { Fragment, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import {
  useController,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";

import { FormSelect } from "@/components/Select";
import { FormDatePicker } from "@/components/DatePicker";
import { FormRadioGroup } from "@/components/FormRadioGroup";
import { FormNumericTextField } from "@/components/NumericTextField";
import { FormTextField } from "@/components/FormTextField";
import { FormProductPTAutocomplete } from "@/components/ProductPTAutocomplete";
import { FormMembershipAutocomplete } from "@/components/MembershipAutocomplete";
import { SummaryPriceDetail } from "@/features/member/components/SummaryPriceDetail";
import {
  formatDurationUnit,
  formatPrice,
  formatPurchaseType,
  formatQuotaUnit,
} from "@/formatter";
import { calculateDiscount } from "@/features/member/utils";
import { mapOptional } from "@/utils";
import { datetime } from "@/lib/yup";
import { decimal } from "@/lib/yup/validation";
import { configs } from "@/configs";
import { DiscountType, PurchaseType } from "@/models";

import type { InferType } from "yup";
import type { QuotaUnit } from "@/models";
import type { DurationObjectUnits } from "luxon";
import type { SelectChangeEvent } from "@mui/material";
import type { ControllerProps } from "react-hook-form";

export type PurchaseEditorState = InferType<typeof schema>;

type PurchaseEditorProps = Pick<
  ControllerProps<PurchaseEditorState>,
  "control"
>;

export function PurchaseEditor({ control }: PurchaseEditorProps) {
  const { fields, append, remove } = useFieldArray({
    name: "products",
    control,
  });

  const disableAddButton = fields.length === configs.maxInterest;

  function onAddItem() {
    append({
      type: null,
      membership: null,
      packagePT: null,
      dateRange: { start: null, end: null },
      discountType: DiscountType.Percentage,
      note: "",
      discountPercentage: 0,
      discountBaht: 0,
    });
  }

  const onClickDelete = (index: number) => remove(index);

  return (
    <Stack gap={4}>
      <Stack direction="row" gap={2} alignItems="center">
        <Typography variant="labelLarge" color="text.secondary">
          ความสนใจ
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{ py: 0.5, px: 4.5 }}
          disabled={disableAddButton}
          onClick={onAddItem}
        >
          เพิ่ม
        </Button>
      </Stack>
      {fields.map((product, index) => (
        <Fragment
          key={`${
            product?.membership?.productId ??
            product.packagePT?.productId ??
            "no-product"
          }-${index}`}
        >
          <PurchaseItemEditor
            control={control}
            index={index}
            onClickDelete={() => onClickDelete(index)}
          />
          <Divider />
        </Fragment>
      ))}
      <PurchaseSummary control={control} />
    </Stack>
  );
}

type PurchaseSummaryProps = Pick<
  ControllerProps<PurchaseEditorState>,
  "control"
>;

function PurchaseSummary({ control }: PurchaseSummaryProps) {
  const products = useWatch({ name: "products", control });

  const [subTotal, discount] = products.reduce(
    ([totalPrice, totalDiscount], { membership, packagePT, discountBaht }) => [
      totalPrice + (membership?.price ?? packagePT?.price ?? 0),
      totalDiscount + (discountBaht ?? 0),
    ],
    [0, 0]
  );

  const summaryPriceDetail = {
    subTotal,
    discount,
    grandTotal: subTotal - discount,
  };

  return <SummaryPriceDetail {...summaryPriceDetail} />;
}

type PurchaseItemEditorProps = Pick<
  ControllerProps<PurchaseEditorState>,
  "control"
> & { index: number; onClickDelete: () => void };

function PurchaseItemEditor({
  control,
  index,
  onClickDelete,
}: PurchaseItemEditorProps) {
  const type = useWatch({ name: `products.${index}.type`, control });
  const membership = useWatch({
    name: `products.${index}.membership`,
    control,
  });
  const packagePT = useWatch({ name: `products.${index}.packagePT`, control });
  const discountType = useWatch({
    name: `products.${index}.discountType`,
    control,
  });
  const discountPercentage = useWatch({
    name: `products.${index}.discountPercentage`,
    control,
  });
  const start = useWatch({
    name: `products.${index}.dateRange.start`,
    control,
  });

  const isSelectPackage = membership || packagePT;
  const isMembership = type === PurchaseType.Membership;

  const {
    field: { onChange: onMembershipChange },
  } = useController({ name: `products.${index}.membership`, control });
  const {
    field: { onChange: onPackagePTChange },
  } = useController({ name: `products.${index}.packagePT`, control });
  const {
    field: { onChange: onEndChange },
  } = useController({ name: `products.${index}.dateRange.end`, control });
  const {
    field: { onChange: onDiscountPercentChange },
  } = useController({ name: `products.${index}.discountPercentage`, control });
  const {
    field: { onChange: onDiscountBahtChange },
  } = useController({ name: `products.${index}.discountBaht`, control });
  const {
    field: { onChange: onDiscountTypeChange },
  } = useController({ name: `products.${index}.discountType`, control });

  useEffect(() => {
    const duration = isMembership ? membership?.duration : packagePT?.duration;
    if (duration && start) {
      const end = start.plus({ ...duration }).plus({ days: -1 });
      onEndChange(end);
    }
  }, [
    start,
    onEndChange,
    isSelectPackage,
    isMembership,
    membership,
    packagePT,
  ]);

  useEffect(() => {
    onDiscountBahtChange(
      calculateDiscount(
        (isMembership ? membership?.price : packagePT?.price) ?? 0,
        discountPercentage ?? 0
      )
    );
  }, [
    membership,
    packagePT,
    discountPercentage,
    onDiscountBahtChange,
    isMembership,
  ]);

  function onChangeType(event: SelectChangeEvent<unknown>) {
    event.target.value === PurchaseType.Membership
      ? onPackagePTChange(null)
      : onMembershipChange(null);
  }

  function onChangeDiscountType(value: PurchaseType) {
    onDiscountPercentChange(0);
    onDiscountBahtChange(0);
    onDiscountTypeChange(value);
  }

  return (
    <Stack gap={2.5}>
      <Stack direction="row" gap={2.5}>
        <FormSelect
          FormControlProps={{ fullWidth: true }}
          label="รายการสินค้า"
          name={`products.${index}.type`}
          control={control}
          onChange={onChangeType}
          required
        >
          {[PurchaseType.Membership, PurchaseType.ProductPT].map(
            (purchaseType) => (
              <MenuItem key={purchaseType} value={purchaseType}>
                {formatPurchaseType(purchaseType)}
              </MenuItem>
            )
          )}
        </FormSelect>
        {index !== 0 && (
          <IconButton size="medium" onClick={onClickDelete}>
            <DeleteIcon fontSize="large" />
          </IconButton>
        )}
      </Stack>
      {!!type && (
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2.5}>
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap={2.5}
            alignContent="flex-start"
          >
            {type === PurchaseType.Membership && (
              <FormMembershipAutocomplete
                label="ชื่อแพ็กเกจสมาชิก"
                name={`products.${index}.membership`}
                control={control}
                sx={{ gridColumn: "1/span 2" }}
                required
              />
            )}
            {type === PurchaseType.ProductPT && (
              <FormProductPTAutocomplete
                label={`ชื่อแพ็กเกจเทรนเนอร์`}
                name={`products.${index}.packagePT`}
                control={control}
                sx={{ gridColumn: "1/span 2" }}
                required
              />
            )}
            <FormDatePicker
              label="วันเริ่มต้น"
              name={`products.${index}.dateRange.start`}
              control={control}
              disabled={!isSelectPackage}
            />
            <FormDatePicker
              label="วันสิ้นสุด"
              name={`products.${index}.dateRange.end`}
              control={control}
              disabled
            />
            <Box display="grid" gridColumn="1/span 2">
              <Stack direction="row" gap={1.25} alignItems="center">
                <Typography variant="labelLarge">เลือกรูปแบบส่วนลด</Typography>
                <FormRadioGroup
                  row
                  name={`products.${index}.discountType`}
                  control={control}
                  onChange={(event, value) =>
                    onChangeDiscountType(value as PurchaseType)
                  }
                >
                  <FormControlLabel
                    value="percentage"
                    control={<Radio />}
                    label="เปอร์เซ็นต์ %"
                    disabled={!isSelectPackage}
                  />
                  <FormControlLabel
                    value="baht"
                    control={<Radio />}
                    label="บาท"
                    disabled={!isSelectPackage}
                  />
                </FormRadioGroup>
              </Stack>
            </Box>
            <FormNumericTextField
              label="ส่วนลด (%)"
              name={`products.${index}.discountPercentage`}
              control={control}
              disabled={!isSelectPackage || discountType === DiscountType.Bath}
            />
            <FormNumericTextField
              label="ราคาส่วนลด (บาท)"
              name={`products.${index}.discountBaht`}
              control={control}
              disabled={
                !isSelectPackage || discountType === DiscountType.Percentage
              }
            />
            <FormTextField
              label="หมายเหตุ"
              name={`products.${index}.note`}
              control={control}
              multiline
              rows={4}
              sx={{ gridColumn: "1/-1" }}
              disabled={!isSelectPackage}
            />
          </Box>
          <PackageDetail control={control} index={index} />
        </Box>
      )}
    </Stack>
  );
}

type PackageDetailProps = Pick<PurchaseItemEditorProps, "control" | "index">;

function PackageDetail({ control, index }: PackageDetailProps) {
  const type = useWatch({ name: `products.${index}.type`, control });
  const membership = useWatch({
    name: `products.${index}.membership`,
    control,
  });
  const packagePT = useWatch({ name: `products.${index}.packagePT`, control });
  const discountBaht = useWatch({
    name: `products.${index}.discountBaht`,
    control,
  });

  const packageData = type === PurchaseType.Membership ? membership : packagePT;

  const packageDuration = packageData?.duration as DurationObjectUnits;

  const unit = packagePT
    ? (Object.keys(packageDuration)[0] as keyof DurationObjectUnits)
    : "months";

  const duration = packageDuration ? packageDuration[unit] : 0;

  const quotaDetail =
    type === PurchaseType.ProductPT
      ? [
          {
            label: "จำนวน",
            value: packagePT
              ? `${packagePT.quota ?? "-"} ${formatQuotaUnit(
                  packagePT.quotaUnit as QuotaUnit
                )}`
              : "-",
          },
        ]
      : [];

  const packageDetails = [
    {
      label: "ระยะเวลา",
      value:
        duration && duration !== 0
          ? `${duration} ${formatDurationUnit(unit)}`
          : "-",
    },
    ...quotaDetail,
    {
      label: "ราคา",
      value: mapOptional(packageData?.price, formatPrice) ?? "-",
    },
    {
      label: "ราคาหลังหักส่วนลด",
      value: packageData
        ? formatPrice(packageData.price - (discountBaht ?? 0))
        : "-",
    },
    {
      label: "สาขาที่เข้าใช้บริการ",
      value: packageData ? packageData.branches.map(({ name }) => name) : "-",
    },
  ];

  return (
    <Box
      bgcolor="background.default"
      p={2}
      borderRadius={2}
      border="1px solid #9E9E9E3D"
    >
      <Box
        display="grid"
        gridTemplateColumns="150px 1fr"
        gap={3}
        alignItems="baseline"
      >
        {packageDetails.map(({ label, value }, index) => (
          <Fragment key={`${label}-${value.toString()}-${index}`}>
            <Typography variant="body1" color="text.disabled" fontWeight={500}>
              {label}
            </Typography>
            {Array.isArray(value) ? (
              <Stack gap={1}>
                {value.map((item, index) => (
                  <Typography
                    key={`${item}-${index}`}
                    variant="body1"
                    color="text.secondary"
                  >
                    <Box component="li">{item}</Box>
                  </Typography>
                ))}
              </Stack>
            ) : (
              <Typography variant="body1">{value}</Typography>
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
}

export function usePurchaseEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const membershipSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  duration: yup.object().required().nullable(),
  price: yup.number().required(),
  branches: yup
    .array(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
      })
    )
    .required(),
  productId: yup.number().required(),
});

const packagePTSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  quota: yup.number().required(),
  duration: yup.object().required().nullable(),
  quotaUnit: yup.string().required(),
  price: yup.number().required(),
  branches: yup
    .array(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
      })
    )
    .required(),
  productId: yup.number().required(),
});

const purchaseItemSchema = yup.object({
  type: yup
    .string()
    .oneOf(Object.values(PurchaseType))
    .required()
    .nullable()
    .label("รายการสินค้า"),
  membership: membershipSchema
    .when("type", {
      is: PurchaseType.Membership,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    })
    .default(null) // fix yup not validate when value is undefined
    .nullable()
    .label(`ชื่อ${formatPurchaseType(PurchaseType.Membership)}`),
  packagePT: packagePTSchema
    .when("type", {
      is: PurchaseType.ProductPT,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    })
    .default(null) // fix yup not validate when value is undefined
    .nullable()
    .label(`ชื่อ${formatPurchaseType(PurchaseType.ProductPT)}`),
  dateRange: yup.object().when(["membership", "packagePT"], {
    is: (membership: unknown, packagePT: unknown) =>
      !!membership || !!packagePT,
    then: (schema) =>
      schema.concat(
        yup.object({
          start: datetime().required().label("วันเริ่มต้น"),
          end: datetime().nullable(),
        })
      ),
    otherwise: (schema) =>
      schema.concat(
        yup.object({
          start: datetime(),
          end: datetime().nullable(),
        })
      ),
  }),
  discountType: yup.string().oneOf(Object.values(DiscountType)).required(),
  note: yup.string().max(500).label("หมายเหตุ"),
  discountPercentage: yup
    .number()
    .concat(decimal)
    .min(0)
    .max(100)
    .label("ส่วนลด"),
  discountBaht: yup.number().concat(decimal).min(0).label("ส่วนลด"),
});

const schema = yup.object({
  products: yup.array(purchaseItemSchema).min(1).required(),
});

const resolver = yupResolver(schema);

const defaultValues = { products: [] } as PurchaseEditorState;
