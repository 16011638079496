import { createTheme, PaletteOptions } from "@mui/material";
import autocompleteClasses from "@mui/material/Autocomplete/autocompleteClasses";
import { CSSProperties } from "react";
import { reds } from "@/colors";

declare module "@mui/material/styles" {
  interface PaletteColor {
    shades?: {
      "4p": string;
      "8p": string;
      "12p": string;
      "30p": string;
      "50p": string;
    };
  }

  interface SimplePaletteColorOptions {
    shades?: {
      "4p": string;
      "8p": string;
      "12p": string;
      "30p": string;
      "50p": string;
    };
  }

  interface TypographyVariants {
    labelLarge: CSSProperties;
  }

  interface TypographyVariantsOptions {
    labelLarge?: CSSProperties;
  }

  interface BreakpointOverrides {
    tablet: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    labelLarge: true;
  }
}

const palette: Partial<PaletteOptions> = {
  primary: {
    main: "#DE2826",
    dark: "#A3000C",
    light: "#DC8687",
    shades: {
      "4p": "rgba(225, 37, 27, 0.04)",
      "8p": "rgba(225, 37, 27, 0.08)",
      "12p": "rgba(225, 37, 27, 0.12)",
      "30p": "rgba(225, 37, 27, 0.3)",
      "50p": "rgba(225, 37, 27, 0.5)",
    },
  },
  secondary: {
    main: "#B90810",
    dark: "#710008",
    light: "#B9706F",
  },
  success: {
    main: "#2E7D32",
    dark: "#1B5E20",
  },
  error: {
    main: "#C74C3F",
    dark: "#9A352F",
    light: "#B27574",
  },
  text: {
    primary: "#000000DE",
    secondary: "#00000099",
    disabled: "#00000061",
  },
  info: {
    main: "#0288D1",
    dark: "#0039AC",
  },
  background: {
    default: "#FAFAFA",
    paper: "#FFFFFF",
  },
  warning: {
    main: "#ED6C02",
    dark: "#FE9F0B",
  },
  action: {
    active: "rgba(0, 0, 0, 0.28)",
    activatedOpacity: 0.28,
    hoverOpacity: 0.04,
    selectedOpacity: 0.8,
    disabledOpacity: 0.26,
    focusOpacity: 0.12,
  },
};

export const theme = createTheme({
  palette,
  typography: {
    fontFamily: ["Prompt", "sans-serif"].join(","),
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontSize: "2rem",
    },
    labelLarge: {
      fontWeight: 500,
      fontSize: "0.875rem",
      color: palette.text?.secondary,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "1rem",
    },
    subtitle2: {
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 12,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tablet: 1280,
    },
  },
});

theme.components = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Prompt';
        font-style: normal;
        font-display: swap;
        font-weight: 300;
        src: local('Prompt'), local('Prompt-Light'), url('/fonts/Prompt-Light.woff2') format('woff2');
      }

      @font-face {
        font-family: 'Prompt';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('Prompt'), local('Prompt-Regular'), url('/fonts/Prompt-Regular.woff2') format('woff2');
      }

      @font-face {
        font-family: 'Prompt';
        font-style: normal;
        font-display: swap;
        font-weight: 500;
        src: local('Prompt'), local('Prompt-Medium'), url('/fonts/Prompt-Medium.woff2') format('woff2');
      }

      .camera-overlay {
        position: absolute;
        bottom: 95;
        width: 500px;
        height: 500px;
        background: radial-gradient(340px 420px at center, transparent 200px, rgba(0, 0, 0, 0.5) 200px);
      }

      @keyframes pulse {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.1);
        }

        100% {
          transform: scale(1);
        }
      }
      
      .SnackbarItem-contentRoot {
        border-radius: ${theme.shape.borderRadius}px !important;
      }
      
      .SnackbarItem-variantSuccess {
        background-color: ${theme.palette.success.main} !important;
      }
      
      .SnackbarItem-variantError {
        background-color: ${theme.palette.error.main} !important;
      }
      
      .SnackbarItem-message > .MuiSvgIcon-root {
        margin-right: ${theme.spacing(2)};
      }
      
      .fc .fc-daygrid-day-top,
      .fc .fc-daygrid-day-number,
      .fc .fc-col-header-cell.fc-day {
        font-size: ${theme.typography.body2.fontSize as string};
        font-weight: ${theme.typography.body2.fontWeight as number};
      }
      
      .fc .fc-col-header-cell .fc-scrollgrid-sync-inner {
        line-height: 2;
      }
      
      .fc .fc-daygrid-day-top {
        flex-direction: row;
      }
      
      .fc .fc-daygrid-day-number {
        padding: ${theme.spacing(1)};
      }
      
      .fc .fc-daygrid-event-dot {
        border-width: 6px;
        border-radius: 12px;
        margin-right: 10px;
      }
      
      .fc .fc-daygrid-dot-event .fc-event-title,
      .fc .fc-v-event .fc-event-title {
        font-size: ${theme.typography.caption.fontSize as string};
        font-weight: ${theme.typography.caption.fontWeight as number};
        line-height: ${theme.typography.caption.lineHeight as number};
      }
      
      .fc .fc-daygrid-day.fc-day-today,
      .fc .fc-timegrid-col.fc-day-today {
        background-color: ${reds["50"]};
      }
      
      .fc .fc-list-table .fc-list-event-time {
        width: 240px;
      }
      
      .fc-list-day .fc-list-day-text,
      .fc-list-day .fc-list-day-side-text {
        font-weight: normal; 
      }
      
      .fc-direction-ltr .fc-list-day-text {
        float: right;
      }
      
      .fc-direction-ltr .fc-list-day-side-text {
        float: left;
      }
      
      .fc .fc-timegrid-slot-minor {
        border-top-style: unset;
      }
      
      .fc .fc-timegrid-event {
        padding: 2px 4px;
        border-radius: ${theme.shape.borderRadius}px;
      }
      
      .fc .fc-list-sticky .fc-list-day.fc-day-today > * {
        background-color: ${reds["50"]};
      }

      .fc .fc-list-sticky .fc-list-day.fc-day-today > * {
        background-color: ${reds["50"]};
      }

      .fc-theme-standard .fc-day-today .fc-list-day-cushion {
        background-color: unset;
      }

      .fc-event {
        cursor: pointer;
      }

      .MuiTabPanel-root {
        padding: 0 !important;
      }
    `,
  },
  MuiButton: {
    defaultProps: {
      size: "large",
    },
    styleOverrides: {
      containedInherit: {
        background: theme.palette.background.paper,
        color: theme.palette.common.black,
        "&:hover": {
          background: theme.palette.grey[100],
        },
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "subtitle1",
      },
    },
    styleOverrides: {
      root: {
        padding: 16,
        [theme.breakpoints.up("tablet")]: {
          padding: 24,
        },
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: 16,
        [theme.breakpoints.up("tablet")]: {
          padding: 24,
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          background: theme.palette.primary.shades?.["4p"],
          "&:hover": {
            background: theme.palette.action.hover,
          },
          "& > .MuiListItemIcon-root": {
            color: theme.palette.primary.main,
          },
          "& > .MuiListItemText-root": {
            color: theme.palette.text.primary,
          },
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        "&.MuiTableRow-hover:hover": {
          cursor: "pointer",
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        background: theme.palette.grey[100],
        "&:first-of-type": {
          borderTopLeftRadius: theme.shape.borderRadius,
        },
        "&:last-of-type": {
          borderTopRightRadius: theme.shape.borderRadius,
        },
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        "&.Mui-selected:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
        "&.Mui-selected": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: theme.palette.action.active,
      },
      sizeMedium: {
        width: 48,
        height: 48,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecorationColor: "inherit",
      },
    },
  },
  MuiDialog: {
    defaultProps: {
      fullWidth: true,
      maxWidth: "lg",
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        ...theme.typography.h6,
        padding: theme.spacing(3),
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: { padding: 24, paddingTop: "24px !important" },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3),
        "& > :not(:first-of-type)": {
          marginLeft: theme.spacing(2.5),
        },
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      rectangular: {
        borderRadius: 12,
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        "& .MuiLink-root:not(:hover)": {
          textDecoration: "none",
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          background: theme.palette.primary.shades?.["4p"],

          "&:hover, &:focus": {
            background: theme.palette.primary.shades?.["8p"],
          },
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        [`& .${autocompleteClasses.option}`]: {
          '&[aria-selected="true"]': {
            backgroundColor: theme.palette.primary.shades?.["4p"],

            [`&.${autocompleteClasses.focused}`]: {
              backgroundColor: theme.palette.primary.shades?.["8p"],
            },
          },
        },
      },
      popupIndicator: {
        width: 24,
        height: 24,
      },
      clearIndicator: {
        width: 24,
        height: 24,
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: { labelLarge: "label" },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: 0,
      },
    },
  },
  MuiFormGroup: {
    styleOverrides: {
      root: {
        ".Mui-error .MuiCheckbox-root": {
          color: theme.palette.error.main,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        "&.Mui-focused": {
          color: theme.palette.text.secondary,
        },
        "&.Mui-error": {
          color: theme.palette.error.main,
        },
      },
    },
  },
};
