import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";
import { formatDate } from "@/formatter";

import type { FollowUpItem } from "@/models";

const columnsWidth = [160, 160, 304, 304].map((item) =>
  calculateColumnWidth(item)
);

export type FollowUpTableRowProps = {
  followUpNo: number;
  data: FollowUpItem;
};

export function FollowUpTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]} align="center">
        ติดตามผลครั้งที่
      </TableCell>
      <TableCell width={columnsWidth[1]}>วันที่</TableCell>
      <TableCell width={columnsWidth[2]}>หมายเหตุ</TableCell>
      <TableCell width={columnsWidth[3]}>ผลการติดตาม</TableCell>
    </TableRow>
  );
}

export function FollowUpTableRow({ followUpNo, data }: FollowUpTableRowProps) {
  const navigate = useNavigate();

  const { id, followedAt, remark, summary } = data;

  const idStr = id.toString();

  const onClickTableRow = () => navigate(`follow-ups/${idStr}`);

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell align="center">{followUpNo}</TableCell>
      <TableCell>{formatDate(followedAt)}</TableCell>
      <TableCell>
        <TextEllipsis line={2}>{remark || "-"}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis line={2}>{summary || "-"}</TextEllipsis>
      </TableCell>
    </TableRow>
  );
}
