import { Divider, Stack, useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { changeToProspect } from "@/services/customer";
import { getApiErrorMessage, mapOptional, refetchQueries } from "@/utils";
import { transformDurationISOtoObject } from "@/lib/duration";
import { theme } from "@/theme";

import { FollowingEditor } from "../../components/FollowingEditor";
import { ProspectEditor } from "./ProspectEditor";

import type { InferType } from "yup";
import type { Control } from "react-hook-form";
import type { FollowingEditorState } from "../../components/FollowingEditor";
import type { ProspectEditorState } from "./ProspectEditor";
import type { Customer } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

type ChangeToProspectEditorState = InferType<typeof schema>;

type AddProspectDialogProps = {
  data: Customer;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function ChangeToProspectDialog({
  data,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: AddProspectDialogProps) {
  const { control, handleSubmit, reset } = useChangeToProspectEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: updateToProspect, isLoading } = useMutation(
    changeToProspect,
    {
      onSuccess: async () => {
        enqueueSnackbar("เปลี่ยนสถานะเป็นมีโอกาสซื้อ และเพิ่มการติดตามสำเร็จ", {
          variant: "success",
        });
        await refetchQueries({ queryClient, fetchKeys });
        close();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    }
  );

  function close() {
    reset();
    onClose();
  }

  useEffect(() => {
    if (data) {
      reset({
        membership: data.productMembership
          ? {
              id: data.productMembership.id,
              name: data.productMembership.products[0].name,
              duration: transformDurationISOtoObject(
                data.productMembership.durationIso
              ),
              price: data.productMembership.products[0].price,
            }
          : undefined,
        packagePT: data.productPersonalTraining
          ? {
              id: data.productPersonalTraining.id,
              name: data.productPersonalTraining.products[0].name,
              quota: data.productPersonalTraining.quota,
              duration: transformDurationISOtoObject(
                data.productPersonalTraining.durationIso
              ),
              quotaUnit: data.productPersonalTraining.quotaUnit,
              price: data.productPersonalTraining.products[0].price,
            }
          : undefined,
        total: data.productTotal || undefined,
        chance: data.chance || undefined,
        dueDate: data.productCloseAt || undefined,
      });
    }
  }, [data, reset]);

  const onSubmit = handleSubmit((form) => {
    updateToProspect({
      id: data.id,
      productMembershipId: +form.membership.id,
      productPersonalTrainingId:
        mapOptional(form.packagePT?.id, Number) || null,
      chance: form.chance,
      productTotal: form.total ?? 0,
      productCloseAt: form.dueDate,
      following: {
        remark: form.following.remark || null,
        followAt: form.following.date,
      },
    });
  });

  const title = "เปลี่ยนสถานะเป็นมีโอกาสซื้อ";

  const isTablet = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <FormConfirmDialog
      maxWidth={isTablet ? "md" : "lg"}
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <Stack gap={2.5}>
        <ProspectEditor
          control={control as unknown as Control<ProspectEditorState>}
        />
        <Divider />
        <FollowingEditor
          control={control as unknown as Control<FollowingEditorState>}
        />
      </Stack>
    </FormConfirmDialog>
  );
}

function useChangeToProspectEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = ProspectEditor.schema.concat(FollowingEditor.schema);

const resolver = yupResolver(schema);

const defaultValues = {
  ...ProspectEditor.defaultValues,
  ...FollowingEditor.defaultValues,
} as ChangeToProspectEditorState;
