import type { FirebaseOptions } from "@firebase/app";

const env = import.meta.env.VITE_ENV ?? "development";
const apiUrl = import.meta.env.VITE_REST_URL;
const aboutUsUrl = import.meta.env.VITE_ABOUT_US_URL;

const firebaseConfigs: Record<string, FirebaseOptions> = {
  development: {
    apiKey: "AIzaSyCvN5T2RmC1zCX9lt1nSDPQ0rwuqrOV-a0",
    authDomain: "fitup-dev.firebaseapp.com",
    projectId: "fitup-dev",
    storageBucket: "fitup-dev.appspot.com",
    messagingSenderId: "393956998711",
    appId: "1:393956998711:web:005bc478be23211cff49b2",
    measurementId: "G-4JFQWJHHV2",
  },
  production: {
    apiKey: "AIzaSyARgZ29ZT6qA6Ek5fswOf49zO9pKTrGM44",
    authDomain: "fitup-pro.firebaseapp.com",
    projectId: "fitup-pro",
    storageBucket: "fitup-pro.appspot.com",
    messagingSenderId: "557369696983",
    appId: "1:557369696983:web:1d6ae9df73d86623d8c692",
    measurementId: "G-22JV76GEF4",
  },
};

const firebase = firebaseConfigs[env];
const buildNumber = import.meta.env.VITE_BUILD_NUMBER ?? "localhost";
const appVersion = import.meta.env.VITE_VERSION ?? "";
const version = appVersion ? `${appVersion.split(/^dev-|^v/)[1]}` : "localhost";
const limitPerPage = 20;
const timezone = "Asia/Bangkok";
const unknownErrorMessage = "เกิดข้อผิดพลาด";
const defaultImageURL = "/default-image.svg";
const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
const role = { admin: 1, trainer: 2, manager: 3, sale: 4 };
const aspectRatio = {
  logo: 1 / 1,
  class: 5 / 4,
  category: 5 / 4,
  trainer: 4 / 5,
  video: 65 / 24,
};
const previewSize = {
  classes: {
    width: 75,
    height: 50,
  },
  videos: {
    width: 163,
    height: 59,
  },
  trainers: {
    width: 60,
    height: 90,
  },
  categories: {
    width: 75,
    height: 59,
  },
  fitness: {
    width: 60,
    height: 60,
  },
};
const maxInterest = 5;

export const configs = {
  env,
  apiUrl,
  aboutUsUrl,
  firebase,
  version,
  buildNumber,
  limitPerPage,
  timezone,
  unknownErrorMessage,
  defaultImageURL,
  sentryDSN,
  role,
  aspectRatio,
  previewSize,
  maxInterest,
};
